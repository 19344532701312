import React from 'react';
import { Link } from 'react-router-dom';
import mt from 'moment-timezone';
import { DATETIME_DISPLAY } from 'components/utils/constants';
import ResponsiveImage from 'components/elements/common/ResponsiveImage/ResponsiveImage';

const crops = {
  desktop: '250x250',
  tablet: '250x250',
  mobile: '150x150',
};

const CollectionContents = ({ id, name, count, thumb, createdAt, isShared }) => (
  <Link to={`/collections/${id}`} className="collections-collection--thumb">
    <span className="collections-collection--count">{count}</span>
    <span className="collections-collection--createdat">{mt(createdAt).format(DATETIME_DISPLAY)}</span>
    <span className={`collections-collection--icon ${isShared ? 'shared' : 'folder'}`} />
    {thumb && <ResponsiveImage imageUrl={thumb} crops={crops} title={name}/>}
  </Link>
);

export default CollectionContents;
