import React from 'react';
import Modal from './Modal';

import './AssetNotAvailableModal.scss';

const AssetNotAvailableModal = ({ open, handleClose }) => (
  <Modal className="asset-not-available-modal" theme="dark" open={open} handleClose={handleClose}>
    <section className="asset-not-available">
      <p>
        This asset is not currently available
      </p>
    </section>
  </Modal>
);

export default AssetNotAvailableModal;
