import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateForm } from 'store/actions/contactUs';
import { closeAccessDenied } from 'store/actions/globalActions';

import './AccessDenied.scss';

class AccessDenied extends Component {
  handleSendRequestClick() {
    const { closeModal, updateContactUsForm } = this.props;
    updateContactUsForm('topic', 'Request a Trial');
    closeModal();
  }

  render() {
    return (
      <section className="accessdenied">
        <p>This asset/feature is not included in your subscription. Please contact sales</p>
        <Link to={'/contact-us'} className="button-contact" onClick={() => this.handleSendRequestClick()}>Send Request</Link>
      </section>
    );
  }
}

const mapDispatchToProps = {
  closeModal: closeAccessDenied,
  updateContactUsForm: updateForm,
};

export default connect(null, mapDispatchToProps)(AccessDenied);
