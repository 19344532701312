import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import OutsideClickHandler from 'react-outside-click-handler';
import { setQuery, search, updateFilter, resetFilterGroups } from 'store/actions/search';
import { setDropdownOpen, quickFindReset } from 'store/actions/quickFind';
import { clearSavedSearchError } from 'store/actions/savedsearch';
import { autocomplete } from 'store/actions/autocomplete';
import SearchInput from 'components/elements/common/forms/searchinput/SearchInput';
import SearchGoButton from 'components/elements/common/forms/searchgobutton/SearchGoButton';
import NewSavedSearchModal from 'components/elements/common/modal/savedsearch/NewSavedSearchModal';
import QuickFindOptions from 'components/elements/layout/header/QuickFindOptions';
import PermissionButton from 'elements/common/permission/PermissionButton';
import { SEARCH_ROUTE } from '../../../../../routes';
import { SEARCH_CONTEXTS, SESSION_FILTERS } from '../../../utils/constants';

import './SearchBar.scss';

class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      newSavedSearchOpen: false,
    };
    const { topics, companies, people, series } = SEARCH_CONTEXTS;
    this.defaultContexts = `${topics.autocomplete},${companies.autocomplete},${people.autocomplete}`;
    this.allContexts = `${this.defaultContexts},${series.autocomplete}`;
  }

  componentDidMount() {
    // FIXME this is a workaround for the react-router location state not updating correctly
    // from: https://github.com/ReactTraining/react-router/issues/5251#issuecomment-309419532
    /* istanbul ignore next */
    this.props.history.listen(location => {
      if (location.pathname !== this.props.location.pathname) {
        this.props.location.pathname = location.pathname; // eslint-disable-line no-param-reassign
        this.forceUpdate();
      }
    });
  }

  handleSearchChange(evt, query) {
    this.setState({ query });
  }

  handleSubmit(evt) {
    this.props.resetFilterGroups(SESSION_FILTERS);
    if (evt.preventDefault) {
      // user hit enter without using an autocomplete query
      evt.preventDefault();
      this.props.setQuery(this.state.query);
    } else {
      // user selected an autocomplete query
      this.setState({ query: '' });
      this.props.setQuery('');
      const { context, value, displayValue } = evt;
      this.props.updateFilter(context.toLowerCase(), value, { value, displayValue });
    }
    this.setState({ query: '' });
    this.props.search();

    const { location: { pathname }, history } = this.props;
    if (pathname !== SEARCH_ROUTE) {
      history.push(SEARCH_ROUTE);
    }
  }

  handleGoButtonClick(query) {
    this.props.resetFilterGroups(SESSION_FILTERS);
    this.props.setQuery(query);
    this.setState({ query: '' });
    this.props.search();
  }

  openNewSavedSearchModal() {
    this.setState({ newSavedSearchOpen: true });
  }

  closeNewSavedSearchModal() {
    this.setState({ newSavedSearchOpen: false });
    this.props.clearSavedSearchError();
  }

  setQuickFindOptionsOpen(quickFindOptionsOpen, event) {
    // this check prevents this func from firing twice when the menu is open and the quick-find button is clicked
    const quickFindBtnClickedWithHandler = (event && Array.isArray(event.path) && event.path.some(element => element.id === 'searchbar-quick-find-button'));

    if (!quickFindBtnClickedWithHandler) {
      this.props.setDropdownOpen(quickFindOptionsOpen);

      if (!quickFindOptionsOpen) {
        this.props.quickFindReset();
      }
    }
  }

  render() {
    /* istanbul ignore next */
    const { filters: { media = {} }, loading, hasResults, location: { pathname }, quickFindOptionsOpen } = this.props;
    const { query } = this.state;
    const { video } = media;
    const showSaveSearchButton = hasResults && pathname.includes(SEARCH_ROUTE);
    const autocompleteContexts = video ? this.allContexts : this.defaultContexts;
    return (
      <div className="searchbar">
        <div className="searchbar--quick-find">
          <button type="button" id="searchbar-quick-find-button" onClick={() => this.setQuickFindOptionsOpen(!quickFindOptionsOpen)}>Quick Find</button>
        </div>

        <div className="searchbar--go-button">
          <Link to='/search' onClick={() => this.handleGoButtonClick(query)}><SearchGoButton /></Link>
        </div>

        <div className="searchbar--searchinput">
          <SearchInput
            className={`searchbar ${showSaveSearchButton ? 'showing-button' : ''}`.trim()}
            autocomplete={true}
            autocompleteId={'search-input-autocomplete'}
            autocompleteContexts={autocompleteContexts}
            value={query}
            getSuggestions={this.props.autocomplete}
            handleSubmit={evt => this.handleSubmit(evt)}
            onChange={(evt, newValue) => this.handleSearchChange(evt, newValue)}
            disabled={loading}>
            {showSaveSearchButton && <PermissionButton permission="savesearch" className="searchbar-save" onClick={() => this.openNewSavedSearchModal()} ariaLabel="add saved search" />}
          </SearchInput>
        </div>

        <div className="searchbar--quickfind-options">
          { quickFindOptionsOpen
            && <OutsideClickHandler onOutsideClick={ e => this.setQuickFindOptionsOpen(false, e)}>
              <QuickFindOptions />
            </OutsideClickHandler>
          }
        </div>

        <NewSavedSearchModal
          open={this.state.newSavedSearchOpen}
          handleClose={() => this.closeNewSavedSearchModal()}
          afterSave={() => this.closeNewSavedSearchModal()}
          timeout={1000}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ search: searchStore, quickFind: { dropdownOpen } }) => ({
  loading: searchStore.loading,
  query: searchStore.query,
  filters: searchStore.filters,
  hasResults: !!searchStore.results.length,
  quickFindOptionsOpen: dropdownOpen,
});

const mapDispatchToProps = {
  setQuery,
  updateFilter,
  search,
  autocomplete,
  clearSavedSearchError,
  setDropdownOpen,
  quickFindReset,
  resetFilterGroups,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchBar));
