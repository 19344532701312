import React from 'react';
import './Checkbox.scss';

const Checkbox = ({ children, className, ...attr }) => (
  <label className="custom-checkbox-label">
    <input
      type="checkbox"
      className={`custom-checkbox-input ${className}`}
      {...attr}
    /><span className="custom-checkbox-text"> {children}</span>
  </label>
);

export default Checkbox;
