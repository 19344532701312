import React, { Component } from 'react';
import axios from 'axios';
import ErrorView from 'elements/common/ErrorView';
import { getAxiosError } from 'components/utils/helpers';
import Page from '../Page';
import DownloadHistory from './downloadhistory/DownloadHistory';
import './Usage.scss';

const duration = {
  MONTHLY: 'Month',
  YEARLY: 'Year',
};

const TIMEOUT = 5000;

export const QuotaNumberValue = ({ className, value, category }) => (
  <div className={className}>
    <p className={`${className}--value`}>{value}</p>
    {category && <p className={`${className}--category`}>{category}</p>}
  </div>
);

export class Usage extends Component {
  constructor() {
    super();
    this.state = {
      quotas: [],
      error: null,
    };
  }

  componentDidMount() {
    this.fetchQuotaStats();
  }

  async fetchQuotaStats() {
    try {
      const { data } = await axios.get('/api/audits/quota/stats', { timeout: TIMEOUT });
      this.setState({ quotas: data.quotas });
    } catch (err) {
      const { message } = getAxiosError(err);
      this.setState({ error: message });
    }
  }

  render() {
    const { quotas, error } = this.state;

    return (
      <Page className="usage" title="Usage" pageType="usage">
        <section className="usage--info">
          <h2>Usage</h2>
          <div className="usage-container">
            <div className="usage-container--header-row">
              <div>&nbsp;</div>
              <h4>Total Allowed</h4>
              <h4>Period</h4>
              <h4>Total Downloaded</h4>
              <h4>Total Left</h4>
            </div>
            {quotas.length > 0 && quotas.map(quota => (
              <div className="usage-container--data-row" key={quota.id}>
                <QuotaNumberValue className="usage-data" value={quota.name} />
                <QuotaNumberValue className="usage-data-highlight" value={quota.limit || '∞'} category={`Assets / ${duration[quota.duration]}`} />
                <QuotaNumberValue className="usage-data-highlight" value={quota.days} category={`days left in this ${duration[quota.duration]}`} />
                <QuotaNumberValue className="usage-data" value={quota.downloaded} category="Assets" />
                <QuotaNumberValue className="usage-data" value={quota.limit ? quota.limit - quota.downloaded : '∞'} category="Assets" />
              </div>))
            }
            {error && <ErrorView error={error} />}
          </div>
        </section>
        <DownloadHistory />
      </Page>
    );
  }
}

export default Usage;
