import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../Modal';
import './announcementModal.scss';

class AnnouncementModal extends Component {
  render() {
    const { open, detail, handleClose } = this.props;
    return (
      <Modal
        className="announcement-detail-modal"
        open={open}
        handleClose={handleClose}
      >
        <p
          className="announcement-detail"
          dangerouslySetInnerHTML={{ __html: detail }}
        ></p>
      </Modal>
    );
  }
}
const mapStateToProps = ({ announcement }) => ({
  detail: announcement.announcement.detail,
});

export default connect(mapStateToProps)(AnnouncementModal);
