import React from 'react';

const Trialist = ({ trialist }) => {
  if (!trialist) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="news--trialist">
        <p className="news--trialist--main">*** This story is for trial purpose only. ***</p>
        <p className="news--trialist--note">Contact your sales person for licensing rights.</p>
      </div>
      <div className="news--trialist__overlay" />
    </React.Fragment>
  );
};

export default Trialist;
