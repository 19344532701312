import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import PermissionButton from 'elements/common/permission/PermissionButton';
import { fetchAndDownloadPhoto } from 'store/actions/photos';
import { ASSET_TYPES, DOWNLOAD_DEBOUNCE_DELAY } from 'components/utils/constants';
import { downloadNews } from 'store/actions/news';
import Loading from 'elements/common/loading/Loading';
import VideoDownloadOptions from 'components/elements/common/Story/VideoDownloadOptions';

import './Download.scss';

export class Download extends Component {
  constructor(props) {
    super(props);

    this.debounceDownloadStory = debounce(this.downloadStory, DOWNLOAD_DEBOUNCE_DELAY, { leading: true, trailing: false });
    this.debounceDownloadWatermark = debounce(this.downloadWatermark, DOWNLOAD_DEBOUNCE_DELAY, { leading: true, trailing: false });
    this.debounceDownloadHighRes = debounce(this.downloadHighRes, DOWNLOAD_DEBOUNCE_DELAY, { leading: true, trailing: false });

    this.state = {
      open: false,
      downloadingWatermark: false,
      downloadingHighRes: false,
    };
  }

  toggleDownloadOptions() {
    this.setState(({ open }) => ({ open: !open }));
  }

  async downloadWatermark() {
    this.setState({ downloadingWatermark: true });
    const { id, watermark: thumb, downloadImage, width, height } = this.props;
    await downloadImage(id, thumb, true, width > height);
    this.setState({ downloadingWatermark: false });
  }

  async downloadHighRes() {
    this.setState({ downloadingHighRes: true });
    const { id, thumb, downloadImage } = this.props;
    await downloadImage(id, thumb, false);
    this.setState({ downloadingHighRes: false });
  }

  downloadStory() {
    const { id, downloadStory } = this.props;
    downloadStory(id);
  }

  renderNewsDownload() {
    const { className, children } = this.props;
    return (
      <span className={`story-download ${className} story-designator`}>
        <button className="button--download" onClick={() => this.debounceDownloadStory()}>
          {children}
          Download
        </button>
      </span>
    );
  }

  renderPhotoDownload() {
    const { className, children, downloadingPhoto } = this.props;
    const { open, downloadingWatermark, downloadingHighRes } = this.state;
    return (
      <span className={`story-download ${className} ${open ? 'open' : 'close'} image-designator`}>
        <button className="button--download" onClick={() => this.toggleDownloadOptions()}>
          {children}
          Download
        </button>
        <div className="story-download-options">
          <PermissionButton disabled={downloadingPhoto} permission="photos.watermark" className="story-download-options-button comp" onClick={() => this.debounceDownloadWatermark()}>Low Resolution {downloadingWatermark && downloadingPhoto && <Loading />}</PermissionButton>
          <PermissionButton disabled={downloadingPhoto} permission="photos.download" className="story-download-options-button download" onClick={() => this.debounceDownloadHighRes()}>High Resolution {downloadingHighRes && downloadingPhoto && <Loading />}</PermissionButton>
        </div>
      </span>
    );
  }

  render() {
    const { id, className, children, type } = this.props;

    if (type === ASSET_TYPES.news.type) {
      return this.renderNewsDownload();
    }
    if (type === ASSET_TYPES.image.type) {
      return this.renderPhotoDownload();
    }
    return <VideoDownloadOptions id={id} className={className}>{children}</VideoDownloadOptions>;
  }
}

const mapStateToProps = ({ user, photos }) => ({
  level: user.user.level,
  downloadingPhoto: photos.downloading,
});

const mapDispatchToProps = {
  downloadImage: fetchAndDownloadPhoto,
  downloadStory: downloadNews,
};

export default connect(mapStateToProps, mapDispatchToProps)(Download);
