import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getVideoPermissions, setVideoDownloading, postVideoDownload, checkUserAndDownload } from 'store/actions/videos';
import Loading from 'elements/common/loading/Loading';
import { saveToFile } from 'components/utils/helpers';
import { DATA_ENCODINGS, VIDEO_TEXT, VIDEO_RENDITIONS } from '../../../utils/constants';

class VideoDownloadOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      videoPermissionsLoading: false,
      videoPermissions: { permissions: {}, renditions: {}, subscription: {} },
    };
  }

  toggleOpen() {
    this.setState(({ open }) => ({ open: !open }));
  }

  async fetchPermissions() {
    const { id } = this.props;
    const permissions = await this.props.getVideoPermissions(id);
    return permissions;
  }

  async getVideoPermissions() {
    const { open } = this.state;
    this.setState({ videoPermissionsLoading: true });
    this.toggleOpen();

    if (!open) {
      this.setState({ videoPermissions: await this.fetchPermissions() });
    }
    this.setState({ videoPermissionsLoading: false });
  }

  downloadRendition(renditionSpecs) {
    const { id } = this.props;
    const { videoPermissions: { renditions } } = this.state;
    window.open(renditions[renditionSpecs.label], `download_${id}_${renditionSpecs.label}`, 'noopener,noreferrer');
    this.props.postVideoDownload(id, renditions[renditionSpecs.label], renditionSpecs);
  }

  downloadSubtitles() {
    const { id, clip: { subtitles } } = this.props;
    window.open(subtitles.url2, `download_${id}_sub`, 'noopener,noreferrer');
    this.props.postVideoDownload(id, subtitles.url2, VIDEO_TEXT.subtitles);
  }

  downloadTranscript() {
    const { id, clip: { transcript } } = this.props;
    saveToFile(transcript, DATA_ENCODINGS.plain, `${id}-transcript.txt`);
    this.props.postVideoDownload(id, VIDEO_TEXT.transcript.label, VIDEO_TEXT.transcript);
  }

  checkUserAndDownload({ downloadFunc, specs }) {
    this.props.checkUserAndDownload({
      specs,
      id: this.props.id,
      downloadFunc: () => downloadFunc(specs),
    });
  }

  checkSubtitles(clip) {
    const { id } = this.props;
    const subtitles = clip.subtitles || { url2: null };
    return id === clip.id && subtitles.url2 != null;
  }

  render() {
    const { className, label = 'Download', clip, children } = this.props;
    const { open, videoPermissions, videoPermissionsLoading } = this.state;
    const renditions = (videoPermissions && videoPermissions.renditions) || {};
    const { transcript } = clip;

    const watermarkDownloadFunc = () => this.downloadRendition(VIDEO_RENDITIONS.lowres);
    const compressedDownloadFunc = () => this.downloadRendition(VIDEO_RENDITIONS.compressed);
    const highresDownloadFunc = () => this.downloadRendition(VIDEO_RENDITIONS.highres);
    const palDownloadFunc = () => this.downloadRendition(VIDEO_RENDITIONS.pal);
    const subtitlesDownloadFunc = () => this.downloadSubtitles();
    const transcriptDownloadFunc = () => this.downloadTranscript();

    return (
      <span className={`story-download ${className} ${open ? 'open' : 'close'} video-designator`}>
        <button className="button--download" onClick={() => this.getVideoPermissions()}>
          {children}
          {label}
        </button>
        <div className="story-download-options">
          {videoPermissionsLoading ? <button className="story-download-options-button-loading"><Loading /></button> : (
            <React.Fragment>
              {renditions.watermark && <button className="story-download-options-button" onClick={() => this.checkUserAndDownload({ downloadFunc: watermarkDownloadFunc, specs: VIDEO_RENDITIONS.lowres })}>{VIDEO_RENDITIONS.lowres.displayName}</button>
              || <span className="story-download-options-button-inactive">{VIDEO_RENDITIONS.lowres.displayName}</span>}
              {renditions.compressed && <button className="story-download-options-button" onClick={() => this.checkUserAndDownload({ downloadFunc: compressedDownloadFunc, specs: VIDEO_RENDITIONS.compressed })}>{VIDEO_RENDITIONS.compressed.displayName}</button>
              || <span className="story-download-options-button-inactive">{VIDEO_RENDITIONS.compressed.displayName}</span>}
              {renditions.highres && <button className="story-download-options-button" onClick={() => this.checkUserAndDownload({ downloadFunc: highresDownloadFunc, specs: VIDEO_RENDITIONS.highres })}>{VIDEO_RENDITIONS.highres.displayName}</button>
              || <span className="story-download-options-button-inactive">{VIDEO_RENDITIONS.highres.displayName}</span>}
              {renditions.pal && <button className="story-download-options-button" onClick={() => this.checkUserAndDownload({ downloadFunc: palDownloadFunc, specs: VIDEO_RENDITIONS.pal })}>{VIDEO_RENDITIONS.pal.displayName}</button>}
              {this.checkSubtitles(clip) && <button className="story-download-options-button" onClick={() => this.checkUserAndDownload({ downloadFunc: subtitlesDownloadFunc, specs: VIDEO_TEXT.subtitles })}>{VIDEO_TEXT.subtitles.label}</button>
              || <span className="story-download-options-button-inactive">subtitles</span>}
              {transcript && <button className="story-download-options-button" onClick={() => this.checkUserAndDownload({ downloadFunc: transcriptDownloadFunc, specs: VIDEO_TEXT.transcript })}>{VIDEO_TEXT.transcript.label}</button>
              || <span className="story-download-options-button-inactive">{VIDEO_TEXT.transcript.label}</span>}
            </React.Fragment>)
          }
        </div>
      </span>
    );
  }
}

const mapStateToProps = ({ videos: { clip } }) => ({
  clip,
});

const mapDispatchToProps = {
  checkUserAndDownload,
  setVideoDownloading,
  getVideoPermissions,
  postVideoDownload,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoDownloadOptions);
