import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchCollections, setFilter } from 'store/actions/collections';
import { addAssetsToCollection } from 'store/actions/collection';
import Loading from 'components/elements/common/loading/Loading';
import CollectionsFilter from 'elements/common/collectionsfilter/CollectionsFilter';
import ErrorView from 'elements/common/ErrorView';
import Modal from '../Modal';
import NewCollectionModal from './NewCollectionModal';
import './CollectionsModal.scss';

class CollectionsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newCollectionOpen: false,
    };
  }

  componentDidMount() {
    if (this.props.open) {
      this.props.fetchCollections();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.props.fetchCollections();
    }
  }

  openNewCollectionModal() {
    this.setState({ newCollectionOpen: true });
  }

  closeNewCollectionModal() {
    this.setState({ newCollectionOpen: false });
  }

  addToNewCollection({ id: collectionId }) {
    this.closeNewCollectionModal();
    this.addToCollection(collectionId);
  }

  async addToCollection(collectionId) {
    const { handleClose, history } = this.props;
    if (await this.props.addAssetsToCollection(collectionId)) {
      handleClose();
      history.push(`/collections/${collectionId}`);
    }
  }

  closeModal() {
    this.props.setFilter('');
    this.props.handleClose();
  }

  render() {
    const { open, collections, loading, error } = this.props;
    const { newCollectionOpen } = this.state;

    return (
      <React.Fragment>
        <Modal className="collections-modal" theme="dark" open={open} handleClose={() => this.closeModal()}>
          <h4>My Collections</h4>
          <CollectionsFilter className="collections-modal--filter" />
          <button className="collections-modal-add-new" onClick={evt => this.openNewCollectionModal(evt)}>Add New Collection</button>
          {error && <ErrorView className="collections-modal-error" error={error} />}
          <ul>
            {loading && <Loading /> || collections.map(({ id, name }) => (
              <li key={`addToCollection${id}`}>
                <button className="collections-modal-add-to" onClick={() => this.addToCollection(id)}>{name}</button>
              </li>
            ))}
          </ul>
        </Modal>
        <NewCollectionModal open={newCollectionOpen} handleClose={() => this.closeNewCollectionModal()} afterSave={id => this.addToNewCollection(id)} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ collections }) => ({
  collections: collections.displayCollections,
  loading: collections.loading,
  error: collections.error,
});

const mapDispatchToProps = {
  fetchCollections,
  addAssetsToCollection,
  setFilter,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CollectionsModal));
