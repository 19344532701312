import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setQuery, resetResults, search, updateFilter, resetFilterGroups } from 'store/actions/search';
import ResponsiveImage from 'components/elements/common/ResponsiveImage/ResponsiveImage';
import './RelatedPhotos.scss';
import { SEARCH_ROUTE } from '../../../../../../routes';
import { ASSET_TYPES } from '../../../../utils/constants';

const RELATED_IMAGES_LIMIT = parseInt(process.env.RELATED_IMAGES_LIMIT, 10) || 4;

const crops = {
  desktop: '163x110',
  tablet: '150x100',
  mobile: '134x92',
};

class RelatedPhotos extends Component {
  findAllPhotos() {
    this.props.setQuery(`Related:${this.props.photo.imageReferenceId}`);
    this.props.resetFilterGroups(['media']);
    this.props.updateFilter('media', ASSET_TYPES.image.media, true);
    this.props.search();
  }

  render() {
    const { photo } = this.props;

    if (!(photo.related && photo.related.length)) {
      return null;
    }

    const showFindAll = photo.related.length > RELATED_IMAGES_LIMIT;
    const relatedImages = photo.related.slice(0, RELATED_IMAGES_LIMIT);

    return (
      <div className="related-photos">
        <h3>Related</h3>
        <div className="related-photos-container">
          {relatedImages.map(({ thumb, title, id }) => (
            <div className="related-photos-img" key={`${photo.id}_related_${id}`}>
              <Link to={`/images/${id}`}>
                <ResponsiveImage imageUrl={thumb} crops={crops} title={title} />
              </Link>
            </div>
          ))}
          {showFindAll && <Link className="related-photos-findall" onClick={() => this.findAllPhotos()} to={SEARCH_ROUTE}>Find All</Link>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ photos }) => ({
  photo: photos.photo,
});

const mapDispatchToProps = {
  setQuery,
  resetResults,
  search,
  updateFilter,
  resetFilterGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(RelatedPhotos);
