import React, { Component } from 'react';
import { noop } from '../../../utils/helpers';

import './TextInput.scss';

export const getValueAttr = (value, defaultValue = '') => ({
  [value !== undefined ? 'value' : 'defaultValue']: value !== undefined && value || defaultValue,
});

class TextInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMsg: false,
    };
  }

  onBlur(evt) {
    const { label, onBlur: parentOnBlur = noop, required = false } = this.props;
    if (required) {
      const error = evt.target.value.trim().length ? false : `${label} is required`;
      this.setState({ errorMsg: error });
    }
    parentOnBlur(evt);
  }

  onFocus(evt) {
    const { onFocus: parentOnFocus = noop } = this.props;
    this.setState({ errorMsg: false });
    parentOnFocus(evt);
  }

  render() {
    const {
      name,
      label,
      className = '',
      placeholder = '',
      onChange = noop,
      type = 'text',
      required,
      value,
      defaultValue,
      errorMsg = false,
    } = this.props;

    const isRequired = required !== undefined && required !== false;

    return (
      <div className={`mercury-textinput ${className}`.trim()}>
        {label && <label>{label}</label>}
        <input type={type} name={name} placeholder={placeholder} {...getValueAttr(value, defaultValue)} onChange={onChange} onFocus={evt => this.onFocus(evt)} onBlur={evt => this.onBlur(evt)} aria-label={label || name} required={isRequired} />
        {(errorMsg || this.state.errorMsg)
          && <div className="mercury-textinput-error">{errorMsg || this.state.errorMsg}</div>
        }
      </div>
    );
  }
}

export default TextInput;
