import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getCollectionTrackingDetails } from 'components/utils/analytics';
import Items from 'components/elements/common/Items/Items';
import StoryListItem from 'components/elements/common/Story/StoryListItem';
import StoryGridItem from 'components/elements/common/Story/StoryGridItem';
import Loading from 'components/elements/common/loading/Loading';
import ErrorView from 'elements/common/ErrorView';
import { noop } from 'components/utils/helpers';
import CollectionDetailsNav from 'components/elements/pages/collection/CollectionDetailsNav';
import SearchContents from 'components/elements/pages/search/SearchContents';
import CollectionDetailsActions from 'components/elements/pages/collection/CollectionDetailsActions';
import { getCollection, resetCollection } from 'store/actions/collection';
import Page from '../Page';
import CollectionEditableName from './CollectionEditableName';

import './CollectionDetails.scss';

class CollectionDetails extends Component {
  componentDidMount() {
    const { match: { params: { id } } } = this.props;
    // reset to null when collection exist solves an issue with the wrong collection name in the management modal
    this.props.resetCollection();
    this.props.getCollection(id);
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { id } }, signedIn } = this.props;
    if (signedIn !== prevProps.signedIn) {
      this.props.getCollection(id);
    }
  }

  render() {
    const { error, loading, collection } = this.props;
    if (loading || (!collection.id && !error)) {
      return (<Loading />);
    }
    const { collection: { assets = [] }, layout } = this.props;

    return (
      <Page className="collection" {...getCollectionTrackingDetails(collection)} error={error}>
        <CollectionEditableName />
        <CollectionDetailsNav />
        {error && <ErrorView className="collection-error" error={error} />}
        <Items
          className={`search-results ${layout}`}
          loading={loading}
          results={assets}
          hasMore={false}
          totals={assets.length}
          loadMore={noop}
          ListItem={(<StoryListItem contents={<SearchContents />} actions={<CollectionDetailsActions permission={collection.permission}/>} className="search-result-list" showCollectionRemove />)}
          GridItem={(<StoryGridItem contents={<SearchContents />} actions={<CollectionDetailsActions permission={collection.permission}/>} className="search-result-grid" />)}
        />
      </Page>
    );
  }
}

const mapStateToProps = ({ collection, globalActions, user }) => ({
  layout: globalActions.layout,
  collection: collection.collection,
  error: collection.error,
  loading: collection.loading,
  signedIn: !!user.user.id,
});

const mapDispatchToProps = {
  getCollection,
  resetCollection,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CollectionDetails));
