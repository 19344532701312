import React from 'react';
import { connect } from 'react-redux';
import { setQuery, resetFilterGroups } from 'store/actions/search';
import { SESSION_FILTERS } from '../../../../../utils/constants';

import './ClearAllFilters.scss';

const onRemove = (props) => {
  props.resetFilterGroups(SESSION_FILTERS);
  props.setQuery('');
};

const ClearAllFilters = props => (
  <div className="clear-all-filters">
    <button type="button" className="click-container" onClick={() => onRemove(props)}>
      <span className="pill__remove clear-filters-button" />
      <label className="clear-filters-label">Clear All</label>
    </button>
  </div>
);

const mapDispatchToProps = {
  resetFilterGroups,
  setQuery,
};

export default connect(null, mapDispatchToProps)(ClearAllFilters);
