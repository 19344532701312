export const SET_VIDEO_SERIES_CONTENT_ERROR = 'redux/videoSeriesContent/SET_VIDEO_SERIES_CONTENT_ERROR';
export const SET_VIDEO_SERIES_CONTENT_LOADING = 'redux/videoSeriesContent/SET_VIDEO_SERIES_CONTENT_LOADING';
export const SET_VIDEO_SERIES_CONTENT_DATA = 'redux/videoSeriesContent/SET_VIDEO_SERIES_CONTENT_DATA';

export const initialState = {
  error: false,
  loading: false,
  content: {},
};

const videoSeriesContent = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIDEO_SERIES_CONTENT_ERROR:
      return {
        ...state,
        error: action.data.error,
      };
    case SET_VIDEO_SERIES_CONTENT_LOADING:
      return {
        ...state,
        loading: action.data.loading,
      };
    case SET_VIDEO_SERIES_CONTENT_DATA:
      return {
        ...state,
        content: action.data.content,
      };
    default:
      return state;
  }
};

export default videoSeriesContent;
