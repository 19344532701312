import React from 'react';
import { connect } from 'react-redux';
import PillFilter from '../PillFilter';

const VideoSeriesFilters = ({ series }) => (
  <PillFilter className="series-filters" name="Video: Series" placeholder="Search Video: Series" items={series} filterKey="series" />
);

const mapStateToProps = ({ search: searchStore }) => ({
  series: Object.values(searchStore.filters.series || {}).map(({ value, displayValue }) => ({ value, displayValue: `Series: ${displayValue}` })),
});

export default connect(mapStateToProps)(VideoSeriesFilters);
