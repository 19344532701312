import React, { Component } from 'react';
import { connect } from 'react-redux';
import PermissionButton from 'components/elements/common/permission/PermissionButton';
import PermissionLink from 'components/elements/common/permission/PermissionLink';
import { downloadPhotoAttachment } from 'store/actions/photos';
import { postVideoDownload } from 'store/actions/videos';
import ErrorView from 'elements/common/ErrorView';
import { ASSET_TYPES, VIDEO_ATTACHMENTS } from '../../../../utils/constants';

class AttachmentDownload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  toggleOpen() {
    this.setState(({ open }) => ({ open: !open }));
  }

  downloadImageWatermark() {
    const { attachment: { id, watermark: thumb, width, height }, downloadImage, revision } = this.props;
    downloadImage(id, thumb, revision, true, width > height);
  }

  downloadImage() {
    const { attachment: { id, url: thumb }, downloadImage, revision } = this.props;
    downloadImage(id, thumb, revision, false);
  }

  downloadSvgImage() {
    const { attachment: { id, svgFile }, downloadImage, revision } = this.props;
    downloadImage(id, svgFile, revision, false);
  }

  downloadVideo() {
    const { attachment, revision } = this.props;
    const { id, compressed } = attachment;
    this.props.postVideoDownload(id, compressed, { ...VIDEO_ATTACHMENTS, revision });
  }

  downloadVideoWatermark() {
    const { attachment, revision } = this.props;
    const { id, watermark } = attachment;
    this.props.postVideoDownload(id, watermark, { ...VIDEO_ATTACHMENTS, revision });
  }

  render() {
    const { revision, attachment: { type, svgFile, watermark, compressed }, photoError } = this.props;
    const { open } = this.state;
    const defaultLinkAttributes = {
      target: '_blank',
      rel: 'noopener noreferrer',
    };
    const basePermission = `news.${revision}.${type}`;

    return (
      <div className={`news--download ${type}`}>
        <button type="button" className="news--download__toggle" onClick={() => this.toggleOpen()} aria-label={`Download ${type} ${revision}`} />
        <div className={`news--download__options ${open ? '' : 'hide'}`}>
          {type === ASSET_TYPES.video.type
          && <React.Fragment>
              <PermissionLink
                permission={`${basePermission}.watermark`}
                className="news--download__low-res"
                ariaLabel={`Download ${type} ${revision} watermark`}
                linkOptions={({ ...defaultLinkAttributes, href: watermark })}
                onClick={() => this.downloadVideoWatermark()}>
                mp4/watermark
              </PermissionLink>
              <PermissionLink
                permission={`${basePermission}.download`}
                className="news--download__high-res"
                ariaLabel={`Download ${type} ${revision} high res`}
                linkOptions={({ ...defaultLinkAttributes, href: compressed })}
                onClick={() => this.downloadVideo()}>
                mp4
              </PermissionLink>
            </React.Fragment>
          || <React.Fragment>
              <PermissionButton
                permission={`${basePermission}.watermark`}
                mediaType={type}
                className="news--download__low-res"
                onClick={() => this.downloadImageWatermark()}>
                Low Resolution
              </PermissionButton>
              <PermissionButton
                permission={`${basePermission}.download`}
                mediaType={type}
                className="news--download__high-res"
                onClick={() => this.downloadImage()}>
                High Resolution
              </PermissionButton>
              {svgFile
              && <PermissionButton
                permission={`${basePermission}.download`}
                mediaType={type}
                className="news--download__svg"
                onClick={() => this.downloadSvgImage()}>
                High Resolution (SVG)
              </PermissionButton>
              }
              {photoError && <ErrorView className="news-error photo-download-error" error={photoError} />}
            </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ videos, photos }) => ({
  videoError: videos.error,
  photoError: photos.error,
});

const mapDispatchToProps = {
  downloadImage: downloadPhotoAttachment,
  postVideoDownload,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentDownload);
