import React, { Component } from 'react';
import { noop } from 'utils/helpers';

import './SelectDropdown.scss';

export const getValueAttr = (value, defaultValue = '') => ({
  value: value !== undefined && value || defaultValue,
});

class SelectDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMsg: false,
    };
  }

  onBlur(evt) {
    const { label, onBlur: parentOnBlur = noop, required = false } = this.props;
    if (required) {
      const error = evt.target.value.trim().length ? false : `${label} is required`;
      this.setState({ errorMsg: error });
    }
    parentOnBlur(evt);
  }

  onFocus(evt) {
    const { onFocus: parentOnFocus = noop } = this.props;
    this.setState({ errorMsg: false });
    parentOnFocus(evt);
  }

  render() {
    const {
      name,
      label,
      className = '',
      onChange = noop,
      required,
      value,
      options = [],
      emptyOption,
      defaultValue,
      errorMsg = false,
    } = this.props;

    return (
      <div className={`mercury-selectdropdown ${className}`.trim()}>
        {label && <label>{label}</label>}
        <select name={name} {...getValueAttr(value, defaultValue)} onChange={onChange} onFocus={evt => this.onFocus(evt)} onBlur={evt => this.onBlur(evt)} aria-label={label || name} required={!!required}>
          {emptyOption && <option key={''} value="" />}
          {options.map(option => <option key={option} value={option}>{option}</option>)}
        </select>
        {(errorMsg || this.state.errorMsg)
        && <div className="mercury-selectdropdown-error">{errorMsg || this.state.errorMsg}</div>
        }
      </div>
    );
  }
}

export default SelectDropdown;
