import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { fetchAnnouncement } from '../../../../store/actions/announcement';
import { showAnnouncementModal as globalShowAnnouncementModal } from '../../../../store/actions/globalActions';
import './announcement.scss';

class Announcement extends Component {
  constructor(props) {
    super(props);

    this.announcementCookie = new Cookies();
    this.state = {
      announcementOpen:
        this.getCookieValue(this.announcementCookie.get('announcementOpen')),
    };
  }

  getCookieValue(cookieValue) {
    // if the cookie value is undefined
    // JSON is not able to parse undefined value
    if (!cookieValue) {
      return false;
    }
    return JSON.parse(cookieValue);
  }

  onClose(expTime) {
    this.announcementCookie.set('announcementOpen', false);
    this.setState({ announcementOpen: false });
    this.announcementCookie.set('announcementExp', expTime);
  }

  componentDidMount() {
    this.props.fetchAnnouncement();
    const cookieExp = this.announcementCookie.get('announcementExp');
    if (!cookieExp || new Date() > new Date(cookieExp)) {
      this.announcementCookie.set('announcementOpen', true);
      this.setState({ announcementOpen: true });
    }
  }

  // the announcement would refetch only when user logs in and logs out
  // due to the clearStore action when logging out, the announcement information
  // would be cleared and wouldn't fetch the announcement again
  componentDidUpdate(prevProps) {
    if (prevProps.loggedIn !== this.props.loggedIn) {
      this.props.fetchAnnouncement();
    }
  }

  render() {
    const { summary, detail, loggedIn, error, subscriberOnly, frequencyDays } = this.props;

    const expTime = new Date();
    expTime.setDate(expTime.getDate() + frequencyDays);

    let visibilityFlag = this.state.announcementOpen;
    if (subscriberOnly && !loggedIn) {
      visibilityFlag = false;
    }
    const displayStatus = visibilityFlag && summary && !error;
    if (!displayStatus) {
      return null;
    }
    const pureDetail = detail.replace(/<[^>]*>/g, '').trim();
    return (
      <header className="announcement">
        <div className="announcement__wrapper">
          {this.state.announcementOpen && (
            <button
              className="announcement__close"
              type="button"
              onClick={() => this.onClose(expTime)}
              aria-label="close announcement"
            />
          )}
          <h2 className="announcement__summary">{summary}</h2>
          <a
            className={`announcement__detail ${pureDetail ? '' : 'closed'}`}
            onClick={() => this.props.showAnnouncementModal()}
          >
            More Details
          </a>
        </div>
      </header>
    );
  }
}

const mapStateToProps = ({ announcement, user }) => ({
  summary: announcement.announcement.summary,
  detail: announcement.announcement.detail,
  loggedIn: !!user.user.email,
  error: announcement.error,
  subscriberOnly: announcement.announcement.subscriberOnly,
  frequencyDays: announcement.announcement.frequencyDays,
});

const mapDispatchToProps = {
  fetchAnnouncement,
  showAnnouncementModal: globalShowAnnouncementModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Announcement);
