export const SET_SEARCH_LOADING = 'redux/search/SET_SEARCH_LOADING';
export const SET_CANCEL_SEARCH_SOURCE = 'redux/search/SET_CANCEL_SEARCH_SOURCE';
export const SET_SEARCH_ERROR = 'redux/search/SET_SEARCH_ERROR';
export const SET_SEARCH_QUERY = 'redux/search/SET_SEARCH_QUERY';
export const SET_FILTERS_ARE_OPEN = 'redux/search/SET_FILTERS_ARE_OPEN';
export const ADD_SEARCH_RESULTS = 'redux/search/ADD_SEARCH_RESULTS';
export const SET_SEARCH_FILTERS = 'redux/search/SET_SEARCH_FILTERS';
export const UPDATE_GLOBAL_FILTERS = 'redux/search/UPDATE_GLOBAL_FILTERS';
export const UPDATE_SEARCH_FILTERS = 'redux/search/UPDATE_SEARCH_FILTERS';
export const REMOVE_SEARCH_FILTER = 'redux/search/REMOVE_SEARCH_FILTER';
export const SET_USER_SEARCH_FILTERS = 'redux/search/SET_USER_SEARCH_FILTERS';
export const RESET_SEARCH_RESULTS = 'redux/search/RESET_SEARCH_RESULTS';
export const RESET_FILTERS = 'redux/search/RESET_FILTERS';
export const INCREMENT_PAGE = 'redux/search/INCREMENT_PAGE';
export const SET_SEARCH_ID = 'redux/search/SET_SEARCH_ID';
export const SET_FEED_ID = 'redux/search/SET_FEED_ID';
export const SET_FILTER_GROUP = 'redux/search/SET_FILTER_GROUP';
export const RESET_FILTER_GROUP = 'redux/search/RESET_FILTER_GROUP';
export const RESET_AUTO_REFRESH_DELTA = 'redux/search/RESET_AUTO_REFRESH_DELTA';

export const initialState = {
  cancelSearchSource: null,
  loading: false,
  error: false,
  query: '',
  searchId: '',
  feedId: '',
  filtersAreOpen: true,
  results: [],
  totals: {},
  extra: {},
  autoRefreshDelta: 0,
  hasMore: false,
  page: 0,
  globalFilter: {},
  filters: {
    media: {
      text: false,
      photo: true,
      video: false,
    },
    dateRange: {
      before: null,
      after: null,
    },
    people: {},
    companies: {},
    topics: {},
    series: {},
    brands: {},
    photo: {
      landscape: false,
      portrait: false,
      creative: false,
      editorial: false,
    },
    video: {
      shortForm: true,
      longForm: true,
      clean: true,
      dirty: true,
      english: true,
      spanish: false,
      japanese: false,
    },
  },
};

const search = (state = initialState, action) => {
  switch (action.type) {
    case SET_CANCEL_SEARCH_SOURCE:
      return {
        ...state,
        cancelSearchSource: action.data.cancelSearchSource,
      };
    case SET_SEARCH_LOADING:
      return {
        ...state,
        error: false,
        loading: action.data.loading,
      };
    case SET_SEARCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data.error,
        hasMore: false,
      };
    case SET_SEARCH_QUERY:
      return {
        ...state,
        query: action.data.query,
      };
    case SET_USER_SEARCH_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.data.filters,
        },
      };
    case UPDATE_SEARCH_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.data.filterKey]: {
            ...state.filters[action.data.filterKey],
            [action.data.name]: action.data.value,
          },
        },
      };
    case SET_FILTER_GROUP:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.data.filterKey]: action.data.value,
        },
      };
    case RESET_FILTER_GROUP:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.data.filterKey]: {},
        },
      };
    /* eslint-disable no-case-declarations */
    case REMOVE_SEARCH_FILTER:
      const filterKeyFilters = state.filters[action.data.filterKey];
      delete filterKeyFilters[action.data.name];
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.data.filterKey]: filterKeyFilters,
        },
      };
    /* eslint-enable no-case-declarations */
    case UPDATE_GLOBAL_FILTERS:
      return {
        ...state,
        globalFilter: action.data.globalFilter,
      };
    case SET_SEARCH_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.data.filters,
        },
      };
    case SET_FILTERS_ARE_OPEN:
      return {
        ...state,
        filtersAreOpen: action.data.filtersAreOpen,
      };
    /* eslint-disable no-case-declarations */
    case ADD_SEARCH_RESULTS:
      const currentDelta = state.autoRefreshDelta || 0;
      return {
        ...state,
        error: false,
        loading: false,
        results: action.data.prepend ? [...action.data.results, ...state.results] : [...state.results, ...action.data.results],
        totals: action.data.totals,
        autoRefreshDelta: currentDelta + action.data.autoRefreshDelta,
        hasMore: action.data.hasMore,
        extra: action.data.extra,
      };
    case RESET_SEARCH_RESULTS:
      return {
        ...state,
        results: initialState.results,
        totals: initialState.totals,
        autoRefreshDelta: initialState.autoRefreshDelta,
        hasMore: initialState.hasMore,
        page: initialState.page,
        extra: initialState.extra,
      };
    case RESET_FILTERS:
      return {
        ...state,
        query: '',
        filters: {
          ...initialState.filters,
          languages: state.filters.languages,
          feeds: state.filters.feeds,
        },
      };
    case INCREMENT_PAGE:
      return {
        ...state,
        page: state.page + 1,
      };
    case SET_SEARCH_ID:
      return {
        ...state,
        searchId: action.data.searchId,
      };
    case SET_FEED_ID:
      return {
        ...state,
        feedId: action.data.feedId,
      };
    case RESET_AUTO_REFRESH_DELTA:
      return {
        ...state,
        autoRefreshDelta: 0,
      };
    default:
      return state;
  }
};

export default search;
