import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import PasswordInput from 'components/elements/common/forms/PasswordInput';
import ErrorView from 'elements/common/ErrorView';
import { validateForm, getAxiosError, validatePasswordMatch } from 'components/utils/helpers';
import {
  PASSWORD_REGEX,
  PASSWORD_RULES_LANGUAGE,
} from '../../../utils/constants';
import Page from '../Page';

import './Resetpassword.scss';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      submitted: false,
      apiError: false,
      form: {
        password: '',
        confirmpassword: '',
      },
      errors: {
        password: false,
        confirmpassword: false,
      },
    };
  }

  onChange(evt) {
    this.setState({ form: { ...this.state.form, [evt.target.name]: evt.target.value.trim() } });
  }

  onPasswordBlur(evt) {
    // set state is async, so we must use form values
    const password = evt.target.value.trim();
    const error = password.length && !PASSWORD_REGEX.test(password) ? PASSWORD_RULES_LANGUAGE : false;

    this.setState({ errors: { ...this.state.errors, password: error } });
  }

  onConfirmPasswordBlur(evt) {
    // set state is async, so we must use form values
    const { form: { password } } = this.state;
    const confirmpassword = evt.target.value.trim();
    const error = validatePasswordMatch(password, confirmpassword);

    this.setState({ errors: { ...this.state.errors, confirmpassword: error } });
  }

  onPasswordFocus(evt) {
    this.setState({ errors: { ...this.state.errors, [evt.target.name]: false } });
  }

  async submitForm() {
    try {
      this.setState({ submitting: true });
      const { key, userId } = this.props.match.params;
      const { password } = this.state.form;
      // Formats PUT payload
      const resetPasswordPayload = {
        password,
        resetPasswordKey: key,
        id: userId,
      };

      await axios.put('/api/reset-password', resetPasswordPayload);
      this.setState({ submitted: true });
    } catch (err) {
      const { message } = getAxiosError(err);
      if (message.error === 'New password matches one of last {1} passwords ') {
        message.error = 'You appear to be using a previous password. Please set a new password.';
      }
      this.setState({ apiError: message });
    }
    this.setState({ submitting: false });
  }

  onSubmit(evt) {
    evt.preventDefault();
    this.setState({ apiError: false });

    const { form: { password, confirmpassword } } = this.state;
    const error = validatePasswordMatch(password, confirmpassword);
    if (error) {
      this.setState({ errors: { ...this.state.errors, confirmpassword: error } });
      return;
    }

    if (validateForm(Object.keys(this.state.form))) {
      this.submitForm();
    }
  }

  renderSuccess() {
    return (
      <div className="reset-password-success">
        <p>Your password has been successfully reset. Please <u><Link to="/login">sign in</Link></u></p>
      </div>
    );
  }

  renderForm() {
    const { errors, submitting } = this.state;

    return (
      <form method="post" onSubmit={evt => this.onSubmit(evt) }>
        <div className="registration-passwords">
          <legend>{PASSWORD_RULES_LANGUAGE}</legend>
          <PasswordInput label="Password" name="password" onChange={evt => this.onChange(evt)} onFocus={evt => this.onPasswordFocus(evt)} onBlur={evt => this.onPasswordBlur(evt)} required={true} errorMsg={errors.password}/>
          <PasswordInput label="Confirm Password" name="confirmpassword" onChange={evt => this.onChange(evt)} onFocus={evt => this.onPasswordFocus(evt)} onBlur={evt => this.onConfirmPasswordBlur(evt)} required={true} errorMsg={errors.confirmpassword} />
        </div>

        <button className="button-submit" type="submit" onClick={evt => this.onSubmit(evt)} disabled={submitting || this.state.errors.password || this.state.errors.confirmpassword}>Reset</button>
      </form>
    );
  }

  render() {
    const { submitted, apiError } = this.state;

    return (
      <Page className="resetpassword" title="resetpassword" pageType="resetpassword">
        <h1>Reset Password</h1>
        {submitted && this.renderSuccess() || this.renderForm()}
        {apiError && <ErrorView className="reset-password-error" error={apiError}/>}
      </Page>
    );
  }
}

export default ResetPassword;
