import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Loading from 'components/elements/common/loading/Loading';
import VideoPlayer from 'components/elements/common/videoplayer/VideoPlayer';
import VideoInfo from 'components/elements/pages/video/VideoInfo';
import VideoCommands from 'components/elements/pages/video/VideoCommands';
import { getVideoTrackingDetails } from 'components/utils/analytics';
import { getVideo } from 'store/actions/videos';
import Page from '../Page';

import './Video.scss';

export class Video extends Component {
  componentDidMount() {
    const { match: { params: { id } } } = this.props;
    this.props.getVideo(id);
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { id } }, signedIn } = this.props;
    /* istanbul ignore if */
    if (id !== prevProps.match.params.id || signedIn !== prevProps.signedIn) {
      this.props.getVideo(id);
    }
  }

  render() {
    const { clip, loading, error } = this.props;

    if (loading && !clip.id && !error) {
      return <Loading />;
    }

    let videoPlayerOptions;
    if (clip && clip.id) {
      videoPlayerOptions = {
        muted: false,
        autoplay: false,
        poster: clip.thumb,
        captions: clip.playerCaptions,
        sources: [{ src: clip.watermark, type: 'video/mp4' }],
      };
    }

    return (
      <Page className="video-detail" {...getVideoTrackingDetails(clip)} title={clip.title} pageType="video" error={error}>
        {clip && clip.id && <VideoPlayer {...videoPlayerOptions} clip={clip} />}
        <VideoInfo {...clip} />
        <VideoCommands {...clip} />
      </Page>
    );
  }
}

const mapStateToProps = ({ videos: { clip, loading, error } }) => ({
  clip,
  loading,
  error,
});

const mapDispatchToProps = {
  getVideo,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Video));
