import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showCollectionsModal } from 'store/actions/globalActions';
import { addAssetToQueue, removeAssetFromQueue } from 'store/actions/collections';
import PermissionButton from 'components/elements/common/permission/PermissionButton';

class AddToCollectionButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      added: false,
    };
  }

  add() {
    const { id, mediaType, thumb = '', watermark = '', multiple = false } = this.props;
    this.props.addAssetToQueue({ assetId: `${id}`, assetType: mediaType, thumb, watermark });

    if (multiple) {
      this.setState({ added: true });
    } else {
      this.props.showCollectionsModal();
    }
  }

  remove() {
    const { id } = this.props;
    this.props.removeAssetFromQueue(`${id}`);

    this.setState({ added: false });
  }

  render() {
    const { className: parentClassName, mediaType, label = 'Add' } = this.props;
    const { added } = this.state;
    const className = `${parentClassName}${added && '-added' || ''}`;
    return (
      <React.Fragment>
        {added
        && <button className={className} onClick={() => this.remove()}><span>{ label }</span></button>
        || <PermissionButton permission="collections.create" mediaType={mediaType} className={ `add-button ${className}`} onClick={() => this.add()}>{ label }</PermissionButton>
        }
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  addAssetToQueue,
  removeAssetFromQueue,
  showCollectionsModal,
};

export default connect(null, mapDispatchToProps)(AddToCollectionButton);
