import React, { Component } from 'react';
import TextInput from './TextInput';
import { noop } from '../../../utils/helpers';

class EmailInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMsg: false,
    };
  }

  onBlur(evt) {
    const { onBlur: parentOnBlur = noop } = this.props;
    const email = evt.target.value.trim();
    const error = !!email.length && !evt.target.checkValidity() ? `${email} is not a valid email` : false;
    this.setState({ errorMsg: error });

    parentOnBlur(evt);
  }

  onFocus(evt) {
    const { onFocus: parentOnFocus = noop } = this.props;
    this.setState({ errorMsg: false });
    parentOnFocus(evt);
  }

  render() {
    const errorMsg = this.props.errorMsg || this.state.errorMsg;
    return (
      <TextInput {...this.props} errorMsg={errorMsg} type="email" onFocus={evt => this.onFocus(evt)} onBlur={evt => this.onBlur(evt)} />
    );
  }
}

export default EmailInput;
