import { getAxiosError, updateLocalStorageItem } from 'components/utils/helpers';
import { trackCustomEvent } from 'components/utils/analytics';

import axios from 'axios';
import {
  SHOW_SIGNIN,
  SHOW_ACCESS_DENIED,
  CLOSE_ACCESS_DENIED,
  CLOSE_RESTRICTIONS,
  SHOW_COLLECTIONS_MODAL,
  CLOSE_COLLECTIONS_MODAL,
  SHOW_COMINGSOON_MODAL,
  CLOSE_COMINGSOON_MODAL,
  SHOW_BOOKMARKS_MODAL,
  CLOSE_BOOKMARKS_MODAL,
  SHOW_QUOTAEXCEEDED_MODAL,
  CLOSE_QUOTAEXCEEDED_MODAL,
  SET_ITEMS_LAYOUT,
  ITEMS_LAYOUT_OPTIONS,
  FAILED_SHARING_RECIPIENTS,
  SET_SHARING_DESCRIPTOR,
  SHOW_SHARE_ITEM_MODAL,
  CLOSE_SHARE_ITEM_MODAL,
  RESET_SHARING,
  SET_SHARING_LOADING,
  SET_SHARING_ERROR,
  ADD_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  SHOW_ASSET_NOT_AVAILABLE_MODAL,
  CLOSE_ASSET_NOT_AVAILABLE_MODAL,
  SHOW_ANNOUNCEMENT_MODAL,
  CLOSE_ANNOUNCEMENT_MODAL,
} from '../reducers/globalActions';
import { ASSET_TYPES, SEARCH_HISTORY_STORAGE_KEY } from '../../components/utils/constants';

const TIMEOUT = process.env.SIGNIN_TIMEOUT || 10000;

export const showSignIn = (invalidToken = false) => ({
  type: SHOW_SIGNIN,
  data: { invalidToken },
});

export const showAccessDenied = () => ({
  type: SHOW_ACCESS_DENIED,
});

export const closeAccessDenied = () => ({
  type: CLOSE_ACCESS_DENIED,
});

export const closeRestrictions = () => ({
  type: CLOSE_RESTRICTIONS,
});

export const showCollectionsModal = () => ({
  type: SHOW_COLLECTIONS_MODAL,
});

export const closeCollections = () => ({
  type: CLOSE_COLLECTIONS_MODAL,
});

export const showBookmarksModal = () => ({
  type: SHOW_BOOKMARKS_MODAL,
});

export const closeBookmarksModal = () => ({
  type: CLOSE_BOOKMARKS_MODAL,
});

export const showComingSoonModal = () => ({
  type: SHOW_COMINGSOON_MODAL,
});

export const closeComingSoonModal = () => ({
  type: CLOSE_COMINGSOON_MODAL,
});

export const showQuotaExceededModal = () => ({
  type: SHOW_QUOTAEXCEEDED_MODAL,
});

export const closeQuotaExceededModal = () => ({
  type: CLOSE_QUOTAEXCEEDED_MODAL,
});

export const showShareItemModal = () => ({
  type: SHOW_SHARE_ITEM_MODAL,
});

export const closeShareItemModal = () => ({
  type: CLOSE_SHARE_ITEM_MODAL,
});

export const showAssetNotAvailableModal = () => ({
  type: SHOW_ASSET_NOT_AVAILABLE_MODAL,
});

export const closeAssetNotAvailableModal = () => ({
  type: CLOSE_ASSET_NOT_AVAILABLE_MODAL,
});

export const setFailedSharingRecipients = failedSharingRecipients => ({
  type: FAILED_SHARING_RECIPIENTS,
  data: { failedSharingRecipients },
});

export const setSharingDescriptor = descriptor => ({
  type: SET_SHARING_DESCRIPTOR,
  data: { descriptor },
});

export const setSharingLoading = (loading = true) => ({
  type: SET_SHARING_LOADING,
  data: { loading },
});

export const setSharingError = error => ({
  type: SET_SHARING_ERROR,
  data: { error },
});

export const addNotification = ({ type, message, details }) => ({
  type: ADD_NOTIFICATION,
  data: {
    notification: {
      type,
      message: typeof message === 'object' ? message.code || message.error : message,
      details: details || (typeof message === 'object' ? message.correlationId : ''),
    },
  },
});

export const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS,
});

export const resetSharing = () => ({
  type: RESET_SHARING,
});

export const setLayout = (layout = ITEMS_LAYOUT_OPTIONS.grid) => {
  updateLocalStorageItem(SEARCH_HISTORY_STORAGE_KEY, layout, 'layout');
  return {
    type: SET_ITEMS_LAYOUT,
    data: { layout },
  };
};

export const toAssetContext = (mediaType) => {
  switch (mediaType) {
    case ASSET_TYPES.image.type:
      return ASSET_TYPES.image.context;
    case ASSET_TYPES.news.type:
      return ASSET_TYPES.news.context;
    case ASSET_TYPES.video.type:
      return ASSET_TYPES.video.context;
    case ASSET_TYPES.collection.type:
      return ASSET_TYPES.collection.context;
    case ASSET_TYPES.search.type:
      return ASSET_TYPES.search.context;
    default:
      throw new Error(`Unsupported media type: ${mediaType}`);
  }
};

export const shareAsset = asset => async (dispatch) => {
  const { id, name, mediaType, emails, sendEmails } = asset;
  dispatch(setSharingLoading());
  try {
    const { data: sendStatusByEmail } = await axios.put(`/api/${toAssetContext(mediaType)}/${id}/share`, { sendEmails, emails }, { timeout: TIMEOUT });
    const failedRecipients = [];

    Object.keys(sendStatusByEmail).forEach((email) => {
      if (sendStatusByEmail[email] !== true) {
        failedRecipients.push({ email, error: sendStatusByEmail[email] });
      }
    });

    dispatch(setSharingLoading(false));
    dispatch(setFailedSharingRecipients(failedRecipients));
    trackCustomEvent({
      category: mediaType,
      action: 'share',
      label: name,
    }, {
      id,
    });
    return !failedRecipients.length;
  } catch (err) {
    const { message } = getAxiosError(err);
    dispatch(setSharingError(message));
    return false;
  }
};

export const showAnnouncementModal = () => ({
  type: SHOW_ANNOUNCEMENT_MODAL,
});

export const closeAnnouncementModal = () => ({
  type: CLOSE_ANNOUNCEMENT_MODAL,
});
