import React from 'react';
import { connect } from 'react-redux';
import PillFilter from '../PillFilter';

const TopicsFilters = ({ topics }) => (
  <PillFilter className="topics-filters" name="Topics" placeholder="Search Topics" items={topics} filterKey="topics" />
);

const mapStateToProps = ({ search: searchStore }) => ({
  topics: Object.values(searchStore.filters.topics || {}),
});

export default connect(mapStateToProps)(TopicsFilters);
