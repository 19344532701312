import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toggle from 'react-toggle';
import { updatePreferences } from 'store/actions/user';
import { AUTO_REFRESH_INTERVAL } from 'components/utils/constants';
import Dropdown from 'components/elements/common/dropdown/Dropdown';
import Radio from 'components/elements/common/forms/radio/Radio';
import Page from '../Page';
import './UserPreferences.scss';

const INTERVALS = [
  { name: 'High', value: AUTO_REFRESH_INTERVAL.high },
  { name: 'Medium', value: AUTO_REFRESH_INTERVAL.medium },
  { name: 'Low', value: AUTO_REFRESH_INTERVAL.low },
];

class UserPreferences extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props.preferences, status: '' };
  }

  componentDidUpdate(prevProps) {
    /* istanbul ignore if */
    if (prevProps.finishedAutoSignin !== this.props.finishedAutoSignin) {
      const { preferences: { autoRefresh, autoRefreshInterval } } = this.props;
      this.setState({ autoRefresh, autoRefreshInterval });
    }
  }

  handleOptionChange(key, value) {
    this.setState({ [key]: value });
  }

  onSubmit() {
    // defaulting the interval prevents saving undefined intervals
    const { autoRefresh, autoRefreshInterval = AUTO_REFRESH_INTERVAL.low } = this.state;
    this.props.updatePreferences({ autoRefresh, autoRefreshInterval });
    this.setState({ autoRefreshInterval, status: 'saved!' });
    /* istanbul ignore next */
    setTimeout(() => {
      this.setState({ status: '' });
    }, 1000);
  }

  render() {
    // defaulting the interval here fixes all the users who currently have interval undefined
    const { autoRefresh, autoRefreshInterval = AUTO_REFRESH_INTERVAL.low, status } = this.state;
    return (
      <Page className="user-preferences" title="User Preferences" pageType="userPreferences">
        <h1>Preferences</h1>
        <div className="user-preferences--autorefresh">
          <label className="user-preferences--autorefresh-title">
            <span>Auto-refresh</span>
            <Toggle
              className="accent-toggle"
              checked={autoRefresh || false}
              icons={false}
              onChange={() => this.handleOptionChange('autoRefresh', !autoRefresh)}
            />
          </label>
          {
            autoRefresh
            && <Dropdown className="user-preferences--autorefresh-dropdown" title="Auto-refresh Interval" alwaysOpen>
              <ul>
                {INTERVALS.map(({ name, value }) => (<li key={value}><Radio checked={autoRefreshInterval === value} onChange={() => this.handleOptionChange('autoRefreshInterval', value)}>{`${name} (${value} min)`}</Radio></li>))}
              </ul>
            </Dropdown>
          }
        </div>
        <div className="user-preferences--footer">
          <button className="item-new-actions-save button-submit" type="submit" onClick={evt => this.onSubmit(evt)}>Save</button>
          <span className="user-preferences--footer-status">{status}</span>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = ({ user: userStore }) => ({
  preferences: userStore.user.preferences,
  finishedAutoSignin: userStore.user.finishedAutoSignin,
});

const mapDispatchToProps = {
  updatePreferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPreferences);
