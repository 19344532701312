import axios from 'axios';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import ErrorView from 'elements/common/ErrorView';
import Loading from 'elements/common/loading/Loading';
import { getAxiosError } from 'components/utils/helpers';
import Page from '../Page';

import './Activation.scss';

const REGISTRATION_TIMEOUT = process.env.REGISTRATION_TIMEOUT || 10000;

class Activation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    this.validateActivation();
  }

  async validateActivation() {
    try {
      const { match: { params: { bconnectId, csId, validationKey } } } = this.props;
      await axios.post(`/api/activate/${csId}/${bconnectId}/${validationKey}`, {}, { timeout: REGISTRATION_TIMEOUT });
    } catch (err) {
      const { message } = getAxiosError(err);
      this.setState({ error: message });
    }
    this.setState({ loading: false });
  }

  render() {
    const { error, loading } = this.state;
    return (
      <Page className="activation" title="activation" pageType="activation" error={error}>
        {loading && <Loading />}
        {error && <ErrorView className="activation--error" error={error} />}
        {!loading && !error
          && <div className="activation--success">
            <h1>Registration Complete</h1>
            <p>You are now activated!</p>
            <p>You may now <Link to="/login">sign in</Link> to begin using the site.</p>
          </div>
        }
      </Page>
    );
  }
}

export default withRouter(Activation);
