import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Loading from 'components/elements/common/loading/Loading';
import { fetchTopicpages } from 'store/actions/topicpage';
import Page from '../Page';
import modules from './modules';

import '../homepage/Homepage.scss';

export class TopicPage extends Component {
  componentDidMount() {
    const { match: { params: { topic } } } = this.props;
    this.props.fetchTopics(topic);
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { topic } } } = this.props;
    const { match: { params: { topic: previousTopic } } } = prevProps;

    /* istanbul ignore next */
    if (topic !== previousTopic) {
      this.props.fetchTopics(topic);
    }
  }

  renderModule(module, i) {
    const mercuryType = (module.mercuryType || module.type || '').toLowerCase();
    const Element = modules[mercuryType] || modules.default;
    const key = `${mercuryType}_${i}`;
    return (
      <Element key={key} row={i} { ...module } />
    );
  }

  render() {
    const { loading, error, topics, match: { params: { topic } } } = this.props;

    return (
      <Page className="topic" title={topic} pageType="topics" error={error}>
        {loading && !error && <Loading />}
        {topics.map((module, i) => this.renderModule(module, i))}
      </Page>
    );
  }
}

const mapStateToProps = ({ topicpage: { topics, loading, error } }) => ({
  topics,
  loading,
  error,
});

const mapDispatchToProps = dispatch => ({
  fetchTopics: topic => dispatch(fetchTopicpages((topic))),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopicPage));
