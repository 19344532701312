import React, { Component } from 'react';
import { connect } from 'react-redux';
import mt from 'moment-timezone';
import moment from 'moment';
import { updateFilter } from 'store/actions/search';
import Datepicker from 'common/forms/datepicker/Datepicker';
import { EARLIEST_CONTENT_DATE } from '../../../../utils/constants';

class DateFilters extends Component {
  updateDateFilter(date, name) {
    let searchDate;
    if (name === 'before' && date) {
      searchDate = mt.min(mt(), mt(date).endOf('day'));
    } else if (name === 'after' && date) {
      searchDate = mt(date).startOf('day');
    }

    this.props.updateFilter('dateRange', name, searchDate && mt(searchDate).format() || null);
  }

  render() {
    const now = new Date();
    const { filters: { dateRange: { before, after } } } = this.props;

    const afterDate = after && mt(after).toDate() || null;
    const beforeDate = before && mt(before).toDate() || now;

    const afterYearDropdownItems = Math.abs(moment(afterDate || now).diff(EARLIEST_CONTENT_DATE, 'years'));
    const beforeYearDropdownItems = Math.abs(moment(beforeDate).diff(EARLIEST_CONTENT_DATE, 'years'));

    const commonParams = {
      startDate: afterDate,
      endDate: beforeDate,
    };

    return (
      <div className="filters--section">
        <legend>Date Range</legend>
        <div className="filters--dates">
          <div className="filters--date">
            <legend>Start Date:</legend>
            <Datepicker
              name="start-date-filter-datepicker"
              {...commonParams}
              selectsStart
              yearDropdownItemNumber={afterYearDropdownItems}
              selected={afterDate}
              maxDate={beforeDate}
              onChange={date => this.updateDateFilter(date, 'after')}
            />
          </div>

          <div className="filters--date">
            <legend>End Date:</legend>
            <Datepicker
              name="end-date-filter-datepicker"
              {...commonParams}
              selectsEnd
              yearDropdownItemNumber={beforeYearDropdownItems}
              selected={beforeDate}
              minDate={afterDate}
              maxDate={now}
              onChange={date => this.updateDateFilter(date, 'before')}
              popperModifiers={{
                offset: {
                  offset: '25px, 0',
                },
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ search: searchStore }) => ({
  filters: searchStore.filters,
});

const mapDispatchToProps = {
  updateFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(DateFilters);
