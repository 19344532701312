import React from 'react';
import SearchTerm from 'components/elements/common/searchterm/SearchTerm';

const searchTermsSection = (key, searchTerms, mediaType) => {
  if (!searchTerms[key] || !searchTerms[key].length) {
    return null;
  }
  return (
    <div key={`searchTerms-${key}`} className={`photo-info-${key}`}>
      <h4>{key}</h4>
      <p>{searchTerms[key].map((searchTerm, index) => <SearchTerm className="photo-info-keyword" key={index} type={key} mediaType={mediaType} {...searchTerm}/>)}</p>
    </div>
  );
};

export default searchTermsSection;
