import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setQuery, resetResults, search, updateFilter, resetFilterGroups } from 'store/actions/search';
import { ASSET_TYPES } from '../../../utils/constants';

class GroupActionButton extends Component {
  onClick() {
    const { group } = this.props;
    this.props.setQuery(`Related:${group.id}`);
    this.props.resetFilterGroups(['media']);
    this.props.updateFilter('media', ASSET_TYPES.image.media, true);
    this.props.search();
  }

  render() {
    const { className, group } = this.props;

    return (
      <button className={className} onClick={() => this.onClick()}>{group.total}</button>
    );
  }
}

const mapDispatchToProps = {
  setQuery,
  resetResults,
  search,
  updateFilter,
  resetFilterGroups,
};

export default connect(null, mapDispatchToProps)(GroupActionButton);
