import React, { Component } from 'react';
import uuid from 'uuid';
import { connect } from 'react-redux';
import FilterGroup from '../FilterGroup';
import PeopleFilters from './PeopleFilters';
import CompanyFilters from './CompaniesFilters';
import TopicFilters from './TopicsFilters';
import VideoSeriesFilters from './VideoSeriesFilters';
import KeywordFilters from './KeywordFilter';
import ClearAllFilters from './ClearAllFilters';

class SearchTermsFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && prevProps.countSearchTerms !== this.props.countSearchTerms) {
      this.setState({ open: uuid.v4() }); // trigger a state change
    }
  }

  render() {
    const { open } = this.state;
    const { videoSelected } = this.props;
    return (
      <FilterGroup className="search-terms" name="Search Terms" open={open}>
        <KeywordFilters />
        <TopicFilters />
        <CompanyFilters />
        <PeopleFilters />
        {videoSelected && <VideoSeriesFilters />}
        <ClearAllFilters />
      </FilterGroup>
    );
  }
}

const mapStateToProps = ({ search: { filters: { media: { video }, people = {}, companies = {}, topics = {}, series = {} } } }) => ({
  countSearchTerms: [Object.keys(people), Object.keys(companies), Object.keys(topics), Object.keys(series)].reduce((sum, keys) => sum + keys.length, 0),
  videoSelected: video,
});

export default connect(mapStateToProps)(SearchTermsFilters);
