import React from 'react';
import { getStoryTitle } from '../../../../../utils/helpers';
import './Contributor.scss';

const Contributors = ({ contributor }) => {
  const { label, url } = contributor;
  const title = getStoryTitle(contributor);
  const abbv = label || title[0];

  return (
    <a className="contributors-contributor" href={url}>
      <span className="contributors-contributor-abbv">{abbv}</span>
      <span className="contributors-contributor-title">{title}</span>
    </a>
  );
};

export default Contributors;
