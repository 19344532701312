import { cloneElement } from 'react';
import { connect } from 'react-redux';

import './Item.scss';

const Item = ({ globalLayout, item, ListItem, GridItem, defaultLayout }) => {
  const layout = defaultLayout || globalLayout;
  const View = layout === 'list' ? ListItem : GridItem;
  return cloneElement(View, { ...item });
};

const mapStateToProps = ({ globalActions }) => ({
  globalLayout: globalActions.layout,
});

export default connect(mapStateToProps)(Item);
