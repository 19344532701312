import { QUICK_FIND_OPTIONS, QUICK_FIND_BASE_MENU } from 'components/utils/constants';

export const QUICK_FIND_SET_DROPDOWN_OPEN = 'redux/quickFind/QUICK_FIND_SET_DROPDOWN_OPEN';
export const QUICK_FIND_SET_SELECTED = 'redux/quickFind/QUICK_FIND_SET_SELECTED';
export const QUICK_FIND_SET_COLUMN_OPEN = 'redux/quickFind/QUICK_FIND_SET_COLUMN_OPEN';
export const QUICK_FIND_SET_SUBMENU_OPEN = 'redux/quickFind/QUICK_FIND_SET_SUBMENU_OPEN';
export const QUICK_FIND_SET_COLUMN_OPTIONS = 'redux/quickFind/QUICK_FIND_SET_COLUMN_OPTIONS';
export const QUICK_FIND_RESET = 'redux/quickFind/RESET_QUICK_FIND';

export const QUICK_FIND_UPDATE_MENU = 'redux/quickFind/QUICK_FIND_UPDATE_MENU';

export const initialState = {
  dropdownOpen: false,
  columns: {
    1: { open: true, selected: '', options: QUICK_FIND_OPTIONS.firstColumn },
    2: { open: false, selected: '', options: QUICK_FIND_OPTIONS.secondColumn },
    3: { open: false, selected: '', options: [] },
  },
  subMenuOpen: '',
  menu: QUICK_FIND_BASE_MENU,
};

const quickFind = (state = initialState, action) => {
  switch (action.type) {
    case QUICK_FIND_SET_DROPDOWN_OPEN:
      return {
        ...state,
        dropdownOpen: action.data.open,
      };
    case QUICK_FIND_SET_SELECTED:
      return {
        ...state,
        columns: {
          ...state.columns,
          [action.data.column]: {
            ...state.columns[action.data.column],
            selected: action.data.selected,
          },
        },
      };
    case QUICK_FIND_SET_COLUMN_OPEN:
      return {
        ...state,
        columns: {
          ...state.columns,
          [action.data.column]: {
            ...state.columns[action.data.column],
            open: action.data.open,
          },
        },
      };
    case QUICK_FIND_SET_SUBMENU_OPEN:
      return {
        ...state,
        subMenuOpen: action.data.open,
      };
    case QUICK_FIND_UPDATE_MENU:
      return {
        ...state,
        menu: [
          ...state.menu,
          ...action.data.menu,
        ],
      };
    case QUICK_FIND_RESET:
      return {
        ...initialState,
        menu: state.menu, // dont reset the menu
      };
    default:
      return state;
  }
};

export default quickFind;
