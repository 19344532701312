import React from 'react';
import { LEDE_MODULE_TITLE } from 'components/utils/constants';
import Story from '../common/Story';
import './Lede.scss';

const Lede = ({ items = [], row }) => {
  const [story = {}] = items;
  const crops = {
    desktop: '-1x-1',
    tablet: '768x274',
    mobile: '320x169',
  };

  return (
    <section className="lede">
      <Story story={story} crops={crops} row={row} column={0} moduleTitle={LEDE_MODULE_TITLE} showLabel />
    </section>
  );
};

export default Lede;
