import React from 'react';
import Truncate from 'react-truncate';
import VideoContentOptions from 'components/elements/pages/video/seriesContent/VideoContentOptions';
import './SeriesCatalogList.scss';
import { Link } from 'react-router-dom';
import { formatLink } from '../../../../utils/helpers';

const SeriesCatalogList = (props) => {
  const { series, isContentPage } = props;
  const items = series.map((item, index) => (
    <div key={item.id || `series-list-${index}`} className="series-catalog-list--item">
      <Link to={formatLink({ url: item.url })}>
        <img src={item.img} alt={item.title} />
      </Link>
      <div className="series-catalog-list--item-title">
        <Link to={formatLink({ url: item.url })}>{item.title}</Link>
      </div>
      {!isContentPage && <div className="series-catalog-list--item-summary">{item.summary}</div>}
      {
        isContentPage
        && <React.Fragment>
          <div className="series-catalog-list--item-summary">
            <Truncate lines={3} ellipsis={'...'}>{item.summary}</Truncate>
          </div>
          <VideoContentOptions {...item} />
        </React.Fragment>
      }
    </div>
  ));

  return (
    <div className="series-catalog-list">
      {items}
    </div>
  );
};

export default SeriesCatalogList;
