import React, { Component } from 'react';
import axios from 'axios';
import ErrorView from 'elements/common/ErrorView';
import { getAxiosError } from 'components/utils/helpers';
import Loading from 'common/loading/Loading';
import DownloadHistoryRow from './DownloadHistoryRow';

const TIMEOUT = 5000;

export class DownloadHistory extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      downloads: [],
      error: null,
    };
  }

  componentDidMount() {
    this.fetchDownloadHistory();
  }

  async fetchDownloadHistory() {
    try {
      const { data: downloads } = await axios.get('/api/audits/download/stats', { timeout: TIMEOUT });
      this.setState({ downloads });
    } catch (err) {
      const { message } = getAxiosError(err);
      this.setState({ error: message });
    }
    this.setState({ loading: false });
  }

  render() {
    const { downloads, loading, error } = this.state;

    return (
      <section className="usage--history">
        <h2>Download History</h2>
        {loading && <Loading/>}
        {downloads.length > 0
          && <div className="usage-container">
            <div className="usage-container--header-row">
              <h4>User</h4>
              <h4>Quota</h4>
              <h4>Duration </h4>
              <h4>Asset Id</h4>
              <h4>Media Type</h4>
              <h4>Download Date</h4>
            </div>
            {downloads.map((download, index) => (<DownloadHistoryRow {...download} key={index} />))}
          </div>
        }
        {error && <ErrorView error={error} />}
      </section>
    );
  }
}

export default DownloadHistory;
