import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Loading from 'components/elements/common/loading/Loading';
import SeriesCatalogList from 'components/elements/pages/video/seriesCatalog/SeriesCatalogList';
import FeaturedSeries from 'components/elements/pages/video/common/FeaturedSeries';
import { getVideoSeriesContent } from 'store/actions/videoSeriesContent';
import Page from '../../Page';

import './VideoSeriesContent.scss';

export class VideoSeriesContent extends Component {
  componentDidMount() {
    const { match: { params: { id } } } = this.props;
    this.props.getVideoSeriesContent(id);
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { id } } } = this.props;
    const { match: { params: { id: previousId } } } = prevProps;

    if (id !== previousId) {
      this.props.getVideoSeriesContent(id);
    }
  }

  render() {
    const { loading, error, content } = this.props;

    if (loading && !error) {
      return <Loading />;
    }

    return (
      <Page className="video-series-content" title={`${content.title}`} pageType="video-series-content" error={error}>
        <FeaturedSeries infiniteLoop={false} autoPlay={false} showIndicators={false} showThumbs={false} series={content.id ? [content] : []} isContentPage={true} />
        <SeriesCatalogList series={content.episodes || []} isContentPage={true} />
      </Page>
    );
  }
}

const mapStateToProps = ({ videoSeriesContent: { content, loading, error } }) => ({
  content,
  loading,
  error,
});

const mapDispatchToProps = {
  getVideoSeriesContent,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideoSeriesContent));
