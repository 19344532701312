import React, { Component } from 'react';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import { connect } from 'react-redux';
import ErrorView from 'elements/common/ErrorView';
import Loading from 'components/elements/common/loading/Loading';
import Checkbox from 'common/forms/checkbox/Checkbox';
import 'react-multi-email/style.css';
import { shareAsset, closeShareItemModal, resetSharing } from 'store/actions/globalActions';
import Modal from './Modal';
import { ASSET_TYPES, PERMISSIONS } from '../../../utils/constants';

import './ShareItemModal.scss';

export const MultipleEmailInput = /* istanbul ignore next */ ({ emails, updateEmails }) => (
  <div className="shareitem-modal--form--multi-email">
    <label>
      <span>Enter email addresses</span>
      <ReactMultiEmail
        placeholder={<span className="placeholder">Enter email addresses</span>}
        style={{ minHeight: '100px' }}
        emails={emails}
        onChange={updateEmails}
        validateEmail={email => isEmail(email)} // return boolean
        getLabel={(email, index, removeEmail) => (
          <div key={index} className="email-data-tag">
            {email}
            <span onClick={() => removeEmail(index)}>×</span>
          </div>
        )}
      />
    </label>
  </div>
);

class ShareItemModal extends Component {
  constructor() {
    super();
    this.state = {
      emails: [],
      success: null,
      sendEmails: true,
    };
  }

  closeModal() {
    this.setState({ emails: [], success: null });
    this.props.resetSharing();
    this.props.closeShareItemModal();
  }

  async onSubmit(evt) {
    evt.preventDefault();
    const { emails, sendEmails } = this.state;

    if (emails.length) {
      const { item: { id, name }, mediaType } = this.props;
      const success = await this.props.shareAsset({ id, name, mediaType, emails, sendEmails });
      this.setState({ success, sendEmails, emails: this.props.failedSharingRecipients.map(({ email }) => email) });

      if (success) {
        /* istanbul ignore next */
        setTimeout(() => this.closeModal(), 1000);
      }
    }
  }

  mailStatus() {
    const { failedSharingRecipients } = this.props;
    if (failedSharingRecipients.length) {
      return (
        <div className="shareitem-modal--mailstatus">
          <h5>Failed to share search with following recipients:</h5>
          <ul>
          {failedSharingRecipients.map((share, index) => (
            <li key={share.email + index}>{share.email}: {share.error}</li>
          ))}
          </ul>
        </div>
      );
    }

    if (this.state.success) {
      return (
        <div className="shareitem-modal--mailstatus">
          <h5>Mail Sent!</h5>
        </div>
      );
    }
  }

  // can be moved to helpers if used elsewhere
  toMediaName(mediaType) {
    switch (mediaType) {
      case ASSET_TYPES.image.type:
        return ASSET_TYPES.image.name;
      case ASSET_TYPES.news.type:
        return ASSET_TYPES.news.name;
      case ASSET_TYPES.collection.type:
        return ASSET_TYPES.collection.name;
      case ASSET_TYPES.search.type:
        return ASSET_TYPES.search.name;
      case ASSET_TYPES.video.type:
        return ASSET_TYPES.video.name;
      default:
        return 'Unsupported'; // cannot throw an error bcz this is a global component that is rendered after sign-in w/o a specific media-type
    }
  }

  render() {
    const { open, mediaType, loading, error, userLevel } = this.props;
    const { emails } = this.state;
    return (
      <React.Fragment>
        <Modal className="shareitem-modal" theme="dark" open={open} handleClose={() => this.closeModal()}>
          <h3>Share with others</h3>
          <form method="post" onSubmit={evt => this.onSubmit(evt) } className="shareitem-modal--form">
            <MultipleEmailInput emails={emails} updateEmails={ids => this.setState({ emails: ids })} />
            {userLevel === PERMISSIONS.admin && <div>
              <Checkbox
                checked={this.state.sendEmails}
                onChange={({ target: { checked } }) => this.setState(() => ({ ...this.state, sendEmails: checked }))}
              >
                Notify recipients via email
              </Checkbox>
            </div>}
            <div>
              <button className="shareitem-modal button-contact" type="submit" disabled={loading}>Share {this.toMediaName(mediaType)}</button>
              {loading && <Loading />}
              {error && <ErrorView className="item-new-error" error={error} />}
            </div>
            {this.mailStatus()}
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ globalActions: { sharing } }) => ({
  failedSharingRecipients: sharing.failedSharingRecipients,
  open: sharing.showShareItemModal,
  mediaType: sharing.descriptor.mediaType,
  item: sharing.descriptor.item,
  loading: sharing.descriptor.loading,
  error: sharing.descriptor.error,
  userLevel: sharing.descriptor.userLevel,
});

const mapDispatchToProps = {
  shareAsset,
  closeShareItemModal,
  resetSharing,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareItemModal);
