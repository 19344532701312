export const SET_NEWS_LOADING = 'redux/news/SET_NEWS_LOADING';
export const SET_NEWS_ERROR = 'redux/news/SET_NEWS_ERROR';
export const SET_NEWS_DOWNLOAD_ERROR = 'redux/news/SET_NEWS_DOWNLOAD_ERROR';
export const SET_STORY = 'redux/news/SET_STORY';
export const ADD_STORY = 'redux/news/ADD_STORY';
export const REMOVE_NEWS_DOWNLOAD_ERRORS = 'redux/news/REMOVE_NEWS_DOWNLOAD_ERRORS';

export const initialState = {
  loading: false,
  error: false,
  downloadErrors: {},
  news: {},
  story: {},
};

const news = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWS_LOADING:
      return {
        ...state,
        error: false,
        loading: action.data.loading,
      };
    case SET_NEWS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data.error,
      };
    case SET_NEWS_DOWNLOAD_ERROR:
      return {
        ...state,
        downloadErrors: {
          ...state.downloadErrors,
          [action.data.id]: action.data.error,
        },
      };
    case SET_STORY:
      return {
        ...state,
        story: action.data.story,
      };
    case ADD_STORY:
      return {
        ...state,
        error: false,
        loading: false,
        news: {
          ...state.news,
          [action.data.story.id]: action.data.story,
        },
      };
    case REMOVE_NEWS_DOWNLOAD_ERRORS:
      return {
        ...state,
        downloadErrors: {},
      };
    default:
      return state;
  }
};

export default news;
