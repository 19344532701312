import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { removeAssetsFromCollection } from 'store/actions/collection';
import CollectionDetailsOptions from 'elements/pages/collection/CollectionDetailsOptions';
import NavigationBar from 'elements/common/navigationbar/NavigationBar';
import RemoveAssetsModal from 'components/elements/common/modal/collections/RemoveAssetsModal';
import ErrorView from 'elements/common/ErrorView';
import { PERMISSIONS } from 'components/utils/constants';
import Loading from 'components/elements/common/loading/Loading';

import './CollectionDetailsNav.scss';

class CollectionDetailsNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  openModal() {
    this.setState({ modalOpen: true });
  }

  render() {
    const { modalOpen } = this.state;
    const { assets, collection, loading, error } = this.props;
    const { permission } = collection;
    return (
      <React.Fragment>
        <NavigationBar className="collections--nav collection-details-nav"
          leftSide={(
            <React.Fragment>
              <Link to="/search" className="button--search" >Back to Search</Link>
              <Link to="/collections" className="button--my-collections">Collections</Link>
              {(permission === PERMISSIONS.edit || permission === PERMISSIONS.own)
              && <button className="button--remove-from-collection" onClick={() => this.openModal()}
                      disabled={!assets.length}>
                Remove {!!assets.length && <span>({assets.length})</span>}</button>}
            </React.Fragment>
          )}
          center={(
            <CollectionDetailsOptions/>
          )}
        />
        {loading && <Loading />}
        {error && <ErrorView className="collections-new-error" error={error} />}
        <RemoveAssetsModal open={modalOpen} collection={collection} numberOfAssets={assets.length} handleClose={() => this.closeModal()}/>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ collections, collection }) => ({
  assets: collections.workQueue,
  collection: collection.collection,
  loading: collection.loading,
  error: collection.error,
});

const mapDispatchToProps = {
  remove: removeAssetsFromCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionDetailsNav);
