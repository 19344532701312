import React, { Component } from 'react';
import { getCropImages } from 'components/utils/helpers';

import './ResponsiveImage.scss';

export const MAX_RETRIES = 3;
export const RETRY_TIMEOUT_MULTIPLIER = 2;

class ResponsiveImage extends Component {
  constructor(props) {
    super(props);
    this.numRetries = 0;
    this.retryTimeout = 250;
    this.imageRef = React.createRef();
    this.desktopRef = React.createRef();
    this.tabletRef = React.createRef();
    this.mobileRef = React.createRef();
  }

  onImageFailure() {
    if (this.numRetries === MAX_RETRIES) return;

    // you must refresh the correct srcSet
    let currentView;
    // istanbul ignore next -- unable to mock or manipulate currentSrc
    if (this.desktopRef.srcset === this.imageRef.currentSrc) {
      currentView = 'desktopRef';
    } else if (this.tabletRef.srcset === this.imageRef.currentSrc) {
      currentView = 'tabletRef';
    } else {
      currentView = 'mobileRef';
    }

    setTimeout(() => {
      // setting image src to null triggers a recall when set back to a real url
      this[currentView].srcset = null;
      this[currentView].srcset = this.imageRef.currentSrc;
    }, this.retryTimeout);

    this.numRetries++;
    this.retryTimeout *= RETRY_TIMEOUT_MULTIPLIER;
  }

  render() {
    const { className, imageUrl, crops, title, caption } = this.props;
    const { desktop, tablet, mobile } = getCropImages(imageUrl, crops);

    return (
      <figure className={className}>
        <picture>
          <source media="(min-width: 769px)" srcSet={desktop} ref={(desktopRef) => { this.desktopRef = desktopRef; }}/>
          <source media="(min-width: 600px)" srcSet={tablet} ref={(tabletRef) => { this.tabletRef = tabletRef; }}/>
          <source srcSet={mobile} ref={(mobileRef) => { this.mobileRef = mobileRef; }}/>
          <img
            className="ri-image"
            srcSet={desktop}
            alt={title}
            onError={() => this.onImageFailure()}
            ref={(imageRef) => { this.imageRef = imageRef; }}
            />
        </picture>
        {caption && <figcaption><div dangerouslySetInnerHTML={{__html:caption}}/></figcaption>}
      </figure>
    );
  }
}
export default ResponsiveImage;
