import React from 'react';
import { connect } from 'react-redux';
import { SEARCH_RESULT_LIMIT } from 'components/utils/constants';
import { formatNumber } from 'components/utils/helpers';

const NumResults = ({ totals, page }) => {
  const total = Object.keys(totals).length && Object.keys(totals).map(key => totals[key]).reduce((sum, mediaTotal) => sum + mediaTotal) || false;
  const showing = Math.min(page * SEARCH_RESULT_LIMIT, total);

  return (
    <div className="search-nav-results">
      {!!total && <span>{formatNumber(showing)} of {formatNumber(total)}</span>}
    </div>
  );
};

const mapStateToProps = ({ search }) => ({
  totals: search.totals,
  page: search.page,
});

export default connect(mapStateToProps)(NumResults);
