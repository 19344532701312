import axios from 'axios';
import { SET_AUTOCOMPLETE, SET_AUTOCOMPLETE_LOADING } from '../reducers/autocomplete';

export const setAutocompleteLoading = (loading = true) => ({
  type: SET_AUTOCOMPLETE_LOADING,
  data: { loading },
});

export const setSuggestions = suggestions => (dispatch) => {
  dispatch({
    type: SET_AUTOCOMPLETE,
    data: { suggestions },
  });
};

// contexts is a comma-delimited string of autocomplete context names: SEARCH_CONTEXTS[context].autocomplete
export const autocomplete = (query, contexts = '') => async (dispatch) => {
  try {
    dispatch(setAutocompleteLoading());
    const contextQueryParam = contexts ? `&contexts=${contexts}` : '';
    const { data } = await axios.get(`/api/autocomplete?query=${query}${contextQueryParam}`);
    dispatch(setSuggestions(data));
  } catch (err) {
    console.error(`Failed to get autocomplete suggestions with error: ${err.message}`);
  }
  dispatch(setAutocompleteLoading(false));
};
