import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import './Dropdown.scss';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: props.alwaysOpen || false,
    };
  }

  toggleDropdown() {
    this.setState(prevState => ({ showDropdown: !prevState.showDropdown }));
  }

  hideDropdown() {
    this.setState({ showDropdown: false });
  }

  render() {
    const { title, children, alwaysOpen, closeOnClick, className, onOpenClassName } = this.props;
    const { showDropdown } = this.state;

    return (
      <OutsideClickHandler onOutsideClick={() => !alwaysOpen && this.hideDropdown()}>
        <div className={`dropdown-container ${className} ${showDropdown && onOpenClassName}`}>
          <button className="dropdown-title" onClick={() => !alwaysOpen && this.toggleDropdown()}>{title} <span className="dropdown-title--icon" /></button>
          {showDropdown && <div className="dropdown-list" onClick={() => !alwaysOpen && closeOnClick && this.hideDropdown()}>{children}</div>}
        </div>
      </OutsideClickHandler>
    );
  }
}

export default Dropdown;
