import React from 'react';
import ResponsiveImage from 'components/elements/common/ResponsiveImage/ResponsiveImage';
import Truncate from 'react-truncate';
import { Link } from 'react-router-dom';
import { trackCustomEvent } from 'utils/analytics';
import { getStoryTitle, isInternalLink, formatLink } from 'utils/helpers';
import { LEDE_MODULE_TITLE } from 'components/utils/constants';
import './Story.scss';

const trackStoryClick = (props) => {
  trackCustomEvent({
    category: 'homepage',
    action: 'selected',
    label: `${props.title} | ${props.moduleTitle} | ${props.row}-${props.column}`,
  }, {
    row: props.row,
    column: props.column,
    title: props.title,
    pageSubType: props.moduleTitle,
  });
};

export const InternalExternalLink = (props) => {
  if (isInternalLink(props.story)) {
    const link = formatLink(props.story);
    return (
      <Link className={props.className} to={link} onClick={() => trackStoryClick(props)}>{props.children}</Link>
    );
  }
  return (
    <a href={props.story.url} className={props.className} target="_blank" rel="noopener noreferrer" onClick={() => trackStoryClick(props)}>{props.children}</a>
  );
};

const Story = ({ story, crops, className = '', showLabel, row, column, moduleTitle }) => {
  const { thumbnail, label } = story;
  const title = getStoryTitle(story);
  const includeLabel = showLabel !== undefined ? showLabel && label : false;
  const isLede = moduleTitle === LEDE_MODULE_TITLE;
  const TitleElement = isLede ? 'span' : Truncate;

  if (!thumbnail) {
    // TODO: handle missing images with some sort of placeholder/default image
    console.warn(`Missing thumbnail object for ${story.id}`);
    return null;
  }

  return (
    <InternalExternalLink className={`homepage-story ${className}`.trim()} {...{ story, row, column, moduleTitle, title }}>
      <ResponsiveImage imageUrl={story.thumbnail.url} crops={crops} title={`Story - ${title}`} />
      <div className="homepage-story-title">
        <TitleElement className={`homepage-story-title__title ${isLede ? 'lede-title' : ''}`} lines={2} ellipsis={'...'}>
          <div dangerouslySetInnerHTML={{ __html: title }} />
        </TitleElement>
        {includeLabel && <span className="homepage-story-label" dangerouslySetInnerHTML={{ __html: label }} />}
      </div>
    </InternalExternalLink>
  );
};

export default Story;
