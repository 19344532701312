import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import RemoveCollectionModal from 'components/elements/common/modal/collections/RemoveCollectionModal';
import ManageCollectionModal from 'components/elements/common/modal/collections/ManageCollectionModal';
import MultifileDownload from 'components/elements/common/modal/multifiledownload/MultifileDownload';
import PermissionButton from 'components/elements/common/permission/PermissionButton';
import ShareButton from 'elements/common/permission/ShareButton';
import { ASSET_TYPES, PERMISSIONS } from '../../../utils/constants';

import './CollectionDetailsOptions.scss';

class CollectionDetailsOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      removeCollectionOpen: false,
      manageCollectionOpen: false,
      downloadAllOpen: false,
    };
  }

  openRemoveCollectionModal() {
    this.setState({ removeCollectionOpen: true });
  }

  openManageCollectionModal() {
    this.setState({ manageCollectionOpen: true });
  }

  openDownloadAllModal() {
    this.setState({ downloadAllOpen: true });
  }

  closeModal() {
    this.setState({
      removeCollectionOpen: false,
      manageCollectionOpen: false,
      downloadAllOpen: false,
    });
  }

  showCollections() {
    this.props.history.push('/collections');
  }

  selectSaveButtonLabel(permission, userLevel) {
    let label = 'Save/Edit';
    if (permission === PERMISSIONS.view) {
      label = 'Save/As';
    } else if (userLevel === PERMISSIONS.admin) {
      label = 'Manage';
    }
    return label;
  }

  render() {
    const { removeCollectionOpen, manageCollectionOpen, downloadAllOpen } = this.state;
    const { collection, userLevel, userPermissions = {}, loading } = this.props;
    const { id, name, permission, assets = [] } = collection;
    const downloadPermissions = {
      image: userPermissions.photos,
      ...userPermissions,
    };
    const saveButtonLabel = this.selectSaveButtonLabel(permission, userLevel);

    return (
      <div className="collection-details-display">
        <PermissionButton permission="collections.create"
                          mediaType={ASSET_TYPES.collection.type}
                          className="button--manage-collection"
                          onClick={() => this.openManageCollectionModal()}>{saveButtonLabel}</PermissionButton>
        <ShareButton className="button--share-collection" mediaType={ASSET_TYPES.collection.type} id={id} name={name} />
        <button className="button--download-collection download-all-media" onClick={() => this.openDownloadAllModal()}>Download</button>
        {permission === PERMISSIONS.own
        && <button className="button--delete-collection" onClick={() => this.openRemoveCollectionModal()}>Delete</button>}
        <ManageCollectionModal open={manageCollectionOpen} collection={collection} handleClose={() => this.closeModal()}/>
        <RemoveCollectionModal open={removeCollectionOpen} collection={collection}
                               afterDelete={() => this.showCollections()} handleClose={() => this.closeModal()}/>
        <MultifileDownload open={downloadAllOpen} close={() => this.closeModal()} files={assets}
                           permissions={downloadPermissions} loading={loading}/>
      </div>
    );
  }
}

const mapStateToProps = ({ collections, collection, user }) => ({
  userLevel: user.user.level,
  userPermissions: user.user.permissions,
  assets: collections.workQueue,
  collection: collection.collection,
  loading: collection.loading,
});

export default withRouter(connect(mapStateToProps)(CollectionDetailsOptions));
