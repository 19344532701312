import React from 'react';
import { Link } from 'react-router-dom';
import mt from 'moment-timezone';
import { ASSET_TYPES } from 'utils/constants';
import { formatLink } from 'utils/helpers';

const displayType = (type, extension) => {
  if (extension) {
    return extension;
  }

  switch (type) {
    case ASSET_TYPES.image.type:
      return 'jpg';
    case ASSET_TYPES.news.type:
      return 'xml';
    case ASSET_TYPES.video.type:
      return 'mp4';
    default:
      return 'uncategorized';
  }
};

const formatDate = date => mt(date).format('M/D/YY');

const DownloadHistoryRow = ({ email, assetId, assetType, date, quotaName, duration, extension }) => (
  <div className="usage-container--data-row">
    <span className="usage--history__user">{email}</span>
    <span className="usage--history__quota__name">{quotaName}</span>
    <span className="usage--history__quota__duration">{duration}</span>
    <span className="usage--history__asset">
      <Link to={formatLink({ id: assetId, type: assetType })}>{assetId}</Link>
    </span>
    <span className="usage--history__type">{displayType(assetType, extension)}</span>
    <span className="usage--history__date">{formatDate(date)}</span>
  </div>
);

export default DownloadHistoryRow;
