import React from 'react';
import Accordion from 'elements/common/accordion/Accordion';

const FilterGroup = ({ name, open = true, children, className = '' }) => (
  <Accordion className={`filters--group ${className} ${open ? 'open' : 'closed'}`} initializeOpen={open} label={name} animate>
    {children}
  </Accordion>
);

export default FilterGroup;
