import React from 'react';
import Header from './elements/layout/header/Header';
import Footer from './elements/layout/footer/Footer';
import MainBody from './elements/layout/MainBody';

const MainLayout = () => (
  <React.Fragment>
    <Header />
    <MainBody />
    <Footer />
  </React.Fragment>
);

export default MainLayout;
