import React from 'react';
import ResponsiveImage from 'components/elements/common/ResponsiveImage/ResponsiveImage';
import AttachmentDownload from './AttachmentDownload';

const crops = {
  desktop: '148x101',
  tablet: '148x101',
  mobile: '148x101',
};

const ImageAttachment = ({ revision, attachment }) => (
  <div className="news--attachments__attachment image">
    <ResponsiveImage imageUrl={attachment.url} title={attachment.title} crops={crops}/>
    <AttachmentDownload revision={revision} attachment={attachment}/>
  </div>
);

export default ImageAttachment;
