import React from 'react';
import { Link } from 'react-router-dom';
import NavigationBar from 'elements/common/navigationbar/NavigationBar';

const SearchNav = () => (
  <NavigationBar
    className="search-nav"
     leftSide={(
       <React.Fragment>
         <Link to="/search" className="button--search">Back to Search</Link>
         <Link to="/collections" className="button--my-collections">Collections</Link>
       </React.Fragment>
     )}
     hideLayoutOptions
  />
);

export default SearchNav;
