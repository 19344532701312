import React from 'react';
import { connect } from 'react-redux';
import TextInput from 'components/elements/common/forms/TextInput';
import { setQuery, search } from 'store/actions/search';

const handleSearchChange = (props, evt) => {
  const newValue = evt.target.value;
  props.setQuery(newValue);
};

const handleFormSubmit = (props, evt) => {
  evt.preventDefault();
  props.search();
};

const onRemove = (props) => {
  props.setQuery('');
};

const KeywordFilter = props => (
  <form method="post" className="filters--keyword pill-filters" onSubmit={evt => handleFormSubmit(props, evt)}>
    <TextInput
      name="keyword-filter-input"
      className="filters--keyword__input"
      value={props.query}
      placeholder="Search Keywords"
      onChange={evt => handleSearchChange(props, evt)}
    />
    <button type="button" className={`filters--keyword__clear pill__remove ${props.query.length ? '' : 'hide'}`.trim()} onClick={() => onRemove(props)} aria-label="clear keyword filter" />
    <legend>Keywords</legend>
  </form>
);

const mapStateToProps = ({ search: searchStore }) => ({
  query: searchStore.query,
});

const mapDispatchToProps = {
  setQuery,
  search,
};

export default connect(mapStateToProps, mapDispatchToProps)(KeywordFilter);
