import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setSubMenuOpen, quickFindReset } from 'store/actions/quickFind';
import { formatLink } from 'utils/helpers';

const CatalogMenuItem = ({ item, subMenuOpen, ...props }) => {
  const open = subMenuOpen === item.title;
  return (
    <div className={`quickfind--option ${open ? 'selected' : ''}`.trim()}>
      <span onClick={() => props.setSubMenuOpen(open ? '' : item.title)}>
        { item.title }
      </span>
      {open
      && <div className="quickfind--sub-options">
        {item.items.map(({ url, title }) => <Link key={url} className="quickfind--option" to={formatLink({ url })} onClick={() => props.quickFindReset()}>
            {title}
          </Link>,
        )}
      </div>
      }
    </div>
  );
};

const mapStateToProps = ({ quickFind }) => ({
  subMenuOpen: quickFind.subMenuOpen,
});

const mapDispatchToProps = {
  setSubMenuOpen,
  quickFindReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogMenuItem);
