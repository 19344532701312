import React from 'react';
import { connect } from 'react-redux';
import QuickFindMenuItem from './QuickFindMenuItem';

import './QuickFind.scss';

const QuickFind = ({ menu }) => (
  <div className="quickfind">
    {menu.map(item => <QuickFindMenuItem key={item.title} item={item} />)}
  </div>
);

const mapStateToProps = ({ quickFind }) => ({
  menu: quickFind.menu,
});

export default connect(mapStateToProps)(QuickFind);
