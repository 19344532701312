import React from 'react';
import { connect } from 'react-redux';
import { ITEMS_LAYOUT_OPTIONS } from 'store/reducers/globalActions';
import { setLayout } from 'store/actions/globalActions';

import './LayoutOptions.scss';

const LayoutOptions = ({ layout, set }) => (
  <div className="layout-options">
    {Object.keys(ITEMS_LAYOUT_OPTIONS).map(key => (
      <button
        key={`layout_option_${key}`}
        className={`layout-options--option-${key} ${layout === key ? 'active' : ''}`.trim()}
        type="button"
        onClick={() => set(key)}>
        {ITEMS_LAYOUT_OPTIONS[key]}
      </button>
    ))}
  </div>
);

const mapStateToProps = ({ globalActions }) => ({
  layout: globalActions.layout,
});

const mapDispatchToProps = dispatch => ({
  set: layout => dispatch(setLayout(layout)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutOptions);
