import axios from 'axios';
import { getAxiosError } from 'components/utils/helpers';
import {
  SET_COLLECTIONS_LOADING,
  SET_COLLECTIONS_ERROR,
  SET_COLLECTIONS,
  ADD_COLLECTION,
  REMOVE_COLLECTION,
  UPDATE_COLLECTION,
  UPDATE_ASSET_DETAILS,
  ADD_ASSET_TO_QUEUE,
  REMOVE_ASSET_FROM_QUEUE,
  RESET_ASSET_QUEUE,
  FILTER_DISPLAY_COLLECTIONS,
  RESET_DISPLAY_COLLECTIONS,
  SET_COLLECTIONS_FILTER,
} from '../reducers/collections';
import {
  showAccessDenied,
  showSignIn,
} from './globalActions';
import { PERMISSIONS } from '../../components/utils/constants';

const START_FILTERING_LENGTH = 2;
const SIGNIN_TIMEOUT = process.env.SIGNIN_TIMEOUT || 10000;

export const setCollectionsLoading = (loading = true) => ({
  type: SET_COLLECTIONS_LOADING,
  data: { loading },
});

export const setCollectionsError = error => ({
  type: SET_COLLECTIONS_ERROR,
  data: { error },
});

export const handleCollectionError = (err) => {
  const { status, message } = getAxiosError(err);
  if (status === 403) {
    return showAccessDenied();
  } if (status === 401) {
    return showSignIn();
  }
  return setCollectionsError(message);
};

export const setCollections = collections => ({
  type: SET_COLLECTIONS,
  data: { collections },
});

export const addCollection = collection => ({
  type: ADD_COLLECTION,
  data: { collection },
});

export const deleteCollection = id => ({
  type: REMOVE_COLLECTION,
  data: { id },
});

export const addAssetToQueue = asset => ({
  type: ADD_ASSET_TO_QUEUE,
  data: { asset },
});

export const removeAssetFromQueue = id => ({
  type: REMOVE_ASSET_FROM_QUEUE,
  data: { id },
});

export const resetAssetQueue = () => ({
  type: RESET_ASSET_QUEUE,
});

export const updateAssetDetails = (id, count, thumb) => ({
  type: UPDATE_ASSET_DETAILS,
  data: { id, count, thumb },
});

export const filterDisplayCollections = filter => ({
  type: FILTER_DISPLAY_COLLECTIONS,
  data: { filter },
});

export const resetDisplayCollections = () => ({
  type: RESET_DISPLAY_COLLECTIONS,
});

export const clearCollectionsError = () => async (dispatch) => {
  dispatch(setCollectionsError(false));
};

export const updateCollection = collection => ({
  type: UPDATE_COLLECTION,
  data: { collection },
});

export const setFilter = filter => (dispatch) => {
  dispatch({
    type: SET_COLLECTIONS_FILTER,
    data: { filter },
  });
};

export const addNewCollection = name => async (dispatch) => {
  dispatch(setCollectionsLoading());

  try {
    const { data: { id } } = await axios.post('/api/collections', { name }, { timeout: SIGNIN_TIMEOUT });
    dispatch(addCollection({ id, name, permission: PERMISSIONS.own }));
    return id;
  } catch (err) {
    dispatch(handleCollectionError(err));
    return false;
  }
};

export const removeCollection = id => async (dispatch) => {
  dispatch(setCollectionsLoading());

  try {
    await axios.delete(`/api/collections/${id}`, { timeout: SIGNIN_TIMEOUT });
    dispatch(deleteCollection(id));
    dispatch(resetDisplayCollections());
    return true;
  } catch (err) {
    dispatch(handleCollectionError(err));
    return false;
  }
};

export const fetchCollections = () => async (dispatch, getState) => {
  const { collections: { collections, loading } } = getState();
  if (collections.length || loading) {
    return;
  }

  dispatch(setCollectionsLoading());
  try {
    const { data } = await axios.get('/api/collections', { timeout: SIGNIN_TIMEOUT });
    dispatch(setCollections(data));
  } catch (err) {
    const { message } = getAxiosError(err);
    dispatch(setCollectionsError(message));
  }
};

export const filterCollections = filter => (dispatch) => {
  if (filter.length < START_FILTERING_LENGTH) {
    dispatch(resetDisplayCollections());
  } else {
    dispatch(filterDisplayCollections(filter));
  }
};
