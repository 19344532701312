import React from 'react';
import { connect } from 'react-redux';
import { showBookmarksModal } from 'store/actions/globalActions';
import PermissionButton from 'elements/common/permission/PermissionButton';
// todo perms for feeds: news.enrolled. I think we can go with the 'savesearch' perm... the model for perms is fracturing and needs rethinking
const Bookmarks = props => (
  <PermissionButton permission="savesearch" className="bookmarks" onClick={props.showBookmarksModal} ariaLabel="show bookmarks" />
);

const mapDispatchToProps = {
  showBookmarksModal,
};

export default connect(null, mapDispatchToProps)(Bookmarks);
