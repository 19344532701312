import { filterRegex } from 'utils/helpers';

export const SET_COLLECTIONS_LOADING = 'redux/collections/SET_COLLECTIONS_LOADING';
export const SET_COLLECTIONS_ERROR = 'redux/collections/SET_COLLECTIONS_ERROR';
export const SET_COLLECTIONS = 'redux/collections/SET_COLLECTIONS';
export const ADD_COLLECTION = 'redux/collections/ADD_COLLECTION';
export const REMOVE_COLLECTION = 'redux/collections/REMOVE_COLLECTION';
export const UPDATE_COLLECTION = 'redux/collections/UPDATE_COLLECTION';
export const ADD_ASSET_TO_QUEUE = 'redux/collections/ADD_ASSET_TO_COLLECTION_QUEUE';
export const REMOVE_ASSET_FROM_QUEUE = 'redux/collections/REMOVE_ASSET_FROM_QUEUE';
export const FILTER_DISPLAY_COLLECTIONS = 'redux/collections/FILTER_DISPLAY_COLLECTIONS';
export const RESET_DISPLAY_COLLECTIONS = 'redux/collections/RESET_DISPLAY_COLLECTIONS';
export const RESET_ASSET_QUEUE = 'redux/collections/RESET_ASSET_QUEUE';
export const UPDATE_ASSET_DETAILS = 'redux/collections/UPDATE_ASSET_DETAILS';
export const SET_COLLECTIONS_FILTER = 'redux/collections/SET_COLLECTIONS_FILTER';

export const initialState = {
  loading: false,
  collections: [],
  displayCollections: [],
  workQueue: [],
  error: false,
  filter: '',
};

const collections = (state = initialState, action) => {
  switch (action.type) {
    case SET_COLLECTIONS_LOADING:
      return {
        ...state,
        error: false,
        loading: action.data.loading,
      };
    case SET_COLLECTIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data.error,
      };
    case SET_COLLECTIONS:
      return {
        ...state,
        error: false,
        loading: false,
        collections: action.data.collections,
        displayCollections: action.data.collections,
      };
    case ADD_COLLECTION:
      return {
        ...state,
        error: false,
        loading: false,
        collections: [...state.collections, action.data.collection],
        displayCollections: [...state.collections, action.data.collection],
      };
    case REMOVE_COLLECTION:
      return {
        ...state,
        error: false,
        loading: false,
        collections: state.collections.filter(({ id }) => id !== action.data.id),
      };
    case UPDATE_COLLECTION:
      return {
        ...state,
        collections: state.collections.map(c => (c.id === action.data.collection.id ? { ...c, ...action.data.collection } : c)),
        displayCollections: state.displayCollections.map(c => (c.id === action.data.collection.id ? { ...c, ...action.data.collection } : c)),
      };
    case ADD_ASSET_TO_QUEUE:
      return {
        ...state,
        workQueue: [...state.workQueue, action.data.asset],
      };
    case REMOVE_ASSET_FROM_QUEUE:
      return {
        ...state,
        workQueue: state.workQueue.filter(({ assetId }) => assetId !== action.data.id),
      };
    case RESET_ASSET_QUEUE:
      return {
        ...state,
        workQueue: [],
      };
    case FILTER_DISPLAY_COLLECTIONS:
      return {
        ...state,
        displayCollections: state.collections.filter(({ name }) => filterRegex(action.data.filter).test(name)),
      };
    case RESET_DISPLAY_COLLECTIONS:
      return {
        ...state,
        displayCollections: [...state.collections],
      };
    case UPDATE_ASSET_DETAILS:
      return {
        ...state,
        collections: state.collections.map(c => (c.id === action.data.id ? {
          ...c,
          count: (c.count || 0) + action.data.count,
          thumb: !c.count ? action.data.thumb : c.thumb,
        } : c)),
      };
    case SET_COLLECTIONS_FILTER:
      return {
        ...state,
        filter: action.data.filter,
      };
    default:
      return state;
  }
};

export default collections;
