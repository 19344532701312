import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import Autosuggest from 'msyn-autosuggest';
import uuid from 'uuid';
import Loading from 'components/elements/common/loading/Loading';
import { setSuggestions } from 'store/actions/autocomplete';

import { AUTOCOMPLETE_DEBOUNCE_DELAY, MIN_AUTOCOMPLETE_LEN } from '../../utils/constants';
import './Autocomplete.scss';

export const getSectionSuggestions = ({ context, displayName, items }) => items.map(item => ({ ...item, context, displayName }));
export const getSuggestionValue = suggestion => suggestion.label;

export const renderSectionTitle = section => (<strong>{section.displayName}</strong>);

export const renderSuggestion = ({ label, extra }) => {
  const suggestion = label + (extra ? ` - ${extra}` : '');
  return <span className="search--suggestion">{suggestion}</span>;
};
export class Autocomplete extends Component {
  constructor() {
    super();

    this.state = {
      query: '',
      loading: false,
    };
    this.debounceLoadSuggestions = debounce(this.loadSuggestions, AUTOCOMPLETE_DEBOUNCE_DELAY);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searching !== this.props.searching) {
      this.setState({ loading: false });
    }
  }

  onChange(event, { newValue }) {
    this.setState({
      query: newValue,
      loading: !!newValue.length,
    });
    this.props.onChange(event, newValue);
  }

  /* istanbul ignore next */ // no need to test lib
  loadSuggestions(query) {
    const { getSuggestions, autocompleteContexts } = this.props;
    getSuggestions(query, autocompleteContexts);
  }

  onSuggestionsFetchRequested({ value: query }) {
    this.debounceLoadSuggestions(query);
  }

  /* istanbul ignore next */ // no need to test lib
  onSuggestionsClearRequested() {
    this.setState({ loading: false });
    if (!this.state.query) {
      this.props.clearSuggestions();
    }
  }

  shouldRenderSuggestions(query) {
    return query && query.trim().length >= MIN_AUTOCOMPLETE_LEN;
  }

  onSuggestionSelected(event, { suggestion }) {
    event.preventDefault();

    const { context, id: value, label, extra } = suggestion;
    this.setState({
      query: `${context}: ${suggestion.label}`,
    });
    const displayValue = label + (extra ? ` - ${extra}` : '');
    this.props.onSelect({ context, value, displayValue, autocomplete: true });
  }

  render() {
    const { query, loading } = this.state;
    const { id = uuid.v4(), ariaLabel, suggestions, placeholder = '', value = false } = this.props;

    let inputValue = value;
    // value can be an empty string for searches w/o queries
    if (inputValue === false) {
      inputValue = query;
    }
    const inputProps = {
      placeholder,
      'aria-label': id,
      value: inputValue,
      onChange: (event, changes) => this.onChange(event, changes),
    };

    return (
      <div className="autocomplete">
        <Autosuggest
          id={id}
          ariaLabel={ariaLabel}
          suggestions={suggestions}
          onSuggestionsFetchRequested={suggestion => this.onSuggestionsFetchRequested(suggestion)}
          onSuggestionsClearRequested={() => this.onSuggestionsClearRequested()}
          shouldRenderSuggestions={suggestion => this.shouldRenderSuggestions(suggestion)}
          onSuggestionSelected={(event, changes) => this.onSuggestionSelected(event, changes)}
          getSectionSuggestions={section => getSectionSuggestions(section)}
          getSuggestionValue={suggestion => getSuggestionValue(suggestion)}
          renderSuggestion={suggestion => renderSuggestion(suggestion)}
          renderSectionTitle={section => renderSectionTitle(section)}
          inputProps={inputProps}
          multiSection={true}
        />
        {loading && <Loading height={18} width={50}/>}
      </div>
    );
  }
}

const mapStateToProps = ({ autocomplete }) => ({
  suggestions: autocomplete.suggestions,
  searching: autocomplete.loading,
});

/* istanbul ignore next */ // no need to test lib
const mapDispatchToProps = dispatch => ({
  clearSuggestions: () => dispatch(setSuggestions([])),
});

export default connect(mapStateToProps, mapDispatchToProps)(Autocomplete);
