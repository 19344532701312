import axios, { CancelToken, isCancel } from 'axios';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { SEARCH_RESULT_LIMIT, SEARCH_CONTEXTS } from 'components/utils/constants';
import { getAxiosError, formatFilters, denormalizeContextFilters } from 'components/utils/helpers';
import { trackSearchView, trackCustomEvent } from 'components/utils/analytics';
import { NOT_ACCEPTABLE } from 'http-status-codes';
import { showSignIn } from 'store/actions/globalActions';
import {
  SET_CANCEL_SEARCH_SOURCE,
  SET_FILTERS_ARE_OPEN,
  SET_SEARCH_QUERY,
  SET_SEARCH_LOADING,
  SET_SEARCH_ERROR,
  ADD_SEARCH_RESULTS,
  SET_USER_SEARCH_FILTERS,
  REMOVE_SEARCH_FILTER,
  UPDATE_SEARCH_FILTERS,
  SET_FILTER_GROUP,
  RESET_FILTER_GROUP,
  UPDATE_GLOBAL_FILTERS,
  RESET_SEARCH_RESULTS,
  RESET_FILTERS,
  INCREMENT_PAGE,
  SET_SEARCH_ID,
  SET_FEED_ID,
  SET_SEARCH_FILTERS,
  RESET_AUTO_REFRESH_DELTA,
} from '../reducers/search';
/* eslint-disable import/no-cycle */
import { resetUser, addFiltersToStorage } from './user';
import { PHOTO_FIXED_RESULT_LIMIT } from '../../components/utils/constants';

const TIMEOUT = process.env.SIGNIN_TIMEOUT || 10000;

export const setAndGetCancelSearchSource = source => (dispatch) => {
  const cancelSearchSource = source === undefined ? CancelToken.source() : source;
  dispatch({
    type: SET_CANCEL_SEARCH_SOURCE,
    data: { cancelSearchSource },
  });
  return cancelSearchSource;
};

export const setQuery = query => (dispatch) => {
  dispatch({
    type: SET_SEARCH_QUERY,
    data: { query },
  });
};

export const resetResults = () => (dispatch) => {
  dispatch({
    type: RESET_SEARCH_RESULTS,
  });
};

export const resetFilters = () => (dispatch) => {
  dispatch({
    type: RESET_FILTERS,
  });
};

export const setUserFilters = ({ feeds, languages, brands }) => ({
  type: SET_USER_SEARCH_FILTERS,
  data: {
    filters: { feeds, languages, brands },
  },
});

export const removeFilter =  (filterKey, name) => (dispatch) => {
  dispatch({
    type: REMOVE_SEARCH_FILTER,
    data: { filterKey, name },
  });
};

export const updateFilter =  (filterKey, name, value) => (dispatch) => {
  dispatch({
    type: UPDATE_SEARCH_FILTERS,
    data: { filterKey, name, value },
  });
};

export const setFilterGroup =  (filterKey, value) => (dispatch) => {
  dispatch({
    type: SET_FILTER_GROUP,
    data: { filterKey, value },
  });
};

// note that this resets several filter groups
export const resetFilterGroups =  filterKeys => (dispatch) => {
  filterKeys.forEach((filterKey) => {
    dispatch({
      type: RESET_FILTER_GROUP,
      data: { filterKey },
    });
  });
};

export const setSearchId =  searchId => (dispatch) => {
  dispatch({
    type: SET_SEARCH_ID,
    data: { searchId },
  });
};

export const setFeedId = feedId => (dispatch) => {
  dispatch({
    type: SET_FEED_ID,
    data: { feedId },
  });
};

export const updateGlobalFilter = (name, value) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_GLOBAL_FILTERS,
    data: {
      globalFilter: {
        ...getState().search.globalFilter,
        [name]: value,
      },
    },
  });
};

export const toggleFilters = () => (dispatch, getState) => {
  dispatch({
    type: SET_FILTERS_ARE_OPEN,
    data: { filtersAreOpen: !getState().search.filtersAreOpen },
  });
};

export const closeFilters = () => (dispatch) => {
  dispatch({
    type: SET_FILTERS_ARE_OPEN,
    data: { filtersAreOpen: false },
  });
};

export const setFilters = filters => ({
  type: SET_SEARCH_FILTERS,
  data: { filters },
});

export const setSearchLoading = (loading = true) => ({
  type: SET_SEARCH_LOADING,
  data: { loading },
});

export const setSearchError = error => ({
  type: SET_SEARCH_ERROR,
  data: { error },
});

export const setSearchResults = ({ results, totals, prepend, autoRefreshDelta, extra }) => ({
  type: ADD_SEARCH_RESULTS,
  data: { results, totals, prepend, autoRefreshDelta, hasMore: results.length >= SEARCH_RESULT_LIMIT, extra },
});

export const resetAutoRefreshDelta = () => ({
  type: RESET_AUTO_REFRESH_DELTA,
});

export const incrementPage = () => ({
  type: INCREMENT_PAGE,
});

const updateNewsFilters = (filters, feeds, languages) => async (dispatch) => {
  Object.keys(filters.feeds).forEach((key) => {
    dispatch(updateFilter('feeds', key, feeds.includes(key)));
  });

  Object.keys(filters.languages).forEach((key) => {
    dispatch(updateFilter('languages', key, languages.includes(key)));
  });
};

const defaultSearchNewsFilters = filters => async (dispatch) => {
  const { feeds: userFeeds, languages: userLanguages, media: { text: newsMedia } } = filters;
  if (newsMedia) {
    let feeds = Object.keys(userFeeds).map(id => ({ id, selected: userFeeds[id] })).filter(({ selected }) => selected).map(({ id }) => id);
    let languages = Object.keys(userLanguages).map(id => ({ id, selected: userLanguages[id] })).filter(({ selected }) => selected).map(({ id }) => id);

    if (!feeds.length) {
      feeds = Object.keys(userFeeds);
    }
    if (!languages.length) {
      languages = Object.keys(userLanguages);
    }
    dispatch(updateNewsFilters(filters, feeds, languages));
  }
};

export const defaultPhotoFilters = () => async (dispatch, getState) => {
  const { filters: { photo: { landscape, portrait, creative, editorial } } } = getState().search;
  const filterUpdate = { landscape, portrait, creative, editorial };

  if (!(landscape || portrait)) {
    filterUpdate.landscape = true;
    filterUpdate.portrait = true;
  }

  if (!(creative || editorial)) {
    filterUpdate.creative = true;
    filterUpdate.editorial = true;
  }
  dispatch(setFilterGroup('photo', filterUpdate));
};
// I think the 2nd part of this check is not relevant: no queries start with that prefix based on search this app
const photoFixedResultSearch = query => query.startsWith('Related:') || query.startsWith('image_reference_id_ld:');

const searchForFeedArticles = async (feedId, specs, getState) => {
  const { globalFilter } = getState().search;
  const { searchDelta = false, publishedDate, dateRange } = specs;

  const { data } = await axios.post(`/api/feeds/${feedId}/news`,
    {
      filters: {
        global: {
          ...globalFilter,
          publishedDate,
        },
        dateRange,
      },
      limit: SEARCH_RESULT_LIMIT,
      extra: { searchDelta },
    });
  return data;
};

const advancedSearch = async (specs, getState) => {
  const { searchDelta = false, paginate = false, publishedDate, dateRange, cancelSearchSource } = specs;
  const { query, globalFilter, totals, extra = {} } = getState().search;
  const newsExtra = paginate ? { total: totals.news, searchTimeFrame: (extra.news || /* istanbul ignore */{}).searchTimeFrame } : {};
  let imageExtra = {};

  let limit = SEARCH_RESULT_LIMIT;

  if (photoFixedResultSearch(query)) {
    limit = PHOTO_FIXED_RESULT_LIMIT;
    imageExtra = { fixedResult: true };
  }

  const { data } = await axios.post('/api/search',
    {
      query: query.trim(),
      filters: {
        global: {
          ...globalFilter,
          publishedDate,
        },
        ...formatFilters({ ...getState().search.filters }, getState().user.user.feeds), // defaulting news filters may update them so we get the filters again from the store
        dateRange,
      },
      limit,
      extra: { searchDelta, news: newsExtra, image: imageExtra, video: {} },
    }, {
      cancelToken: cancelSearchSource.token,
    });
  return data;
};

export const performSearch = (specs = {}) => async (dispatch, getState) => {
  const { searchDelta = false } = specs;
  dispatch(setSearchLoading());
  try {
    const { query, totals, filters, page, globalFilter, results } = getState().search;
    const cancelSearchSource = await dispatch(setAndGetCancelSearchSource());
    const publishedDate =  searchDelta ? moment().tz('UTC') : globalFilter.publishedDate;
    const dateRange = searchDelta ? { after: results.length ? moment(results[0].publishedDate).add(1, 's') : /* istanbul ignore next */null } : filters.dateRange;

    const searchSpecs = { ...specs, publishedDate, dateRange, cancelSearchSource };

    const { feedId } = getState().search;
    const data = feedId ? await searchForFeedArticles(feedId, searchSpecs, getState) : await advancedSearch(searchSpecs, getState);
    dispatch(setAndGetCancelSearchSource(null));

    let newTotals;
    let autoRefreshDelta = 0;
    if (searchDelta) {
      const { news: currentNews = 0, photos: currentPhotos = 0, videos: currentVideos = 0 } = { ...totals };
      const { totals: { news = 0, photos = 0, videos = 0 } } = data;
      newTotals = { news: currentNews + news, photos: currentPhotos + photos, videos: currentVideos + videos };
      autoRefreshDelta = news + photos + videos;
    } else {
      newTotals = Object.keys(totals).length ? { ...totals } : data.totals;
      dispatch(incrementPage());
      const scrollPublishedDate = data.results.length && moment(data.results[data.results.length - 1].publishedDate).subtract(1, 'ms') || null;
      if (scrollPublishedDate) {
        dispatch(updateGlobalFilter('publishedDate', scrollPublishedDate));
      }
    }

    // not tracking autorefresh or subsequent paginated searches
    if (!searchDelta && page < 1) {
      trackSearchView({
        query,
        feedId,
        filters,
        user: getState().user.user,
        layout: getState().globalActions.layout,
        totals: data.totals,
      });
    }
    if (searchDelta && autoRefreshDelta === 0) {
      dispatch(setSearchLoading(false));
    } else {
      dispatch(setSearchResults({
        prepend: searchDelta,
        ...data,
        totals: { ...newTotals },
        autoRefreshDelta,
      }));
    }
  } catch (err) {
    if (isCancel(err)) {
      dispatch(setSearchLoading(false));
    } else {
      const { message, status } = getAxiosError(err);
      if (status === NOT_ACCEPTABLE) {
        dispatch(setSearchLoading(false));
        dispatch(resetUser());

        const cookies = new Cookies();
        cookies.remove('login');
        dispatch(showSignIn(true));
      } else {
        console.error(err); // TODO used for debugging
        dispatch(setSearchError(message));
      }
    }
  }
};

export const paginate = () => async (dispatch, getState) => {
  if (getState().search.loading) {
    return;
  }
  dispatch(performSearch({ paginate: true }));
};

export const updateSearchResults = () => async (dispatch, getState) => {
  if (getState().search.loading) {
    return;
  }

  trackCustomEvent({
    category: 'search',
    action: 'autorefresh',
  });

  dispatch(performSearch({ searchDelta: true }));
};

export const search = (specs = {}) => async (dispatch, getState) => {
  if (getState().search.loading) {
    getState().search.cancelSearchSource.cancel();
  }

  dispatch(resetResults());
  dispatch(updateGlobalFilter('publishedDate', moment().tz('UTC')));

  const { filters } = getState().search;

  dispatch(defaultSearchNewsFilters(filters));
  dispatch(defaultPhotoFilters());
  addFiltersToStorage({ ...filters });

  if (!specs.bookmarks) {
    dispatch(setSearchId(null));
    dispatch(setFeedId(null));
  }
  dispatch(performSearch());
};

const defaultSavedSearchNewsFilters = ({ userFeeds, savedFeeds, userLanguages, savedLanguages, newsMedia }) => {
  let feeds = savedFeeds;
  let languages = savedLanguages;

  if (newsMedia) {
    if (!feeds.length) {
      feeds = [...Object.keys(userFeeds)];
    }

    if (!languages.length) {
      languages = [...Object.keys(userLanguages)];
    }
  }
  return { feeds, languages };
};

export const populateSavedSearch = ({ queryParams: { query, filters: savedFilters } }) => async (dispatch, getState) => {
  dispatch(resetFilters());
  dispatch(setQuery(query));
  const { filters } = getState().search;
  const { languages: savedLanguages, feeds: savedFeeds, contexts = {}, ...basicFilters } = savedFilters;

  Object.keys(basicFilters).forEach((filterKey) => {
    const values = basicFilters[filterKey];
    Object.keys(values).forEach(key => dispatch(updateFilter(filterKey, key, values[key])));
  });

  const { feeds, languages } = defaultSavedSearchNewsFilters({
    userFeeds: filters.feeds,
    savedFeeds,
    userLanguages: filters.languages,
    savedLanguages,
    newsMedia: savedFilters.media.text,
  });
  dispatch(updateNewsFilters(filters, feeds, languages));

  const { topics, companies, people, series, brands } = denormalizeContextFilters(contexts);
  dispatch(setFilterGroup(SEARCH_CONTEXTS.topics.name, topics));
  dispatch(setFilterGroup(SEARCH_CONTEXTS.companies.name, companies));
  dispatch(setFilterGroup(SEARCH_CONTEXTS.people.name, people));
  dispatch(setFilterGroup(SEARCH_CONTEXTS.series.name, series));
  dispatch(setFilterGroup(SEARCH_CONTEXTS.brands.name, brands));
};

export const executeSavedSearch = id => async (dispatch) => {
  try {
    const { data: savedSearchData } = await axios.get(`/api/searches/${id}`, { timeout: TIMEOUT });

    dispatch(setSearchId(id));
    dispatch(setFeedId(null));
    dispatch(populateSavedSearch(savedSearchData));

    trackCustomEvent({
      category: 'savedsearch',
      action: 'execute',
      label: savedSearchData.name,
    }, {
      id,
    });

    dispatch(search({ bookmarks: true }));
  } catch (err) {
    const { message } = getAxiosError(err);
    dispatch(setSearchError(message));
  }
};

export const fetchFeedArticles = feedId => async (dispatch) => {
  dispatch(setFeedId(feedId));
  dispatch(setSearchId(null));
  dispatch(search({ bookmarks: true }));
};
