import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextInput from 'components/elements/common/forms/TextInput';
import { PERMISSIONS } from 'components/utils/constants';
import { updateCollection } from 'store/actions/collection';
import Loading from 'components/elements/common/loading/Loading';

class CollectionEditableName extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      newName: props.collection.name,
    };
  }

  openForm() {
    this.setState({ open: true });
  }

  closeForm() {
    this.setState({ open: false });
  }

  onChange({ target: { value } }) {
    this.setState({ newName: value });
  }

  async saveName(evt) {
    evt.preventDefault();
    const { newName } = this.state;
    const { collection: { name, id } } = this.props;

    if (newName.trim() === name) {
      this.closeForm();
      return;
    }

    if (newName.trim().length && await this.props.updateCollection(id, { name: newName.trim() })) {
      this.closeForm();
    }
  }

  render() {
    const { loading, collection: { name, permission } } = this.props;
    const { open, newName } = this.state;

    return (
      <div className="collection--name">
        {open
        && <form method="post" onSubmit={evt => this.saveName(evt)}>
          <button type="submit" className="collection--name__submit" onClick={evt => this.saveName(evt)} disabled={loading} aria-label="collection name save" />
          <TextInput name="edit-collection-name-input" value={newName} onChange={evt => this.onChange(evt)} onBlur={() => this.closeForm()}/>
          {loading && <Loading />}
        </form>
        || <h2>
          {permission === PERMISSIONS.own && <button type="button" className="collection--name__edit" onClick={() => this.openForm()} aria-label="collection name edit" />}
          {name}
        </h2>
        }
      </div>
    );
  }
}

const mapStateToProps = ({ collection }) => ({
  collection: collection.collection,
  loading: collection.loading,
});

const mapDispatchToProps = {
  updateCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionEditableName);
