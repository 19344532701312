import axios from 'axios';
import { getAxiosError, formatFilters } from 'components/utils/helpers';
import {
  SET_SAVEDSEARCH_ERROR,
  SET_SAVEDSEARCH_LOADING,
  ADD_SAVEDSEARCH,
  REMOVE_SAVEDSEARCH,
  SET_SAVEDSEARCHES,
  FILTER_DISPLAY_SEARCHES,
  RESET_DISPLAY_SEARCHES,
} from '../reducers/savedsearch';
import { MIN_BOOKMARKS_AUTOCOMPLETE_LEN } from '../../components/utils/constants';

const TIMEOUT = process.env.SIGNIN_TIMEOUT || 10000;

export const setSavedSearchLoading = (loading = true) => ({
  type: SET_SAVEDSEARCH_LOADING,
  data: { loading },
});

export const setSavedSearchError = error => ({
  type: SET_SAVEDSEARCH_ERROR,
  data: { error },
});

export const clearSavedSearchError = () => async (dispatch) => {
  dispatch(setSavedSearchError(false));
};

export const addSavedSearch = savedSearch => ({
  type: ADD_SAVEDSEARCH,
  data: { savedSearch },
});

export const removeSavedSearch = id => ({
  type: REMOVE_SAVEDSEARCH,
  data: { id },
});

export const setSavedSearches = savedSearches => ({
  type: SET_SAVEDSEARCHES,
  data: { savedSearches },
});

export const fetchSavedSearches = () => async (dispatch, getState) => {
  const { savedsearch: { savedSearches, loading } } = getState();
  if (savedSearches.length || loading) {
    return;
  }
  dispatch(setSavedSearchLoading());
  try {
    const { data: searches } = await axios.get('/api/searches', { timeout: TIMEOUT });
    dispatch(setSavedSearches(searches));
  } catch (err) {
    const { message } = getAxiosError(err);
    dispatch(setSavedSearchError(message));
  }
};

export const addNewSavedSearch = name => async (dispatch, getState) => {
  try {
    const { query, filters } = getState().search;
    const savedFilters = { ...formatFilters(filters, getState().user.user.feeds) };
    // we want to preserve the format of the saved feeds for backward compatibility
    savedFilters.feeds = savedFilters.feeds.map(({ id }) => id);
    const queryParams = {
      query,
      filters: savedFilters,
    };

    const { savedsearch: { savedSearches } } = getState();
    // we can be saving a new search before the searches are fetched
    if (!savedSearches.length) {
      dispatch(fetchSavedSearches());
    }
    dispatch(setSavedSearchLoading());
    const { data: { id } } = await axios.post('/api/searches', { name, queryParams }, { timeout: TIMEOUT });
    dispatch(addSavedSearch({ id, name, queryParams }));
    return id;
  } catch (err) {
    const { message } = getAxiosError(err);
    dispatch(setSavedSearchError(message));
    return false;
  }
};

export const filterDisplaySearches = filter => ({
  type: FILTER_DISPLAY_SEARCHES,
  data: { filter },
});

export const resetDisplaySearches = () => ({
  type: RESET_DISPLAY_SEARCHES,
});

export const filterSavedSearches = filter => (dispatch) => {
  if (filter.length < MIN_BOOKMARKS_AUTOCOMPLETE_LEN) {
    dispatch(resetDisplaySearches());
  } else {
    dispatch(filterDisplaySearches(filter));
  }
};

export const deleteSavedSearch = id => async (dispatch) => {
  dispatch(setSavedSearchLoading());
  try {
    await axios.delete(`/api/searches/${id}`, { timeout: TIMEOUT });
    dispatch(removeSavedSearch(id));
    return true;
  } catch (err) {
    const { message } = getAxiosError(err);
    dispatch(setSavedSearchError(message));
    return false;
  }
};
