import React, { Component } from 'react';
import axios from 'axios';
import ErrorView from 'elements/common/ErrorView';
import EmailInput from 'components/elements/common/forms/EmailInput';
import { validateForm, getAxiosError } from 'components/utils/helpers';
import Page from '../Page';

import './ForgotPassword.scss';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      submitted: false,
      apiError: false,
      form: {
        email: '',
      },
    };
  }

  onChange({ target: { value } }) {
    this.setState({ form: { email: value.trim() } });
  }

  async submitForm() {
    try {
      this.setState({ submitting: true });
      await axios.post('/api/forgot-password', { ...this.state.form });
      this.setState({ submitted: true });
    } catch (err) {
      const { message } = getAxiosError(err);
      this.setState({ apiError: message });
    }
    this.setState({ submitting: false });
  }

  onSubmit(evt) {
    evt.preventDefault();
    this.setState({ apiError: false });

    if (validateForm(Object.keys(this.state.form))) {
      this.submitForm();
    }
  }

  renderSuccess() {
    const { email } = this.state.form;
    return (
      <div className="forgot-password-success">
        <p>An email has been sent to <u>{email}</u>. Please follow the instructions in the email to reset your password</p>
      </div>
    );
  }

  renderForm() {
    const { submitting } = this.state;
    return (
      <form method="post" onSubmit={evt => this.onSubmit(evt) } className="forgot-password-form">
        <EmailInput label="Email" name="email" placeholder="name@example.com" onChange={evt => this.onChange(evt)} required={true} />
        <button className="button-submit" type="submit" onClick={evt => this.onSubmit(evt)} disabled={submitting}>Submit</button>
      </form>
    );
  }

  render() {
    const { apiError, submitted } = this.state;

    return (
      <Page className="forgot-password" title="forgotpassword" pageType="forgotpassword">
        <h1>Request Password Reset</h1>
        {submitted && this.renderSuccess() || this.renderForm()}
        {apiError && <ErrorView className="forgot-password-error" error={apiError}/>}
      </Page>
    );
  }
}

export default ForgotPassword;
