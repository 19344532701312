import React from 'react';
import AddToCollectionButton from 'components/elements/common/permission/AddToCollectionButton';
import Download from 'components/elements/common/Story/Download';
import ShareButton from 'elements/common/permission/ShareButton';

const SearchActions = ({ className, id, title, type, thumb, watermark, showFooterOptions }) => (
  <React.Fragment>
    <ShareButton className="button button--share" mediaType={type} id={id} name={title}/>
    <AddToCollectionButton className="button button--add" mediaType={type} id={id} thumb={thumb}
                           watermark={watermark} multiple/>
    <Download className={`button ${className}-download`} id={id} type={type} thumb={thumb} watermark={watermark}
              parentIsOpen={showFooterOptions}/>
  </React.Fragment>
);

export default SearchActions;
