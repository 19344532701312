import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hasPermission } from 'store/actions/user';
import {
  showSignIn as globalActionsShowSignIn,
  showAccessDenied as globalActionsShowAccessDenied,
} from 'store/actions/globalActions';

const noop = () => {};

export class PermissionLink extends Component {
  onClick(evt) {
    const { email, permission, onClick = noop, showSignIn, showAccessDenied } = this.props;
    if (!email) {
      evt.stopPropagation();
      evt.preventDefault();
      showSignIn();
      return;
    }
    if (!this.props.hasPermission(permission)) {
      evt.stopPropagation();
      evt.preventDefault();
      showAccessDenied();
      return;
    }
    onClick();
  }

  render() {
    const { children, className, disabled, linkOptions = {}, ariaLabel = '' } = this.props;
    return (
      <a {...linkOptions} className={className} aria-label={ariaLabel} disabled={disabled} onClick={evt => this.onClick(evt)}>{children}</a>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  email: user.user.email,
});

const mapDispatchToProps = {
  showSignIn: globalActionsShowSignIn,
  showAccessDenied: globalActionsShowAccessDenied,
  hasPermission,
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionLink);
