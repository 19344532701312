import React from 'react';
import { connect } from 'react-redux';
import { updateFilter, setFilterGroup } from 'store/actions/search';
import { defaultVideoFilters } from 'store/actions/videos';
import Checkbox from 'common/forms/checkbox/Checkbox';
import { formatNumber } from 'components/utils/helpers';
import { allowFilterAction } from '../../../../utils/helpers';
import { ASSET_TYPES } from '../../../../utils/constants';

const triggerFilterUpdate = (props, mediaType, checked) => {
  const { filters: { media: { text, photo, video } } } = props;
  if (allowFilterAction({ text: { value: text }, photo: { value: photo }, video: { value: video } }, checked)) {
    props.updateFilter('media', mediaType, checked);

    if (mediaType === ASSET_TYPES.video.media) {
      props.defaultVideoFilters();
    }
  }
};

const MediaFilters = props => (
    <div className="filters--section media-filters">
      <legend>Media</legend>
      <div className="filters--checkboxes">
        <Checkbox
          checked={props.filters.media.text}
          onChange={({ target: { checked } }) => triggerFilterUpdate(props, ASSET_TYPES.news.media, checked)}
        >
          News {props.filters.media.text && <span>({formatNumber(props.totals.news)})</span>}
        </Checkbox>
        <Checkbox
          checked={props.filters.media.photo}
          onChange={({ target: { checked } }) => triggerFilterUpdate(props, ASSET_TYPES.image.media, checked)}
        >
          Photos {props.filters.media.photo && <span>({formatNumber(props.totals.photos)})</span>}
        </Checkbox>
        <Checkbox
            checked={props.filters.media.video}
            onChange={({ target: { checked } }) => triggerFilterUpdate(props, ASSET_TYPES.video.media, checked)}
          >
            Video {props.filters.media.video && <span>({formatNumber(props.totals.videos)})</span>}
          </Checkbox>
      </div>
    </div>
);

const mapStateToProps = ({ search: searchStore }) => ({
  filters: searchStore.filters,
  totals: searchStore.totals,
});

const mapDispatchToProps = {
  updateFilter,
  setFilterGroup,
  defaultVideoFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaFilters);
