import React from 'react';
import { connect } from 'react-redux';
import PillFilter from '../PillFilter';

const CompaniesFilters = ({ companies }) => (
  <PillFilter className="companies-filters" name="Companies" placeholder="Search Companies" items={companies} filterKey="companies" />
);

const mapStateToProps = ({ search: searchStore }) => ({
  companies: Object.values(searchStore.filters.companies || {}),
});

export default connect(mapStateToProps)(CompaniesFilters);
