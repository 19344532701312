import React, { Component } from 'react';
import TextInput from 'components/elements/common/forms/TextInput';
import Loading from 'components/elements/common/loading/Loading';
import ErrorView from 'elements/common/ErrorView';
import { validateForm, noop } from 'components/utils/helpers';
import Modal from '../Modal';

import './NewItemModal.scss';

export class NewItemModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      name: '',
      status: '',
    };
  }

  onChange(evt) {
    this.setState({ name: evt.target.value });
  }

  async onSubmit(evt) {
    evt.preventDefault();
    if (validateForm(['name'], this.props.className)) {
      let { name } = this.state;
      name = name.trim();

      const { createItem, timeout = 0 } = this.props;
      const id = await createItem(name);

      if (id) {
        this.setState({ id, status: 's a v e d' });
        /* istanbul ignore if */
        if (timeout > 0) {
          setTimeout(() => this.afterSubmit(id, name), timeout);
        } else {
          this.afterSubmit(id, name);
        }
      }
    }
  }

  afterSubmit(id, name) {
    const { afterSave = noop } = this.props;
    this.setState({ id: '', name: '', status: '' });
    afterSave({ id, name });
  }

  cancel() {
    /* istanbul ignore next */ // assignment to afterSave = noop is tested for submit above - no need to repeat
    const { clearError, handleClose, afterSave = noop } = this.props;

    // post-submit housekeeping was interrupted by user hitting Cancel
    const { id, name } = this.state;
    if (id) {
      afterSave(id, name);
    }

    clearError();
    this.setState({ id: '', name: '', status: '' });
    handleClose();
  }

  render() {
    const { label, open, loading, error, className } = this.props;
    const { name, status } = this.state;

    return (
      <Modal className={`item-new ${className}`.trim()} theme="dark" open={open} handleClose={evt => this.cancel(evt)}>
        <h4>{label}</h4>
        {status && <h5>{status}</h5>}
        <form method="post" onSubmit={evt => this.onSubmit(evt) }>
          <TextInput label="Name" name="name" value={name} onChange={evt => this.onChange(evt)} required />
          <div className="item-new-actions">
            <button className="item-new-actions-cancel button-cancel" type="button" onClick={() => this.cancel()} disabled={loading}>Cancel</button>
            <button className="item-new-actions-save button-contact" type="submit" onClick={evt => this.onSubmit(evt)} disabled={loading}>
              Save {loading && <Loading height={8} width={24}/>}
            </button>
            {error && <ErrorView className="item-new-error" error={error} />}
          </div>
        </form>
      </Modal>
    );
  }
}

export default NewItemModal;
