import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addAssetToQueue, removeAssetFromQueue } from 'store/actions/collections';

class RemoveFromCollectionButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      added: false,
    };
  }

  add() {
    const { id, mediaType } = this.props;
    this.props.addAssetToQueue({ assetId: id, assetType: mediaType });
    this.setState({ added: true });
  }

  remove() {
    const { id } = this.props;
    this.props.removeAssetFromQueue(id);
    this.setState({ added: false });
  }

  render() {
    const { className: parentClassName } = this.props;
    const { added } = this.state;
    const className = `${parentClassName}${added && '-added' || ''}`;
    return (
      <React.Fragment>
        {added
        && <button className={`${className} asset-added`} onClick={() => this.remove()}><span>Remove</span></button>
        || <button className={`${className} asset-not-added`} onClick={() => this.add()}>Remove</button>
        }
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  addAssetToQueue,
  removeAssetFromQueue,
};

export default connect(null, mapDispatchToProps)(RemoveFromCollectionButton);
