export const SET_AUTOCOMPLETE = 'redux/search/SET_AUTOCOMPLETE';
export const SET_AUTOCOMPLETE_LOADING = 'redux/search/SET_AUTOCOMPLETE_LOADING';

export const initialState = {
  suggestions: [],
  loading: false,
};

const autocomplete = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTOCOMPLETE:
      return {
        ...state,
        suggestions: action.data.suggestions,
      };
    case SET_AUTOCOMPLETE_LOADING:
      return {
        ...state,
        loading: action.data.loading,
      };
    default:
      return state;
  }
};

export default autocomplete;
