export const SET_HOMEPAGE_LOADING = 'redux/homepage/SET_HOMEPAGE_LOADING';
export const SET_HOMEPAGE_ERROR = 'redux/homepage/SET_HOMEPAGE_ERROR';
export const SET_HOMEPAGE = 'redux/homepage/SET_HOMEPAGE';

export const initialState = {
  loading: false,
  error: false,
  layout: [],
};

const homepage = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOMEPAGE_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case SET_HOMEPAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data.error,
      };
    case SET_HOMEPAGE:
      return {
        ...initialState,
        layout: action.data.layout,
      };
    default:
      return state;
  }
};

export default homepage;
