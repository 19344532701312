import Contributors from './Contributors/Contributors';
import FeaturedModule from './FeaturedModule/FeaturedModule';
import Lede from './Lede/Lede';
import Spotlight from './Spotlight/Spotlight';

export default {
  contributors: Contributors,
  featuredmodule: FeaturedModule,
  lede: Lede,
  spotlight: Spotlight,
  default: Spotlight,
};
