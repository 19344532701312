import axios from 'axios';
import {
  SET_ANNOUNCEMENT,
  FETCH_ANNOUNCEMENT_ERROR,
} from '../reducers/announcement';

export const setAnnouncement = (announcement) => ({
  type: SET_ANNOUNCEMENT,
  data: { announcement },
});

export const fetchAnnouncementError = (error) => ({
  type: FETCH_ANNOUNCEMENT_ERROR,
  data: { error },
});

export const fetchAnnouncement = () => async (dispatch) => {
  try {
    const { data } = await axios.get('/api/announcements');
    dispatch(setAnnouncement(data));
  } catch (err) {
    dispatch(fetchAnnouncementError(err.message));
  }
};
