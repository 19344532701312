import axios from 'axios';
import saveAs from 'file-saver';
import { getAxiosError } from 'components/utils/helpers';
import { trackCustomEvent } from 'components/utils/analytics';
import { ASSET_TYPES } from 'components/utils/constants';
import { updateForm as updateContactUsForm } from './contactUs';
import { showQuotaExceededModal } from './globalActions';
import {
  SET_PHOTOS_LOADING,
  SET_PHOTOS_ERROR,
  SET_PHOTO,
  ADD_PHOTO,
  SET_PHOTO_DOWNLOADING,
} from '../reducers/photos';

const PHOTO_TIMEOUT = process.env.PHOTO_TIMEOUT || 10000;

export const setPhotosLoading = (loading = true) => ({
  type: SET_PHOTOS_LOADING,
  data: { loading },
});

export const setPhotosDownloading = (downloading = true) => ({
  type: SET_PHOTO_DOWNLOADING,
  data: { downloading },
});

export const setPhotosError = error => ({
  type: SET_PHOTOS_ERROR,
  data: { error },
});

export const setPhoto = photo => ({
  type: SET_PHOTO,
  data: {
    photo,
  },
});

export const addPhoto = photo => ({
  type: ADD_PHOTO,
  data: {
    photo,
  },
});

const handleError = (dispatch, err) => {
  const { status, message } = getAxiosError(err);
  const errorMsg = status === 404 ? status : message;
  dispatch(setPhotosError(errorMsg));
};

const getThumb = (thumb, extension, watermark, isLandscape) => {
  if (watermark) {
    return isLandscape ? thumb.replace('-1x-1', '600x-1') : thumb.replace('-1x-1', '-1x600');
  }
  if (extension !== 'png') { // workaround for charts
    return thumb.replace('-1x-1', '-999x-999');
  }
  return thumb;
};

export const fetchAndDownloadPhoto = (id, thumb, watermark = false, isLandscape = true) => async (dispatch, getState) => {
  if (!(id && thumb)) return;
  let hardCapped = false;

  dispatch(setPhotosDownloading());

  try {
    if (!watermark && getState().user.user.quotaInfo && getState().user.user.quotaInfo.imageHardCap) {
      const { data: { hasRemainingQuota } } = await axios.get(`/api/audits/quota?assetType=${ASSET_TYPES.image.type}`, { timeout: PHOTO_TIMEOUT });
      hardCapped = !hasRemainingQuota;
    }

    if (hardCapped) {
      dispatch(updateContactUsForm('topic', 'Add Photo Access'));
      dispatch(showQuotaExceededModal());
    } else {
      const extension = thumb.split('.').pop();
      const actualThumb = getThumb(thumb, extension, watermark, isLandscape);

      const assetVersion = watermark && 'watermark' || 'paid';
      saveAs(actualThumb, `${id}.${extension}`);
      trackCustomEvent({
        category: ASSET_TYPES.image.type,
        action: 'download',
      }, {
        id,
        mediaAssetType: assetVersion,
      });

      try {
        await axios.post('/api/audits/download', {
          assetId: id,
          assetType: ASSET_TYPES.image.type,
          assetVersion,
          extension,
        }, { timeout: PHOTO_TIMEOUT });
      } catch (err) {
        const { message } = getAxiosError(err);
        console.error(`photo download audit failed; photo: ${id}; message: ${JSON.stringify(message)}`);
      }
    }
  } catch (err) {
    console.error(`Error downloading photo, id: ${id}, watermark: ${watermark}, thumb: ${thumb},`, err);
    handleError(dispatch, err);
  }
  dispatch(setPhotosDownloading(false));
};

export const downloadPhotoAttachment = (id, thumb, revision, watermark = false, isLandscape = true) => async (dispatch) => {
  dispatch(setPhotosDownloading());

  try {
    const extension = thumb.split('.').pop();
    const actualThumb = getThumb(thumb, extension, watermark, isLandscape);
    const assetVersion = `attachment${watermark ? '_watermark' : ''}`;

    saveAs(actualThumb, `${id}.${extension}`);
    trackCustomEvent({
      category: ASSET_TYPES.image.type,
      action: 'download',
    }, {
      id,
      mediaAssetType: assetVersion,
    });

    try {
      await axios.post('/api/audits/download', {
        assetId: id,
        assetType: ASSET_TYPES.image.type,
        assetVersion,
        extension,
        revision,
      }, { timeout: PHOTO_TIMEOUT });
    } catch (err) {
      const { message } = getAxiosError(err);
      console.error(`photo attachment download audit failed; photo: ${id}; message: ${JSON.stringify(message)}`);
    }
  } catch (err) {
    console.error(`Error downloading photo, id: ${id}, watermark: ${watermark}, thumb: ${thumb},`, err);
    handleError(dispatch, err);
  }
  dispatch(setPhotosDownloading(false));
};

export const getOrFetchPhoto = id => async (dispatch, getState) => {
  if (!id) return;

  const photo = getState().photos.photos[id];
  if (photo) {
    dispatch(setPhoto(photo));
    return;
  }
  try {
    dispatch(setPhotosLoading());
    const { data } = await axios.get(`/api/photos/${id}`, { timeout: PHOTO_TIMEOUT });
    dispatch(setPhoto(data));
    dispatch(addPhoto(data));
  } catch (err) {
    handleError(dispatch, err);
  }
};
