import React, { memo, cloneElement } from 'react';
import GridItem from 'components/elements/common/griditem/GridItem';

import './StoryGridItem.scss';

export const StoryGridItem = (props) => (
  <GridItem
    {...props}
    className={`news-grid-item ${props.className}`}
    contents={cloneElement(props.contents, { ...props })}
    actions={cloneElement(props.actions, { ...props })}
  />
);

export default memo(StoryGridItem, (prevProps, nextProps) => prevProps.contents.id === nextProps.contents.id);
