import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Loading from 'components/elements/common/loading/Loading';
import SeriesCatalogList from 'components/elements/pages/video/seriesCatalog/SeriesCatalogList';
import FeaturedSeries from 'components/elements/pages/video/common/FeaturedSeries';
import SecondaryFeaturedSeries from 'components/elements/pages/video/common/SecondaryFeaturedSeries';
import { getVideoSeriesCatalog } from 'store/actions/videoSeriesCatalog';
import Page from '../../Page';

import './VideoSeriesCatalog.scss';

export const CATALOG_PRIMARY_SECTION_LIMIT = 6;
export const CATALOG_PRIMARY_FEATURE_LIMIT = 3;
export const CATALOG_SECONDARY_FEATURE_LIMIT = 2;

export class VideoSeriesCatalog extends Component {
  componentDidMount() {
    const { match: { params: { type } } } = this.props;
    this.props.getVideoSeriesCatalog(type);
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { type } } } = this.props;
    const { match: { params: { type: previousType } } } = prevProps;

    if (type !== previousType) {
      this.props.getVideoSeriesCatalog(type);
    }
  }

  getCatalogListSections() {
    const { series } = this.props;
    return {
      primary: series.slice(0, CATALOG_PRIMARY_SECTION_LIMIT),
      secondary: series.slice(CATALOG_PRIMARY_SECTION_LIMIT),
    };
  }

  getFeaturedSeries() {
    const { series } = this.props;
    const seriesCopy = [...series];
    seriesCopy.sort(() => Math.random() - 0.5); // randomly shuffle the array
    return {
      primary: seriesCopy.slice(0, CATALOG_PRIMARY_FEATURE_LIMIT),
      secondary: seriesCopy.slice(CATALOG_PRIMARY_FEATURE_LIMIT, CATALOG_PRIMARY_FEATURE_LIMIT + CATALOG_SECONDARY_FEATURE_LIMIT),
    };
  }

  render() {
    const { loading, error, match: { params: { type } } } = this.props;

    if (loading && !error) {
      return <Loading />;
    }

    const featuredSeries = this.getFeaturedSeries();
    const catalogListSections = this.getCatalogListSections();
    return (
      <Page className="video-series-catalog" title={`${type} Video Catalog`} pageType="video-series-catalog" error={error}>
        <FeaturedSeries infiniteLoop={true} autoPlay={true} interval={5000} showIndicators={false} showThumbs={false} series={featuredSeries.primary} />
        <SeriesCatalogList series={catalogListSections.primary}/>
        <SecondaryFeaturedSeries series={featuredSeries.secondary}/>
        <SeriesCatalogList series={catalogListSections.secondary}/>
      </Page>
    );
  }
}

const mapStateToProps = ({ videoSeriesCatalog: { series, loading, error } }) => ({
  series,
  loading,
  error,
});

const mapDispatchToProps = {
  getVideoSeriesCatalog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideoSeriesCatalog));
