import { filterRegex } from 'utils/helpers';

export const SET_SAVEDSEARCH_LOADING = 'redux/savedsearch/SET_SAVEDSEARCH_LOADING';
export const SET_SAVEDSEARCH_ERROR = 'redux/savedsearch/SET_SAVEDSEARCH_ERROR';
export const ADD_SAVEDSEARCH = 'redux/savedsearch/ADD_SAVEDSEARCH';
export const REMOVE_SAVEDSEARCH = 'redux/savedsearch/REMOVE_SAVEDSEARCH';
export const SET_SAVEDSEARCHES = 'redux/savedsearch/SET_SAVEDSEARCHES';
export const FILTER_DISPLAY_SEARCHES = 'redux/savedsearch/FILTER_DISPLAY_SEARCHES';
export const RESET_DISPLAY_SEARCHES = 'redux/savedsearch/RESET_DISPLAY_SEARCHES';

export const initialState = {
  loading: false,
  error: false,
  savedSearches: [],
  unsuccessfulRecipients: [],
  displaySavedSearches: [],
};

const savedsearch = (state = initialState, action) => {
  switch (action.type) {
    case SET_SAVEDSEARCH_LOADING:
      return {
        ...state,
        error: false,
        loading: action.data.loading,
      };
    case SET_SAVEDSEARCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data.error,
      };
    case ADD_SAVEDSEARCH:
      return {
        ...state,
        loading: false,
        error: false,
        savedSearches: [...state.savedSearches, action.data.savedSearch],
        displaySavedSearches: [...state.savedSearches, action.data.savedSearch],
      };
    case REMOVE_SAVEDSEARCH:
      return {
        ...state,
        loading: false,
        error: false,
        savedSearches: state.savedSearches.filter(({ id }) => id !== action.data.id),
        displaySavedSearches: state.displaySavedSearches.filter(({ id }) => id !== action.data.id),
      };
    case SET_SAVEDSEARCHES:
      return {
        ...state,
        loading: false,
        error: false,
        savedSearches: action.data.savedSearches,
        displaySavedSearches: action.data.savedSearches,
      };
    case FILTER_DISPLAY_SEARCHES:
      return {
        ...state,
        displaySavedSearches: state.savedSearches.filter(({ name }) => filterRegex(action.data.filter).test(name)),
      };
    case RESET_DISPLAY_SEARCHES:
      return {
        ...state,
        displaySavedSearches: [...state.savedSearches],
      };
    default:
      return state;
  }
};

export default savedsearch;
