export const SET_TOPICPAGE_LOADING = 'redux/topicpage/SET_TOPICPAGE_LOADING';
export const SET_TOPICPAGE_ERROR = 'redux/topicpage/SET_TOPICPAGE_ERROR';
export const SET_TOPICPAGE = 'redux/topicpage/SET_TOPICPAGE';

export const initialState = {
  loading: false,
  error: false,
  topics: [],
};

const topicpage = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOPICPAGE_LOADING:
      return {
        ...state,
        loading: action.data.loading,
      };
    case SET_TOPICPAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data.error,
      };
    case SET_TOPICPAGE:
      return {
        ...state,
        loading: false,
        topics: action.data.topics,
      };
    default:
      return state;
  }
};

export default topicpage;
