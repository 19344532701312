import axios from 'axios';
import { getAxiosError, saveToFile } from 'components/utils/helpers';
import { trackCustomEvent } from 'components/utils/analytics';
import { ASSET_TYPES } from 'components/utils/constants';
import { updateUserNewsPermissions } from './user';
import {
  SET_NEWS_LOADING,
  SET_NEWS_ERROR,
  SET_NEWS_DOWNLOAD_ERROR,
  SET_STORY,
  ADD_STORY,
  REMOVE_NEWS_DOWNLOAD_ERRORS,
} from '../reducers/news';
import { showAccessDenied, showSignIn, showQuotaExceededModal, addNotification } from './globalActions';
import { updateForm as updateContactUsForm } from './contactUs';
import {
  DATA_ENCODINGS,
  NEWS_FILE_EXTENSION,
  NEWS_COPY_EXTENSION,
  NEWS_TIMEOUT,
} from '../../components/utils/constants';

export const setNewsLoading = (loading = true) => ({
  type: SET_NEWS_LOADING,
  data: { loading },
});

export const setNewsError = error => ({
  type: SET_NEWS_ERROR,
  data: { error },
});

export const setNewsDownloadError = (id, error) => ({
  type: SET_NEWS_DOWNLOAD_ERROR,
  data: { id, error },
});

export const setStory = story => ({
  type: SET_STORY,
  data: {
    story,
  },
});

export const addStory = story => ({
  type: ADD_STORY,
  data: {
    story,
  },
});

export const removeNewsDownloadErrors = () => ({
  type: REMOVE_NEWS_DOWNLOAD_ERRORS,
});

const handleError = (err, showPopup = false) => {
  const { status, message } = getAxiosError(err);
  if (showPopup) {
    if (status === 403) {
      return showAccessDenied();
    }
    if (status === 401) {
      return showSignIn();
    }
  }

  const errorMsg = status === 404 ? status : message;
  if (showPopup) {
    return addNotification({
      type: 'error',
      message: errorMsg,
    });
  }
  return setNewsError(errorMsg);
};

export const getOrFetchNews = id => async (dispatch, getState) => {
  if (!id) return;

  const cachedStory = getState().news.news[id];
  const { user: { permissions: perms } } = getState().user;
  if (cachedStory && perms.news[id]) {
    dispatch(setStory(cachedStory));
    return;
  }

  try {
    dispatch(setNewsLoading());
    dispatch(setStory({}));
    const { data: { story, permissions } } = await axios.get(`/api/news/${id}`, {
      timeout: NEWS_TIMEOUT,
    });

    dispatch(updateUserNewsPermissions(story.revision, permissions));
    dispatch(setStory(story));
    dispatch(addStory(story));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const isHardcapped = async (user, id) => {
  // if any of the news subscriptions have a known hardCap, make the call to see if the limit has been reached
  if (user.quotaInfo && user.quotaInfo.newsHardCap) {
    const { data: { hasRemainingQuota } } = await axios.get(`/api/audits/quota?assetId=${id}&assetType=${ASSET_TYPES.news.type}`, { timeout: NEWS_TIMEOUT });
    return !hasRemainingQuota;
  }

  return false;
};

export const auditNewsDownload = async (id, copy) => {
  try {
    const payload = {
      assetId: id,
      assetType: ASSET_TYPES.news.type,
      assetVersion: 'paid',
      extension: copy ? NEWS_COPY_EXTENSION : NEWS_FILE_EXTENSION,
    };
    if (copy) {
      payload.assetData = { copy };
    }
    await axios.post('/api/audits/download', payload, {
      timeout: NEWS_TIMEOUT,
    });
  } catch (err) {
    const { message } = getAxiosError(err);
    console.error(`news download audit failed; id: ${id}; message: ${JSON.stringify(message)}`);
  }
};

export const downloadNews = (id, displayErrorInline = false) => async (dispatch, getState) => {
  if (!id) return;

  try {
    dispatch(setNewsLoading());

    const { user: { user } }  = getState();
    const hardCapped = await isHardcapped(user, id);

    if (hardCapped) {
      dispatch(updateContactUsForm('topic', 'Add News Access'));
      dispatch(showQuotaExceededModal());
    } else {
      const { data } = await axios.get(`/api/news/${id}/download`, { timeout: NEWS_TIMEOUT });
      saveToFile(data, DATA_ENCODINGS.plain, `${id}.${NEWS_FILE_EXTENSION}`);

      trackCustomEvent({
        category: ASSET_TYPES.news.type,
        action: 'download',
      }, {
        id,
        mediaAssetType: 'paid',
      });
      await auditNewsDownload(id);
    }
  } catch (err) {
    if (displayErrorInline) {
      dispatch(setNewsDownloadError(id, err));
    } else {
      dispatch(handleError(err, true));
    }
  }
  dispatch(setNewsLoading(false));
};
