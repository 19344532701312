import React from 'react';
import { connect } from 'react-redux';
import { clearNotifications } from 'store/actions/globalActions';

import './Notification.scss';

const getType = notifications => notifications.length && notifications[0].type || '';

const Notification = ({ showNotifications, notifications, ...props }) => (
  <div className={`notification ${showNotifications ? 'open' : 'closed'} ${getType(notifications)}`}>
    <button className="notification--close" onClick={props.clearNotifications}>Close X</button>
    {notifications.map(({ type, message, details }, i) => (
      <details key={`details_${i}`} className={details ? '' : 'empty'}>
        <summary><span>{type}</span>: {message}</summary>
        {details && <p>{details}</p>}
      </details>
    ))}
  </div>
);

const mapStateToProps = ({ globalActions }) => ({
  showNotifications: globalActions.showNotifications,
  notifications: globalActions.notifications,
});

const mapDispatchToProps = {
  clearNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
