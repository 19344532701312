import React, { Component } from 'react';
import axios from 'axios';
import { Route, Link } from 'react-router-dom';
import TextInput from 'components/elements/common/forms/TextInput';
import EmailInput from 'components/elements/common/forms/EmailInput';
import PasswordInput from 'components/elements/common/forms/PasswordInput';
import ErrorView from 'elements/common/ErrorView';
import {
  PASSWORD_REGEX,
  PASSWORD_RULES_LANGUAGE,
  REGISTRATION_SUCCESS_LANGUAGE,
} from 'components/utils/constants';
import { validateForm, getAxiosError } from 'components/utils/helpers';
import Page from '../Page';

import './Registration.scss';

const validatePassword = password => (password.length && !PASSWORD_REGEX.test(password) ? PASSWORD_RULES_LANGUAGE : false);
const REGISTRATION_TIMEOUT = process.env.REGISTRATION_TIMEOUT || 10000;

class Registration extends Component {
  constructor(props) {
    super(props);

    const { match: { params: { publicationToken } = {} } = {} } = props;

    let form = {
      firstName: '',
      lastName: '',
      email: '',
      confirmpassword: '',
      password: '',
    };

    if (!publicationToken) {
      form = {
        ...form,
        industry: '',
        company: '',
      };
    }

    this.state = {
      submitting: false,
      submitted: false,
      apiError: false,
      form,
      errors: {
        password: false,
        confirmpassword: false,
      },
    };
  }

  onChange({ target: { name, value } }) {
    this.setState(prevState => ({ form: { ...prevState.form, [name]: value.trim() } }));
  }

  onPasswordBlur({ target: { value } }) {
    // set state is async, so we must use form values
    const password = value.trim();
    const error = password.length && validatePassword(password);

    this.setState({ errors: { ...this.state.errors, password: error } });
  }

  onConfirmPasswordBlur(evt) {
    // set state is async, so we must use form values
    const { form: { password } } = this.state;
    const confirmpassword = evt.target.value.trim();
    const error = (password.length && confirmpassword.length && password !== confirmpassword) ? 'Passwords do not match' : false;

    this.setState(prevState => ({ errors: { ...prevState.errors, confirmpassword: error } }));
  }

  onPasswordFocus({ target: { name } }) {
    this.setState(prevState => ({ errors: { ...prevState.errors, [name]: false } }));
  }

  validateForm() {
    let valid = validateForm(Object.keys(this.state.form));

    Object.keys(this.state.errors).forEach((name) => {
      valid = valid && !this.state.errors[name];
    });

    return valid;
  }

  async submitForm() {
    try {
      this.setState({ submitting: true });
      const { match: { params: { publicationToken } = {} } = {} } = this.props;

      const url = `/api/register${publicationToken ? `/${publicationToken}` : ''}`;
      const { data: { alreadyInBconnect } } = await axios.post(url, { ...this.state.form }, { timeout: REGISTRATION_TIMEOUT });

      this.setState({ submitted: true, alreadyInBconnect });
    } catch (err) {
      const { message } = getAxiosError(err);
      this.setState({ apiError: message });
    }
    this.setState({ submitting: false });
  }

  onSubmit(evt) {
    evt.preventDefault();
    this.setState({ apiError: false });

    if (this.validateForm()) {
      this.submitForm();
    }
  }

  renderAlreadyInBconnectMessage() {
    return (
      <React.Fragment>
        <p>You may begin using the site with your Bloomberg password</p>
        <p>Don&apos;t remember your password? <Link to="/reset-password">Reset your password here</Link></p>
      </React.Fragment>
    );
  }

  renderSuccess() {
    const { alreadyInBconnect } = this.state;
    const message = alreadyInBconnect ? this.renderAlreadyInBconnectMessage() : <p>{REGISTRATION_SUCCESS_LANGUAGE}</p>;
    return (
      <div className="registration-success">
        <strong>Success</strong>
        {message}
      </div>
    );
  }

  renderForm() {
    const { errors, submitting } = this.state;

    return (
      <form method="post" onSubmit={evt => this.onSubmit(evt)}>
        <div className="registration-columns">
          <TextInput label="First Name" name="firstName" onChange={evt => this.onChange(evt)} required />
          <TextInput label="Last Name" name="lastName" onChange={evt => this.onChange(evt)} required />
        </div>

        <EmailInput label="Email" name="email" placeholder="name@example.com" onChange={evt => this.onChange(evt)} required />

        <Route exact path="/registration" render={() => (
          <React.Fragment>
            <TextInput label="Company" name="company" onChange={evt => this.onChange(evt)} required />
            <TextInput label="Industry" name="industry" placeholder="Industry ex. Media, Finance" onChange={evt => this.onChange(evt)} required/>
          </React.Fragment>
        )}/>

        <div className="registration-passwords">
          <legend>{PASSWORD_RULES_LANGUAGE}</legend>
          <PasswordInput
            label="Password"
            name="password"
            onChange={evt => this.onChange(evt)}
            onFocus={evt => this.onPasswordFocus(evt)}
            onBlur={evt => this.onPasswordBlur(evt)}
            errorMsg={errors.password}
            required
          />
          <PasswordInput
            label="Confirm Password"
            name="confirmpassword"
            onChange={evt => this.onChange(evt)}
            onFocus={evt => this.onPasswordFocus(evt)}
            onBlur={evt => this.onConfirmPasswordBlur(evt)}
            errorMsg={errors.confirmpassword}
            required
          />
        </div>

        <button className="button-submit registration-submit" type="submit" onClick={evt => this.onSubmit(evt)} disabled={submitting}>Submit</button>
      </form>
    );
  }

  render() {
    const { apiError, submitted } = this.state;
    return (
      <Page className="registration" title="registration" pageType="registration">
        <h1>Register</h1>
        {submitted && this.renderSuccess() || this.renderForm()}
        {apiError && <ErrorView className="registration-error" error={apiError} />}
      </Page>
    );
  }
}

export default Registration;
