import { combineReducers } from 'redux';
import autocomplete from './autocomplete';
import announcement from './announcement';
import collection from './collection';
import collections from './collections';
import globalActions from './globalActions';
import homepage from './homepage';
import news from './news';
import photos from './photos';
import savedsearch from './savedsearch';
import search from './search';
import user from './user';
import videos from './videos';
import contactUs from './contactUs';
import quickFind from './quickFind';
import videoSeriesCatalog from './videoSeriesCatalog';
import videoSeriesContent from './videoSeriesContent';
import bookmarks from './bookmarks';
import topicpage from './topicpage';

export const CLEAR_STORE = 'CLEAR_STORE';

const appReducer = combineReducers({
  autocomplete,
  announcement,
  collection,
  collections,
  globalActions,
  homepage,
  news,
  photos,
  savedsearch,
  search,
  user,
  videos,
  contactUs,
  quickFind,
  videoSeriesContent,
  videoSeriesCatalog,
  bookmarks,
  topicpage,
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_STORE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
