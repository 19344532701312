export const SET_VIDEO_SERIES_CATALOG_ERROR = 'redux/videoSeriesCatalog/SET_VIDEO_SERIES_CATALOG_ERROR';
export const SET_VIDEO_SERIES_CATALOG_LOADING = 'redux/videoSeriesCatalog/SET_VIDEO_SERIES_CATALOG_LOADING';
export const SET_VIDEO_SERIES_CATALOG_SERIES = 'redux/videoSeriesCatalog/SET_VIDEO_SERIES_CATALOG_SET_SERIES';

export const initialState = {
  error: false,
  loading: false,
  series: [],
};

const videoSeriesCatalog = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIDEO_SERIES_CATALOG_ERROR:
      return {
        ...state,
        error: action.data.error,
      };
    case SET_VIDEO_SERIES_CATALOG_LOADING:
      return {
        ...state,
        loading: action.data.loading,
      };
    case SET_VIDEO_SERIES_CATALOG_SERIES:
      return {
        ...state,
        series: action.data.series,
      };
    default:
      return state;
  }
};

export default videoSeriesCatalog;
