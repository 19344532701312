import React from 'react';
import Story from '../common/Story';

import './Spotlight.scss';

const Spotlight = ({ title, mercuryIcon, mercuryTheme, items, row }) => {
  const itemsWithImages = items.filter(({ thumbnail }) => !!thumbnail);
  let stories = [...itemsWithImages].splice(0, 4);
  if (stories.length < 4) {
    stories = stories.splice(0, 2);
  }

  if (stories.length < 2) {
    console.warn(`SPOTLIGHT ERROR: insufficient number of stories for ${title}`);
    return null;
  }

  const layout = stories.length === 2 ? 'double' : 'quartet';
  const crops = {
    double: {
      desktop: '507x292',
      tablet: '385x220',
      mobile: '320x167',
    },
    quartet: {
      desktop: '376x251',
      tablet: '193x167',
      mobile: '320x167',
    },
  };

  return (
    <section className={`spotlight ${mercuryTheme || 'light'}-theme`}>
      <div className="spotlight-wrapper">
        <h3 className={`spotlight--${mercuryIcon || 'photo'}`}>{title}</h3>
        <div className={'spotlight-container'}>
          {stories.map((story, i) => <Story className="spotlight-story" key={story.id || story.title.replace(/[\W_]+/g, '_')} crops={crops[layout]} story={story} row={row} column={i} moduleTitle={title} />,
          )}
        </div>
      </div>
    </section>
  );
};

export default Spotlight;
