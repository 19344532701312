import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toggle from 'react-toggle';
import debounce from 'lodash.debounce';
import ResponsiveImage from 'components/elements/common/ResponsiveImage/ResponsiveImage';
import Loading from 'components/elements/common/loading/Loading';
import { downloadNews as fetchAndDownloadNews, removeNewsDownloadErrors } from 'store/actions/news';
import { showQuotaExceededModal } from 'store/actions/globalActions';
import { fetchAndDownloadPhoto, downloadPhotoAttachment } from 'store/actions/photos';
import { postVideoDownload, getVideoPermissions } from 'store/actions/videos';
import saveAs from 'file-saver';
import { getAxiosError } from 'components/utils/helpers';
import { trackCustomEvent } from 'components/utils/analytics';
import Modal from '../Modal';
import AccessDeniedModal from '../AccessDeniedModal';
import { ASSET_TYPES, DOWNLOAD_DEBOUNCE_DELAY, VIDEO_ATTACHMENTS, VIDEO_RENDITIONS } from '../../../../utils/constants';

import './MultifileDownload.scss';

const HARD_CAP_TIMEOUT = 10000; // 10 seconds

const crops = {
  desktop: '75x-1',
  tablet: '75x-1',
  mobile: '75x-1',
};

const imageDownloadOptions = {
  watermark: { value: 'watermark', display: 'Low Resolution' },
  highres: { value: 'highres', display: 'High Resolution' },
  svg: { value: 'svg', display: 'High Resolution (SVG)' },
};

const downloadStatuses = {
  downloading: <Loading />,
  success: 'Download Successful',
  error: 'Download Error',
  unauthorized: 'No Access',
};

const getDefaultImageDownloadType = (file, permission) => {
  if (permission.download) {
    if (file.svgFile) {
      return imageDownloadOptions.svg.value;
    }
    return imageDownloadOptions.highres.value;
  }
  return imageDownloadOptions.watermark.value;
};

const defaultDownloadType = (file, permission) => ({
  [ASSET_TYPES.image.type]: getDefaultImageDownloadType(file, permission),
  [ASSET_TYPES.news.type]: 'newsml',
  [ASSET_TYPES.video.type]: 'mp4',
});

const setDownloadType = (file, permission) => ({
  url: file.thumb,
  ...file,
  downloadType: defaultDownloadType(file, permission)[file.type],
});

const formatFiles = (files, permissions) => files.map((file) => {
  const permission = permissions[file.type] || {};
  return setDownloadType(file, permission);
}).filter(file => !!file);

export class MultifileDownload extends Component {
  constructor(props) {
    super(props);
    this.debounceDownload = debounce(this.download, DOWNLOAD_DEBOUNCE_DELAY, { leading: true, trailing: false });

    this.state = {
      files: formatFiles(props.files, props.permissions),
      downloadHiRes: true,
      downloadInitiated: false,
      downloading: false,
    };
  }

  /* istanbul ignore next */
  componentDidUpdate(prevProps) {
    if (this.props.files.length !== prevProps.files.length) {
      this.setState({ files: formatFiles(this.props.files, this.props.permissions) });
    }
  }

  removeFile(fileId, fileType) {
    this.setState(prevState => ({
      files: prevState.files.filter(({ id, type }) => !(id === fileId && type === fileType)),
    }));
  }

  handleClose() {
    const { files, permissions, clearNewsDownloadErrors, close } = this.props;
    this.setState({
      files: formatFiles(files, permissions),
      downloadHiRes: true,
      downloadInitiated: false,
      downloading: false,
    });
    clearNewsDownloadErrors();
    close();
  }

  setFileDownloadType(id, type, { target: { value } }) {
    this.setState(prevState => ({
      files: prevState.files.map(file => ({
        ...file,
        downloadType: id === file.id && type === file.type ? value : file.downloadType,
      })),
    }));
  }

  renderImage({ id, type, url, svgFile, title, downloadType, downloadStatus }) {
    const { permissions: { image } } = this.props;
    const options = [imageDownloadOptions.watermark];

    if (image && image.download) {
      options.push(imageDownloadOptions.highres);

      if (svgFile) {
        options.push(imageDownloadOptions.svg);
      }
    }

    return (
      <React.Fragment>
        <span className="multifile-download--file__asset">
          <ResponsiveImage imageUrl={url} title={title} crops={crops} />
        </span>
        <button className="multifile-download--file__remove" onClick={() => this.removeFile(id, type) } aria-label={`remove image ${title}`} />
        <span className="multifile-download--file__options">
          <select value={downloadType} onChange={evt => this.setFileDownloadType(id, type, evt)}>
            {options.map(option => <option key={option.value} value={option.value}>{option.display}</option>)}
          </select>
        </span>
        <span className="multifile-download--file__status">{downloadStatus || ''}</span>
      </React.Fragment>
    );
  }

  renderVideo({ id, type, thumb, title, downloadStatus }) {
    return (
      <React.Fragment>
        <span className="multifile-download--file__asset">
          <ResponsiveImage imageUrl={thumb} title={title} crops={crops} />
        </span>
        <button className="multifile-download--file__remove" onClick={() => this.removeFile(id, type) } aria-label={`remove video ${title}`} />
        <span className="multifile-download--file__options">
          <select defaultValue="MP4">
            <option value="MP4">MP4</option>
          </select>
        </span>
        <span className="multifile-download--file__status">{downloadStatus || ''}</span>
      </React.Fragment>
    );
  }

  renderNews({ id, type, title, downloadStatus }) {
    return (
      <React.Fragment>
        <span className="multifile-download--file__asset">{title}</span>
        <button className="multifile-download--file__remove" onClick={() => this.removeFile(id, type) } aria-label={`remove news ${title}`} />
        <span className="multifile-download--file__options">
          <select defaultValue="XML">
            <option value="XML">XML</option>
          </select>
        </span>
        <span className="multifile-download--file__status">{downloadStatus || ''}</span>
      </React.Fragment>
    );
  }

  renderDownloadHiResToggle() {
    const { downloadHiRes } = this.state;
    return (
      <div className="toggle-container">
        <div className="toggle-container-copy">
          High Resolution Images
        </div>
        <div className="toggle-container-toggle">
          <Toggle
            className="accent-toggle"
            checked={downloadHiRes}
            icons={false}
            onChange={() => this.toggleDownloadHiRes()}
          />
        </div>
      </div>
    );
  }

  renderAsset(asset) {
    switch (asset.type) {
      case ASSET_TYPES.image.type:
        return this.renderImage(asset);
      case ASSET_TYPES.news.type:
        return this.renderNews(asset);
      case ASSET_TYPES.video.type:
        return this.renderVideo(asset);
      default:
        throw new Error(`Invalid asset type ${asset.type}`);
    }
  }

  async download() {
    if (await this.exceedsHardCap()) {
      this.props.showQuotaExceededModal();
      return;
    }

    const { files } = this.state;
    const { downloadNews, downloadPhoto, isAttachment, revision } = this.props;
    this.setDownloadInitiated();

    files.forEach(async (file) => {
      const { url, svgFile, id, type, watermark, downloadType, width, height } = file;
      const downloadPerms = await this.checkDownloadPermission(id, type);
      if (!downloadPerms.download) {
        return this.updateFileDownloadStatus(id, downloadStatuses.unauthorized);
      }
      this.updateFileDownloadStatus(id, downloadStatuses.downloading);

      if (type === ASSET_TYPES.image.type) {
        const isWatermark = downloadType === 'watermark';

        let imageUrl;
        if (isWatermark) {
          imageUrl = watermark;
        } else if (downloadType === imageDownloadOptions.svg.value) {
          imageUrl = svgFile;
        } else {
          imageUrl = url;
        }
        if (isAttachment) {
          await this.props.downloadPhotoAttachment(id, imageUrl, revision, isWatermark, width > height);
        } else {
          await downloadPhoto(id, imageUrl, isWatermark, width > height);
        }
        this.updateFileDownloadStatus(id, downloadStatuses.success);
      }

      if (type === ASSET_TYPES.news.type) {
        await downloadNews(id, true);
        this.setCompletedNewsDownloadStatus(id);
      }

      if (type === ASSET_TYPES.video.type) {
        // assuming only the compressed format can be download in bulk - UI shows the MP4 option only anyway
        const downloadUrl = downloadPerms.extra.compressed;
        await saveAs(downloadUrl, `${id}.${VIDEO_RENDITIONS.compressed.displayName}`);

        if (isAttachment) {
          await this.props.postVideoDownload(id, downloadUrl, { ...VIDEO_ATTACHMENTS, revision });
        } else {
          await this.props.postVideoDownload(id, downloadUrl, VIDEO_RENDITIONS.compressed);
        }
        this.updateFileDownloadStatus(id, downloadStatuses.success);
      }
    });

    this.setDownloadFinished();
    trackCustomEvent({
      category: 'multifile',
      action: 'download',
    });
  }

  async checkDownloadPermission(id, type) {
    const { permissions } = this.props;
    if (type === ASSET_TYPES.image.type) {
      return { download: permissions[type] && permissions[type].download };
    }
    if (type === ASSET_TYPES.video.type) {
      const { permissions: videoPerms, renditions } = await this.props.getVideoPermissions(id);
      return { download: videoPerms.download, extra: { ...renditions } };
    }
    // news permissions get checked on the api call
    return { download: true };
  }

  updateFileDownloadStatus(id, status) {
    this.setState(prevState => ({
      files: Object.values({
        ...prevState.files,
        [prevState.files.map(file => file.id).indexOf(id)]: {
          ...prevState.files.find(file => file.id === id),
          downloadStatus: status,
        },
      }),
    }));
  }

  setCompletedNewsDownloadStatus(id) {
    const { newsDownloadErrors } = this.props;
    let statusToSet = downloadStatuses.error;

    const downloadError = newsDownloadErrors ? newsDownloadErrors[id] : null;
    if (!downloadError) {
      statusToSet = downloadStatuses.success;
    } else if (downloadError.response && downloadError.response.status && downloadError.response.status < 500) {
      statusToSet = downloadStatuses.unauthorized;
    }

    this.updateFileDownloadStatus(id, statusToSet);
  }

  async exceedsHardCap() {
    const { imageHardCap, newsHardCap } = this.props.quotaInfo || {};
    const hasHardCap = !!(imageHardCap || newsHardCap);
    if (!hasHardCap) {
      return false;
    }

    const { files } = this.state;
    const assets = files.map(({ id, type, downloadType }) => ({
      assetId: id,
      assetType: type,
      assetVersion: downloadType === 'watermark' ? 'watermark' : 'paid',
    }));
    try {
      const { data: { hasRemainingQuota } } = await axios.post('/api/audits/quota/bulk', { assets }, { timeout: HARD_CAP_TIMEOUT });
      return !hasRemainingQuota;
    } catch (err) {
      const { status, message } = getAxiosError(err);
      console.error(`Error checking bulk hardcap, status: ${status}`, message);
      return false; // allow download
    }
  }

  toggleDownloadHiRes() {
    const { files, downloadHiRes } = this.state;
    const { permissions: { image } } = this.props;

    const downloadType = (image && image.download && !downloadHiRes) ? 'highres' : 'watermark';

    const updatedFiles = files.map(file => ((file.type !== ASSET_TYPES.image.type) ? file : {
      ...file,
      downloadType,
    }));

    this.setState({ files: updatedFiles, downloadHiRes: !downloadHiRes });
  }

  setDownloadInitiated() {
    this.setState({ downloadInitiated: true, downloading: true });
  }

  setDownloadFinished() {
    this.setState({ downloading: false });
  }

  render() {
    const { open, close, loading = false } = this.props;
    const { files, downloadInitiated, downloading } = this.state;
    if (!files.length && !loading) {
      return <AccessDeniedModal open={open} handleClose={close} />;
    }

    return (
      <Modal className="multifile-download" theme="dark" open={open} handleClose={() => this.handleClose()}>
        <h3>Download All</h3>
        {loading
          ? <Loading color="white" />
          : <React.Fragment>
            <div className="multifile-download--files">
              <div className="multifile-download--file legend">
                <div className="multifile-download--file__asset">Asset</div>
                <div className="multifile-download--file__options">
                  Format
                  {this.renderDownloadHiResToggle()}
                </div>
                <div className="multifile-download--file__status">{downloadInitiated && 'Status'}</div>
              </div>
              {files.map(file => <div className={`multifile-download--file multifile-download--file__${file.type} file-item`} key={`${file.type}_${file.id}`}>
                  {this.renderAsset(file)}
                </div>,
              )}
            </div>
            <div className="multifile-download--actions">
              <button className="multifile-download--actions__cancel" onClick={() => this.handleClose()}>Cancel</button>
              <button className="multifile-download--actions__download button-contact" onClick={() => this.debounceDownload()} disabled={downloading}>Download</button>
            </div>
          </React.Fragment>
        }
      </Modal>
    );
  }
}

const mapStateToProps = ({ user, news }) => ({
  quotaInfo: user.user.quotaInfo,
  newsDownloadErrors: news.downloadErrors,
});

const mapDispatchToProps = {
  downloadNews: fetchAndDownloadNews,
  downloadPhoto: fetchAndDownloadPhoto,
  downloadPhotoAttachment,
  postVideoDownload,
  getVideoPermissions,
  clearNewsDownloadErrors: removeNewsDownloadErrors,
  showQuotaExceededModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(MultifileDownload);
