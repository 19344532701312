import axios from 'axios';
import { getAxiosError } from 'components/utils/helpers';
import {
  SET_VIDEO_SERIES_CONTENT_ERROR,
  SET_VIDEO_SERIES_CONTENT_LOADING,
  SET_VIDEO_SERIES_CONTENT_DATA,
} from '../reducers/videoSeriesContent';

export const VIDEO_SERIES_CONTENT_TIMEOUT = process.env.VIDEO_SERIES_CONTENT_TIMEOUT || 10000;

export const setVideoSeriesContentError = error => ({
  type: SET_VIDEO_SERIES_CONTENT_ERROR,
  data: { error },
});

export const setVideoSeriesContentLoading = (loading = true) => ({
  type: SET_VIDEO_SERIES_CONTENT_LOADING,
  data: { loading },
});

export const setVideoSeriesContentData = (content = {}) => ({
  type: SET_VIDEO_SERIES_CONTENT_DATA,
  data: { content },
});

export const getVideoSeriesContent = id => async (dispatch) => {
  if (!id) return;

  try {
    dispatch(setVideoSeriesContentData({}));
    dispatch(setVideoSeriesContentLoading());
    const { data } = await axios.get(`/api/videos/series/content/${id}`, { timeout: VIDEO_SERIES_CONTENT_TIMEOUT });
    dispatch(setVideoSeriesContentData(data));
  } catch (err) {
    const { status, message } = getAxiosError(err);
    const errorMsg = status === 404 ? status : message;
    dispatch(setVideoSeriesContentError(errorMsg));
    console.error(`error getting video series content data for id: ${id}; message: ${JSON.stringify(message)}`);
  } finally {
    dispatch(setVideoSeriesContentLoading(false));
  }
};
