import mt from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import debounce from 'lodash.debounce';
import { getSelectedText, roundFloat } from 'utils/helpers';
import { NEWS_COPY_THRESHOLD, DOWNLOAD_DEBOUNCE_DELAY } from 'utils/constants';
import { trackCustomEvent } from 'utils/analytics';
import { isHardcapped, auditNewsDownload } from 'store/actions/news';
import { updateForm as updateContactUsForm } from 'store/actions/contactUs';
import { showQuotaExceededModal } from 'store/actions/globalActions';
import ResponsiveImage from 'components/elements/common/ResponsiveImage/ResponsiveImage';
import VideoPlayer from 'components/elements/common/videoplayer/VideoPlayer';

const crops = {
  desktop: '814x-1',
  tablet: '300x-1',
  mobile: '300x-1',
};

const jumpTo = (id) => {
  const to = document.getElementById(id.replace('-ref', ''));
  to.scrollIntoView();
};

const transformFootnote = ({ attribs }) => <sup key={attribs.id} onClick={() => jumpTo(attribs.id)} />;

const transformLink = ({ attribs, children }, index, history) => {
  const textNodes = [];
  children.forEach(({ type, data, name, children: tagChildren }) => {
    if (type === 'text') {
      textNodes.push(data);
    } else if (type === 'tag' && name !== 'meta' && tagChildren.length && tagChildren[0].data) {
      // accounts for times when the inner text is wrapped in a styled tag - ex. <em>Game of Thrones</em>
      textNodes.push(React.createElement(name, { key: `${tagChildren[0].data}_${index}` }, tagChildren[0].data));
    }
  });
  const revision = children.find(({ type, name, attribs: { itemprop } = {} }) => type === 'tag' && name === 'meta' && itemprop === 'suid');
  const optionalProps = {};

  if (revision) {
    optionalProps.onClick = (e) => {
      e.preventDefault();
      history.push(`/news/${revision.attribs.content}`);
    };
  }
  return <a key={`${attribs.href}_${index}`} href={attribs.href} target="_blank" rel="noopener noreferrer" {...optionalProps}>{textNodes}</a>;
};

const transformVideo = (srcId, videos, index) => {
  const video = srcId && videos.find(({ avmmId }) => avmmId === srcId);
  if (!video) {
    return null;
  }

  const videoPlayerOptions = {
    muted: false,
    poster: video.thumb,
    sources: [{ src: video.watermark, type: 'video/mp4' }],
  };

  return (
    <VideoPlayer key={`article_${srcId}_${index}`} {...videoPlayerOptions} clip={video} />
  );
};

const transformImage = (srcId, images, index) => {
  const image = srcId && images.find(({ id }) => id === srcId);
  if (!image) {
    return null;
  }
  return <ResponsiveImage key={`article_${srcId}_${index}`} imageUrl={image.watermark} crops={crops} title={image.title} caption={image.description}/>;
};

const transformMedia = ({ attribs }, index, images, videos) => {
  const type = attribs['data-image-type'];
  const srcId = attribs['data-id'];
  if (type === 'video') {
    return transformVideo(srcId, videos, index);
  }
  return transformImage(srcId, images, index);
};

const filterDivs = ({ attribs }) => {
  if (attribs && attribs.class && attribs.class.includes('news-designed-for-consumer-media')) {
    return null;
  }
};

const stripContactReporter = ({ attribs }) => {
  if (attribs && attribs.class
    && (
      attribs.class.includes('news-rsf-contact-reporter')
      || attribs.class.includes('news-rsf-contact-editor')
      || attribs.class.includes('news-rsf-contact-staff')
      || attribs.class.includes('news-rsf-assists')
    )
  ) {
    return null;
  }
};

const transform = (node, index, images, videos, history) => {
  if (node.type === 'tag') {
    switch (node.name) {
      case 'p':
        return stripContactReporter(node);
      case 'figure':
        return transformMedia(node, index, images, videos);
      case 'a':
        return transformLink(node, index, history);
      case 'sup':
        return transformFootnote(node);
      case 'div':
        return filterDivs(node);
      case 'span':
        return filterDivs(node); // filter out news-designed-for-consumer-media which comes in spans sometimes
      case 'meta':
        return null;
      default:
        break;
    }
  }
};

const transformAttribution = (node, index) => {
  if (node.type === 'tag' && node.name === 'span' && node.attribs && node.attribs.class && node.attribs.class === 'news-dateline') {
    const attribution = node.children.find(child => child.type === 'text');
    if (attribution && attribution.data) {
      return <span className="article--attribution" key={index}>{attribution.data}</span>;
    }
  }
  return null;
};

class Body extends Component {
  constructor(props) {
    super(props);

    this.debounceTrackCopy = debounce(this.trackCopy, DOWNLOAD_DEBOUNCE_DELAY, { leading: true, trailing: false });
  }

  async trackCopy(wordCount) {
    const selectedText = getSelectedText() || '';
    if (!selectedText.length) {
      return;
    }

    const numWords = selectedText.split(' ').filter(word => !!word).length;
    const percentage = roundFloat(numWords / wordCount * 100, 2);

    if (percentage > NEWS_COPY_THRESHOLD) {
      const { user, id } = this.props;
      try {
        const hardCapped = await isHardcapped(user, id);

        if (hardCapped) {
          this.props.updateContactUsForm('topic', 'Add News Access');
          this.props.showQuotaExceededModal();
          return;
        }
      } catch (err) {
        // allow copy and track
        console.warn(err.message);
      }

      await auditNewsDownload(id, percentage);
    }

    trackCustomEvent({
      category: 'news',
      action: 'copy',
    }, {
      numWords,
      percentage,
    });
  }

  render() {
    const {
      header: headerRaw,
      body: bodyRaw,
      trashline: trashlineRaw,
      footer: footerRaw,
      images,
      videos,
      wordCount,
      history,
    } = this.props;

    const body = ReactHtmlParser(bodyRaw && bodyRaw.html || '', {
      transform: (node, index) => transform(node, index, images, videos, history),
    });

    const trashline = ReactHtmlParser(trashlineRaw && trashlineRaw.html || '', {
      transform: (node, index) => transform(node, index, images, videos, history),
    });

    const footer = ReactHtmlParser(footerRaw && footerRaw.html || '', {
      transform: (node, index) => transform(node, index, images, videos, history),
    });

    const attribution = ReactHtmlParser(headerRaw && headerRaw.html || '', {
      transform: (node, index) => transformAttribution(node, index),
    });

    return (
      <article onCopy={() => this.debounceTrackCopy(wordCount)}>
        {attribution}
        {body}
        {trashline && <div className="article--trashline">{trashline}</div>}
        {footer}
        <div className="article--copyright">&copy; {mt().format('YYYY')} Bloomberg L.P.</div>
      </article>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user: user.user,
});

const mapDispatchToProps = {
  updateContactUsForm,
  showQuotaExceededModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);
