import { SET_BOOKMARKS_FILTER } from '../reducers/bookmarks';
import { filterSavedSearches } from './savedsearch';
import { filterPublicationFeeds } from './user';

export const setFilter = filter => (dispatch) => {
  dispatch({
    type: SET_BOOKMARKS_FILTER,
    data: { filter },
  });
};

export const filterBookmarks = filter => (dispatch) => {
  dispatch(filterSavedSearches(filter));
  dispatch(filterPublicationFeeds(filter));
};
