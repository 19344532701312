import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { initialState } from 'store/reducers/search';
import { search, updateFilter, resetFilterGroups } from 'store/actions/search';
import { defaultVideoFilters } from 'store/actions/videos';
import { quickFindReset } from 'store/actions/quickFind';
import { SESSION_FILTERS, ASSET_TYPES } from 'utils/constants';

class QuickFindItem extends Component {
  customizeFilterSearch(filterKey) {
    if (filterKey === ASSET_TYPES.video.media) {
      this.props.defaultVideoFilters();
    }
  }

  handleFilterSearch(filterKey) {
    this.props.resetFilterGroups(SESSION_FILTERS);

    const mediaFilters = Object.keys(initialState.filters.media);
    mediaFilters.map(filter => this.props.updateFilter('media', filter, (filterKey === filter)));
    this.customizeFilterSearch(filterKey);

    this.props.search();
  }

  handleClick() {
    const { item } = this.props;
    this.handleFilterSearch(item.filterKey);
    this.props.quickFindReset();
  }

  render() {
    const { item } = this.props;
    return (
      <Link to="/search" onClick={() => this.handleClick()} className="quickfind--option">
        { item.title }
      </Link>
    );
  }
}

const mapDispatchToProps = {
  quickFindReset,
  updateFilter,
  search,
  resetFilterGroups,
  defaultVideoFilters,
};

export default connect(null, mapDispatchToProps)(QuickFindItem);
