import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { fetchAndDownloadPhoto } from 'store/actions/photos';
import { ASSET_TYPES, DOWNLOAD_DEBOUNCE_DELAY } from 'components/utils/constants';
import PermissionButton from 'components/elements/common/permission/PermissionButton';
import Loading from 'components/elements/common/loading/Loading';
import AddToCollectionButton from 'components/elements/common/permission/AddToCollectionButton';
import NavigationBar from 'elements/common/navigationbar/NavigationBar';
import ShareButton from 'elements/common/permission/ShareButton';

class PhotoCommands extends Component {
  constructor(props) {
    super(props);

    this.debounceDownloadComp = debounce(this.comp, DOWNLOAD_DEBOUNCE_DELAY, { leading: true, trailing: false });
    this.debounceDownloadHighRes = debounce(this.download, DOWNLOAD_DEBOUNCE_DELAY, { leading: true, trailing: false });

    this.state = {
      downloadingWatermark: false,
      downloadingHighRes: false,
    };
  }

  async comp() {
    this.setState({ downloadingWatermark: true });
    const { id, watermark: thumb, width, height } = this.props;
    await this.props.fetchAndDownloadPhoto(id, thumb, true, width > height);
    this.setState({ downloadingWatermark: false });
  }

  async download() {
    this.setState({ downloadingHighRes: true });
    const { id, thumb } = this.props;
    await this.props.fetchAndDownloadPhoto(id, thumb, false);
    this.setState({ downloadingHighRes: false });
  }

  render() {
    const { id, title, downloading } = this.props;
    const { downloadingWatermark, downloadingHighRes } = this.state;

    return (
      <NavigationBar
        className="photo-commands"
        leftSide={(
          <ShareButton mediaType={ASSET_TYPES.image.type} context={ASSET_TYPES.image.context} id={id} name={title}/>
        )}
        center={(
          <React.Fragment>
            <PermissionButton disabled={downloading} permission="photos.watermark" className="button--comp-asset" onClick={() => this.debounceDownloadComp()}>Low Resolution {downloadingWatermark && downloading && <Loading />}</PermissionButton>
            <PermissionButton disabled={downloading} permission="photos.download" className="button--download-asset" onClick={() => this.debounceDownloadHighRes()}>High Resolution {downloadingHighRes && downloading && <Loading />}</PermissionButton>
          </React.Fragment>
        )}
        rightSide={(
          <AddToCollectionButton mediaType={ASSET_TYPES.image.type} id={id} className="button--add-asset" />
        )}
        hideLayoutOptions
      />
    );
  }
}

const mapStateToProps = ({ photos }) => ({
  downloading: photos.downloading,
});

const mapDispatchToProps = {
  fetchAndDownloadPhoto,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotoCommands);
