import React from 'react';
import Pill from './Pill';

import './Pills.scss';

const Pills = ({ className = '', items = [], ...props }) => (
  <div className={`pills ${className}`.trim()}>
    {items.map((item, i) => (<Pill key={`pill_${i}`} className={className} item={item} {...props} />))}
  </div>
);

export default Pills;
