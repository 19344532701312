import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getMenu } from 'store/actions/quickFind';
import SearchBar from './SearchBar';
import UserActions from './UserActions';
import Bookmarks from './Bookmarks';
import logo from '../../../../icons/logo_release.svg';
import Announcement from '../../common/announcement/announcement';
import './Header.scss';

class Header extends Component {
  componentDidMount() {
    if (process.env.FEATURE_CATALOG_ENABLED) {
      this.props.getMenu();
    }
  }

  render() {
    return (
      <div>
      <header className="main-header">
        <div className="main-header-logo">
          <Link className="logo" to="/">
            <img src={logo} alt="Bloomberg Media Distribution"/>
          </Link>
        </div>
        <div className="main-header-searchbar"><SearchBar /></div>
        <div className="main-header-bookmarks"><Bookmarks /></div>
        <div className="main-header-user-actions"><UserActions /></div>
      </header>
      <Announcement />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getMenu,
};

export default connect(null, mapDispatchToProps)(Header);
