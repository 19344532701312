import React, { Component } from 'react';
import { connect } from 'react-redux';
import { removeCollection, clearCollectionsError } from 'store/actions/collections';
import { noop } from 'components/utils/helpers';
import Confirmation from '../savedsearch/ConfirmDeleteModal';

export class RemoveCollectionModal extends Component {
  async onSubmit() {
    const { remove, collection: { id }, handleClose, afterDelete = noop } = this.props;
    if (await remove(id)) {
      handleClose();
      afterDelete();
    }
  }

  cancel() {
    this.props.clearError();
    this.props.handleClose();
  }

  render() {
    const { collection: { name } } = this.props;
    const message = `This will remove collection ${name} and all the assets it contains`;
    return (
      <Confirmation
        className="collection-new"
        title="Remove Collection"
        message={message}
        {...this.props}
        confirm={() => this.onSubmit()}
        handleClose={() => this.cancel()}
      />
    );
  }
}

const mapStateToProps = ({ collections }) => ({
  loading: collections.loading,
  error: collections.error,
});

const mapDispatchToProps = {
  remove: removeCollection,
  clearError: clearCollectionsError,
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveCollectionModal);
