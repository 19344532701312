import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { trackPageView, getGaPublishedAt } from 'utils/analytics';
import { isForbidden, isNotFound, ucFirst, ellipsisSentence } from 'utils/helpers';
import { resetAssetQueue, resetDisplayCollections } from 'store/actions/collections';
import AccessDenied from 'components/elements/common/permission/AccessDenied';
import { TITLE_BRAND } from 'components/utils/constants';
import NotFound from './NotFound';
import SigninContent from './signin/SigninContent';
import ErrorPage from './errorpage/ErrorPage';

export const DESCRIPTION_TAG_MAX_LENGTH = 160;

class Page extends Component {
  componentDidMount() {
    this.props.resetAssetQueue();
    this.props.resetDisplayCollections();
    if (!this.props.noTrack && this.props.finishedAutoSignin) {
      this.trackPageView();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.noTrack && !prevProps.finishedAutoSignin && this.props.finishedAutoSignin) {
      this.trackPageView();
    }
  }

  trackPageView() {
    const {
      id,
      title,
      pageType,
      pageSubType,
      userId,
      firmId,
      firmName,
      publicationId,
      publicationName,
      subStatus,
      bConnectId,
      gaLocation,
      parentId,
      orientation,
      keywords,
      publishedAt,
      sources,
      author,
      language,
      submitAppId,
      people,
      nicode,
      tickers,
      query,
      layout,
      error,
    } = this.props;

    let trackingTitle;
    const errorMessage = error && typeof error === 'object' && error.error || error;
    if (isForbidden(errorMessage)) {
      trackingTitle = 'accessdenied';
    } else if (isNotFound(errorMessage)) {
      trackingTitle = 'pagenotfound';
    } else {
      trackingTitle = title;
    }

    trackPageView({
      sources,
      author,
      firm: firmId,
      firmName,
      publicationId,
      publicationName,
      parentId,
      language,
      location: gaLocation,
      mediaAssetType: null,
      nicode,
      orientation,
      id,
      keywords,
      publishedAt: publishedAt && getGaPublishedAt(publishedAt) || null,
      title: trackingTitle,
      subStatus,
      submitAppId,
      userId,
      people,
      tickers,
      query,
      pageType,
      owner: null,
      bConnectId,
      dateTimeRange: null,
      pageSubType,
      layout,
    });
  }

  render() {
    const { className, children, error, title, userId, pageType, description } = this.props;
    let show;
    const errorMessage = error && typeof error === 'object' && error.error || error;

    if (isForbidden(errorMessage)) {
      show = userId ? <AccessDenied/> : <SigninContent/>;
    } else if (isNotFound(errorMessage)) {
      show = <NotFound />;
    } else if (errorMessage && !title) {
      const formattedError = {
        message: errorMessage,
        correlationId: typeof error === 'object' && error.correlationId || null,
      };
      show = <ErrorPage error={formattedError} />;
    } else {
      show = children;
    }

    let displayTitle = TITLE_BRAND + (title && title !== TITLE_BRAND ? ` - ${ucFirst(title)}` : '');
    if (pageType === 'photo') {
      displayTitle = `${title} - Photo | ${TITLE_BRAND}`;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{displayTitle}</title>
          { window && window.location && window.location.href && <link rel="canonical" href={window.location.href} /> }
          { description && <meta name="description" content={ellipsisSentence(description, DESCRIPTION_TAG_MAX_LENGTH)} /> }
        </Helmet>
        <section className={className}>{show}</section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ user, globalActions }) => ({
  finishedAutoSignin: user.attemptedAutoSignin,
  userId: user.user.id,
  firmId: user.user.firm,
  firmName: user.user.firmName,
  publicationId: user.user.publication,
  publicationName: user.user.publicationName,
  bConnectId: user.user.bconnect && user.user.bconnect.id || null,
  subStatus: user.user.level,
  layout: globalActions.layout,
});

const mapDispatchToProps = {
  resetAssetQueue,
  resetDisplayCollections,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));
