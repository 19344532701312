import React from 'react';
import ShareButton from 'components/elements/common/permission/ShareButton';
import Download from 'components/elements/common/Story/Download';
import AddToCollectionButton from 'components/elements/common/permission/AddToCollectionButton';

import './StoryContentOptions.scss';

const renderButtonLabel = text => (
  <div>
    <div className="label-image" />
    <div className="label-text">{text}</div>
  </div>
);

const StoryContentOptions = props => {
  const { id } = props;
  if (!id) {
    return null;
  }

  return (
    <div className="story-content-options">
      <div className="story-content-options--share-button">
        <ShareButton label={renderButtonLabel('Share')} mediaType={props.type} id={props.id} name={props.title}/>
      </div>
      <div className="story-content-options--collection-button">
        <AddToCollectionButton label={renderButtonLabel('Add')} mediaType={props.type} id={props.id}
                               thumb={props.thumbnail} watermark={props.watermark}/>
      </div>
      <div className="story-content-options--download-button">
        <Download className="story-content-download" type={props.type} id={props.id} thumb={props.thumbnail}
                  watermark={props.watermark}>
          <div className="label-image"/>
        </Download>
      </div>
    </div>
  );
};

export default StoryContentOptions;
