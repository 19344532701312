import React from 'react';
import { connect } from 'react-redux';
import FilterCheckboxes from './FilterCheckboxes';
import FilterGroup from './FilterGroup';
import SourceFilters from './SourceFilters';

const NewsFilters = (props) => {
  const { languages = [], filters, open } = props;

  const languageOptions = filters.languages && languages.map(({ id, name }) => ({
    name: id,
    displayName: name,
    value: filters.languages[id],
    legend: 'Languages',
  })) || [];

  return (
    <FilterGroup className="news" name="News" open={open}>
      <SourceFilters />
      <FilterCheckboxes className="filters--languages" filterKey="languages" options={languageOptions}/>
    </FilterGroup>
  );
};

const mapStateToProps = ({ user, search }) => ({
  languages: user.user.languages,
  filters: search.filters,
  open: search.filters.media.text,
});

export default connect(mapStateToProps)(NewsFilters);
