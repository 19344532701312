import uuid from 'uuid';
import React, { Component } from 'react';
import Page from '../pages/Page';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  componentDidCatch(error) {
    console.error(error);
    this.setState({ error: true });
  }

  render() {
    const { error } = this.state;
    if (error) {
      const err = {
        error: 'Page Error',
        correlationId: uuid.v4(),
      };
      return <Page error={err} />;
    }

    const { children } = this.props;

    return children;
  }
}

export default ErrorBoundary;
