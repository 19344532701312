import React, { Component } from 'react';
import { connect } from 'react-redux';
import { trackCustomEvent } from 'utils/analytics';
import SigninContent from 'components/elements/pages/signin/SigninContent';
import Modal from './Modal';

export class SigninModal extends Component {
  componentDidUpdate(prevProps) {
    const { email, close, open } = this.props;
    if (email !== prevProps.email) {
      close();
    }

    if (open && !prevProps.open) {
      trackCustomEvent({
        category: 'upsell',
        action: 'popup',
        label: 'signin',
      });
    }
  }

  render() {
    const { open, close } = this.props;
    return (
      <Modal className="signin-modal" open={open} handleClose={() => close()}>
        <SigninContent handleClose={() => close()} />
      </Modal>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  email: user.user.email,
});

export default connect(mapStateToProps)(SigninModal);
