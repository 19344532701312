import React from 'react';
import { connect } from 'react-redux';
import FilterList from 'components/elements/common/filterlist/FilterList';
import { filterBookmarks, setFilter } from 'store/actions/bookmarks';

const BookmarksFilter = props => (
  <FilterList className={props.className} filterList={props.filterBookmarks} display={props.display}
              placeholder="Find Bookmark" setFilter={props.setFilter} filter={props.filter}/>
);

const mapStateToProps = ({ savedsearch, user, bookmarks }) => ({
  display: !!savedsearch.savedSearches.length || !!user.user.publicationFeeds.length,
  filter: bookmarks.filter,
});

const mapDispatchToProps = {
  filterBookmarks,
  setFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookmarksFilter);
