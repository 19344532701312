import React from 'react';
import { connect } from 'react-redux';
import { search, resetFilters, setQuery } from 'store/actions/search';

import './Filters.scss';

const handleResetFiltersClick = (props) => {
  props.resetFilters();
  props.setQuery('');
};

const FilterActions = props => (
  <div className="filters--actions">
    <div className="filters--actions-fade" />
    <div className="filters--actions--buttons">
      <button type="reset" onClick={() => handleResetFiltersClick(props)}>Reset Filters</button>
      <button type="button" onClick={props.search}>Apply Filters</button>
    </div>
  </div>
);

const mapDispatchToProps = {
  search,
  resetFilters,
  setQuery,
};

export default connect(null, mapDispatchToProps)(FilterActions);
