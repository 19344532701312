import axios from 'axios';
import get from 'lodash.get';
import { Parser } from 'm3u8-parser';
import { getAxiosError, denormalizeContextFilters, getVideoErrorMsg } from 'components/utils/helpers';
import { trackCustomEvent } from 'components/utils/analytics';
import { ASSET_TYPES } from 'components/utils/constants';
import {
  SET_VIDEO_ERROR,
  SET_VIDEO_LOADING,
  SET_CLIP,
  SET_VIDEO_DOWNLOADING,
  SET_VIDEO_PERMISSIONS,
} from '../reducers/videos';
import { setFilterGroup, updateFilter } from './search';
import { showQuotaExceededModal, showAccessDenied } from './globalActions';

export const VIDEO_TIMEOUT = process.env.VIDEO_TIMEOUT || 10000;
export const VIDEO_CAPTION_TIMEOUT = process.env.VIDEO_CAPTION_TIMEOUT || 5000;

export const setVideoError = error => ({
  type: SET_VIDEO_ERROR,
  data: { error },
});

export const setVideoLoading = (loading = true) => ({
  type: SET_VIDEO_LOADING,
  data: { loading },
});

export const setVideoDownloading = (downloading = true) => ({
  type: SET_VIDEO_DOWNLOADING,
  data: { downloading },
});

export const setClip = clip => ({
  type: SET_CLIP,
  data: { clip },
});

export const setPermissions = permissions => ({
  type: SET_VIDEO_PERMISSIONS,
  data: { permissions },
});

const handleError = (dispatch, err) => {
  const { status, message } = getAxiosError(err);
  dispatch(setVideoError(getVideoErrorMsg(status, message)));
};

export const getPlaylistData = async (url) => {
  if (!url) return {};

  try {
    const parser = new Parser();
    const playlistResponse = await axios.get(url, { timeout: VIDEO_CAPTION_TIMEOUT });
    parser.push(playlistResponse.data);
    parser.end();
    return parser.manifest;
  } catch (err) {
    console.warn('Error retrieving video closed-caption data');
    return {};
  }
};

export const getVideoJsCaption = (playlistUrl, label) => async () => {
  if (!playlistUrl) return {};

  try {
    const captionBaseUrl = new URL(playlistUrl).origin;
    const playlistData = await getPlaylistData(playlistUrl);
    const captionPlaylistUrl = get(playlistData, `mediaGroups.SUBTITLES.subs.${label}.uri`, false);
    const captionPlaylistData = await getPlaylistData(captionPlaylistUrl);
    const captionPath = get(captionPlaylistData, 'segments[0].uri', '');

    return {
      label,
      kind: 'captions',
      language: get(playlistData, `mediaGroups.SUBTITLES.subs.${label}.language`, ''),
      src: captionPath ? (captionBaseUrl + captionPath) : '',
    };
  } catch (err) {
    console.warn('Error assembling video closed-caption data');
    return {};
  }
};

export const defaultVideoFilters = () => async (dispatch, getState) => {
  const { filters: { brands } } = getState().search;
  const { user: { videoBrands } } = getState().user;

  if ((!(brands && Object.keys(brands).length)) && videoBrands && videoBrands.length) {
    const { brands: denormalizedBrands } = denormalizeContextFilters({ brands: videoBrands.map(({ refName, name }) => ({ value: refName, displayValue: name })) });
    dispatch(setFilterGroup('brands', denormalizedBrands));
    videoBrands.forEach(videoBrand => dispatch(updateFilter('video', videoBrand.refName, true)));
  }
};

export const getVideo = id => async (dispatch) => {
  if (!id) return;

  try {
    let playerCaptions = {};
    dispatch(setClip({}));
    dispatch(setVideoLoading());
    const { data } = await axios.get(`/api/videos/${id}`, { timeout: VIDEO_TIMEOUT });

    if (data.subtitles && data.subtitles.url) {
      playerCaptions = await dispatch(getVideoJsCaption(data.subtitles.url, 'English'));
    }

    dispatch(setClip({ ...data, playerCaptions }));
  } catch (err) {
    dispatch(handleError(dispatch, err));
  } finally {
    dispatch(setVideoLoading(false));
  }
};

export const getVideoPermissions = id => async (dispatch) => {
  if (!id) return;

  let newPermissions = { renditions: {}, permissions: {}, subscription: {} };
  try {
    const { data } = await axios.get(`/api/videos/${id}/permissions`, { timeout: VIDEO_TIMEOUT });
    newPermissions = data;
  } catch (e) {
    console.error('Error getting video permissions');
  }
  dispatch(setPermissions(newPermissions));
  return newPermissions;
};

export const checkUserAndDownload = ({ id, specs, downloadFunc }) => async (dispatch, getState) => {
  const { permissions: videoPermissions } = getState().videos;
  const permissions = videoPermissions.permissions || /* istanbul ignore next */ { download: false };

  if (!specs.paid) {
    dispatch(setVideoDownloading());
    downloadFunc();
  } else if (permissions.download) {
    const { subscription: { hasRemainingQuota } } = await dispatch(getVideoPermissions(id));
    if (hasRemainingQuota) {
      dispatch(setVideoDownloading());
      downloadFunc();
    } else {
      await dispatch(showQuotaExceededModal());
    }
  } else {
    dispatch(showAccessDenied());
  }
};

export const postVideoDownload = (id, url, specs) => async (dispatch, getState) => {
  try {
    const { permissions: { subscription } } = getState().videos;

    const extension = url.split('?').shift().split('.').pop();
    const { label, paid, revision } = specs;
    // would have been better to have 2 attributes: the asset-version and a boolean to indicate if it is  paid or not
    const assetVersion = paid ? 'paid' : label;
    await axios.post('/api/audits/download', {
      assetId: id,
      assetType: ASSET_TYPES.video.type,
      assetVersion,
      assetData: {
        subscription,
      },
      extension,
      revision,
    }, {
      timeout: VIDEO_TIMEOUT,
    });
    trackCustomEvent({
      category: ASSET_TYPES.video.type,
      action: 'download',
    }, {
      id,
      mediaAssetType: assetVersion,
    });
  } catch (err) {
    const { message } = getAxiosError(err);
    console.error(`video download audit failed; video: ${id}; message: ${JSON.stringify(message)}`);
  } finally {
    dispatch(setVideoDownloading(false));
  }
};
