import React from 'react';
import { connect } from 'react-redux';
import { addNewSavedSearch, clearSavedSearchError } from 'store/actions/savedsearch';

import NewItemModal from '../newitemmodal/NewItemModal';

export const NewSavedSearchModal = props => (
  <NewItemModal className="savedsearch-new" label="Saved Search" createItem={props.createSavedSearch} {...props} />
);

const mapStateToProps = ({ savedsearch }) => ({
  loading: savedsearch.loading,
  error: savedsearch.error,
});

const mapDispatchToProps = {
  createSavedSearch: addNewSavedSearch,
  clearError: clearSavedSearchError,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSavedSearchModal);
