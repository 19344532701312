import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './Footer.scss';

class Footer extends Component {
  render() {
    return (
      <footer className="main-footer">
        <div className="main-footer__links">
          <a href="https://www.bloomberg.com/company/" target="_blank" rel="noopener noreferrer">About</a>
          <a href="https://www.bloomberg.com/notices/tos/" target="_blank" rel="noopener noreferrer">Terms</a>
          <a href="https://www.bloomberg.com/notices/privacy/" target="_blank" rel="noopener noreferrer">Privacy</a>
          <a href="https://www.bloomberg.com/distribution/faq/" target="_blank" rel="noopener noreferrer">FAQ</a>
          <Link to="/contact-us" className="contact-sales">Support</Link>
        </div>
        <div className="main-footer__copyright">&copy; Bloomberg LP All rights reserved</div>
      </footer>
    );
  }
}

export default Footer;
