import React, { Component } from 'react';
import { connect } from 'react-redux';
import { removeAssetsFromCollection, clearCollectionError } from 'store/actions/collection';
import Confirmation from '../savedsearch/ConfirmDeleteModal';

export class RemoveAssetsModal extends Component {
  async onSubmit() {
    const { collection: { id }, handleClose } = this.props;
    if (await this.props.remove(id)) {
      handleClose();
    }
  }

  cancel() {
    this.props.clearError();
    this.props.handleClose();
  }

  render() {
    const { collection: { name }, numberOfAssets } = this.props;
    const itemOrItems = 'item'.concat(numberOfAssets > 1 ? 's' : '');
    const message = `This will remove ${numberOfAssets} ${itemOrItems} from collection ${name}`;
    return (
      <Confirmation
        className="collection-new"
        title="Remove Items"
        message={message}
        {...this.props}
        confirm={() => this.onSubmit()}
        handleClose={() => this.cancel()}
      />
    );
  }
}

const mapStateToProps = ({ collection }) => ({
  loading: collection.loading,
  error: collection.error,
});

const mapDispatchToProps = {
  remove: removeAssetsFromCollection,
  clearError: clearCollectionError,
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveAssetsModal);
