import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  showComingSoonModal,
  showShareItemModal,
  setSharingDescriptor,
} from 'store/actions/globalActions';
import PermissionButton from './PermissionButton';
import { PERMISSIONS } from '../../../utils/constants';

import './ShareButton.scss';

class ShareButton extends Component {
  openShareDialog() {
    const { id, name, mediaType, userLevel } = this.props;

    this.props.setSharingDescriptor({
      item: { id, name },
      mediaType,
      userLevel,
    });
    this.props.showShareItemModal();
  }

  render() {
    const { className, mediaType, label, name } = this.props;
    // allow empty labels
    const buttonLabel = label === undefined ? 'Share' : label;
    return (
      <PermissionButton permission={PERMISSIONS.share} mediaType={mediaType}
                        className={`share-button ${className || 'button--share-asset'}`}
                        onClick={() => this.openShareDialog()} ariaLabel={`Share ${name}`}>
        {buttonLabel}
      </PermissionButton>
    );
  }
}

const mapStateToProps = ({ globalActions }) => ({
  failedSharingRecipients: globalActions.failedSharingRecipients,
});

const mapDispatchToProps = {
  showComingSoonModal,
  showShareItemModal,
  setSharingDescriptor,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareButton);
