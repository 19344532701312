import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { downloadNews } from 'store/actions/news';
import Loading from 'components/elements/common/loading/Loading';
import AddToCollectionButton from 'components/elements/common/permission/AddToCollectionButton';
import PermissionButton from 'components/elements/common/permission/PermissionButton';
import ShareButton from 'elements/common/permission/ShareButton';
import NavigationBar from 'elements/common/navigationbar/NavigationBar';
import MultifileDownload from 'components/elements/common/modal/multifiledownload/MultifileDownload';

import { ASSET_TYPES, DOWNLOAD_DEBOUNCE_DELAY } from '../../../utils/constants';

class NewsCommands extends Component {
  constructor(props) {
    super(props);

    this.debounceDownload = debounce(this.download, DOWNLOAD_DEBOUNCE_DELAY, { leading: true, trailing: false });

    this.state = {
      downloading: false,
      downloadAllOpen: false,
    };
  }

  openDownloadAll() {
    this.setState({ downloadAllOpen: true });
  }

  closeDownloadAll() {
    this.setState({ downloadAllOpen: false });
  }

  setDownloading(downloading) {
    this.setState({ downloading });
  }

  download() {
    const { id } = this.props;
    this.setDownloading(true);
    this.props.downloadNews(id);
    this.setDownloading(false);
  }

  render() {
    const { title, revision, id, attachments = [], loading, permissions = { news: {} } } = this.props;
    const { downloading, downloadAllOpen } = this.state;
    const files = [
      { type: ASSET_TYPES.news.type, id: id, title },
      ...attachments,
    ];
    const storyPermissions = permissions.news[revision] || {};

    return (
      <React.Fragment>
        <NavigationBar
          className="news--commands"
          leftSide={(
            <ShareButton mediaType={ASSET_TYPES.news.type} id={revision} name={title} />
          )}
          center={(
            <React.Fragment>
              <PermissionButton permission={`news.${revision}.news.download`} mediaType={ASSET_TYPES.news.type} className="button--download-asset" onClick={() => this.debounceDownload()} disabled={downloading}>Download Article</PermissionButton>
              <button type="button" className="button--download-asset download-all-media" onClick={() => this.openDownloadAll()}>Download All Media</button>
            </React.Fragment>
          )}
          rightSide={(
            <AddToCollectionButton mediaType={ASSET_TYPES.news.type} id={revision} className="button--add-asset" />
          )}
          hideLayoutOptions
        />
        <MultifileDownload open={downloadAllOpen} close={() => this.closeDownloadAll()} files={files} permissions={storyPermissions} revision={revision} isAttachment={true} />
        {loading && <Loading />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ news, user }) => ({
  loading: news.loading,
  permissions: user.user.permissions,
});

const mapDispatchToProps = {
  downloadNews,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsCommands);
