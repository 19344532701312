import React from 'react';
import { connect } from 'react-redux';
import { isActiveSession } from 'store/actions/user';
import {
  closeRestrictions as globalActionsCloseRestrictions,
  closeCollections as globalActionsCloseAddCollection,
  closeComingSoonModal as globalActionsCloseComingSoon,
  closeBookmarksModal as globalActionsCloseBookmarks,
  closeQuotaExceededModal as globalActionsCloseQuotaExceededModal,
  closeShareItemModal as globalCloseShareItemModal,
  closeAssetNotAvailableModal as globalCloseAssetNotAvailableModal,
  closeAnnouncementModal as globalCloseAnnouncementModal,
} from 'store/actions/globalActions';

import AccessDeniedModal from './modal/AccessDeniedModal';
import SigninModal from './modal/SigninModal';
import CollectionsModal from './modal/collections/CollectionsModal';
import ComingSoonModal from './modal/ComingSoonModal';
import ShareItemModal from './modal/ShareItemModal';
import BookmarksModal from './modal/bookmarks/BookmarksModal';
import QuotaExceededModal from './modal/quota/QuotaExceededModal';
import AssetNotAvailableModal from './modal/AssetNotAvailableModal';
import Notification from './notification/Notification';
import AnnouncementModal from './modal/announcementModal/announcementModal';

const signedInActions = (
  {
    showCollectionsModal,
    closeCollections,
    showAccessDenied,
    showComingSoon,
    closeComingSoonModal,
    closeRestrictions,
    showBookmarksModal,
    closeBookmarksModal,
    showQuotaExceededModal,
    closeQuotaExceededModal,
    showShareItemModal,
    closeShareItemModal,
    showAssetNotAvailableModal,
    closeAssetNotAvailableModal,
    showAnnouncementModal,
    closeAnnouncementModal,
  }) => (
  <React.Fragment>
    <AccessDeniedModal open={showAccessDenied} handleClose={closeRestrictions} />
    <CollectionsModal open={showCollectionsModal} handleClose={closeCollections}/>
    <ComingSoonModal open={showComingSoon} close={closeComingSoonModal}/>
    <BookmarksModal open={showBookmarksModal} handleClose={closeBookmarksModal}/>
    <ShareItemModal open={showShareItemModal} handleClose={closeShareItemModal}/>
    <QuotaExceededModal open={showQuotaExceededModal} close={closeQuotaExceededModal} />
    <AssetNotAvailableModal open={showAssetNotAvailableModal} handleClose={closeAssetNotAvailableModal} />
    <Notification />
    <AnnouncementModal open={showAnnouncementModal} handleClose={closeAnnouncementModal} />
  </React.Fragment>
);
const publicActions = ({ showSignin, showComingSoon, closeRestrictions, closeComingSoonModal, showAnnouncementModal, closeAnnouncementModal }) => (
  <React.Fragment>
    <SigninModal open={showSignin} close={closeRestrictions}/>
    <ComingSoonModal open={showComingSoon} close={closeComingSoonModal}/>
    <Notification />
    <AnnouncementModal open={showAnnouncementModal} handleClose={closeAnnouncementModal} />
  </React.Fragment>
);

export const GlobalActions = (props) => {
  if (props.signedIn) {
    return signedInActions(props);
  }
  return publicActions(props);
};

const mapStateToProps = ({ globalActions, user }) => ({
  signedIn: !!user.user.email && isActiveSession(),
  showSignin: globalActions.signin.showSignin,
  showAccessDenied: globalActions.showAccessDenied,
  showCollectionsModal: globalActions.showCollectionsModal,
  showComingSoon: globalActions.showComingSoon,
  showBookmarksModal: globalActions.showBookmarksModal,
  showQuotaExceededModal: globalActions.showQuotaExceededModal,
  showShareItemModal: globalActions.showShareItemModal,
  showAssetNotAvailableModal: globalActions.showAssetNotAvailableModal,
  showAnnouncementModal: globalActions.showAnnouncementModal,
});

const mapDispatchToProps = {
  closeRestrictions: globalActionsCloseRestrictions,
  closeCollections: globalActionsCloseAddCollection,
  closeComingSoonModal: globalActionsCloseComingSoon,
  closeBookmarksModal: globalActionsCloseBookmarks,
  closeQuotaExceededModal: globalActionsCloseQuotaExceededModal,
  closeShareItemModal: globalCloseShareItemModal,
  closeAssetNotAvailableModal: globalCloseAssetNotAvailableModal,
  closeAnnouncementModal: globalCloseAnnouncementModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalActions);
