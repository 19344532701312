import React, { memo } from 'react';
import mt from 'moment-timezone';
import { Link } from 'react-router-dom';
import { DATETIME_DISPLAY } from 'components/utils/constants';
import { formatResultURL } from 'components/utils/helpers';
import ResponsiveImage from 'components/elements/common/ResponsiveImage/ResponsiveImage';
import ShareButton from 'components/elements/common/permission/ShareButton';
import AddToCollectionButton from 'components/elements/common/permission/AddToCollectionButton';
import RemoveFromCollectionButton from 'components/elements/common/permission/RemoveFromCollectionButton';
import Download from 'components/elements/common/Story/Download';

export const words = (type, wordCount) => (type === 'news' ? `${wordCount} words` : '');

const crops = {
  desktop: '140x140',
  tablet: '140x140',
  mobile: '140x140',
};

export const StoryListItem = (props) => {
  const { className = 'story', title, id, type, wire, publishedDate, wordCount, duration, thumb, summary, caption, watermark, showCollectionRemove } = props;
  const formattedTime = mt(publishedDate).format(DATETIME_DISPLAY);
  const CollectionButtonElement = showCollectionRemove ? RemoveFromCollectionButton : AddToCollectionButton;

  return (
    <div className={`item--list ${className} ${type}`} >
      <Link className={`item--list--image ${className}--image`} to={formatResultURL(type, id)} aria-label={title || id}>
        {thumb && <ResponsiveImage imageUrl={thumb} title={title} crops={crops} />}
      </Link>
      <i className={`item--list--icon ${type}`} />
      <span className={`item--list--extra-info ${className}--extra-info`}>
        <time>{formattedTime}</time>
        { duration && <span>{duration}</span> }
        { wordCount && wordCount > 0 && <span>{words(type, wordCount)}</span> }
      </span>
      <span className={`item--list--wire ${className}--wire`}>{wire}</span>
      <span className={`item--list--options ${className}--options`}>
        <ShareButton className="button button--share" mediaType={type} id={id} name={title} />
        <CollectionButtonElement className="button button--add" mediaType={type} id={id} thumb={thumb} watermark={watermark} multiple />
        <Download className={`button ${className}-download`} id={id} type={type} thumb={thumb} watermark={watermark} />
      </span>
      <Link className={`item--list--title ${className}--title`} to={formatResultURL(type, id)}>
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </Link>
      <Link className={`item--list--summary ${className}--summary`} to={formatResultURL(type, id)}>
        {(summary || caption) && <span dangerouslySetInnerHTML={{ __html: summary || caption }} />}
      </Link>
    </div>
  );
};

export default memo(StoryListItem, /* istanbul ignore next */ (prevProps, nextProps) => prevProps.id === nextProps.id);
