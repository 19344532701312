import React from 'react';
import './AutoRefresh.scss';

const AutoRefresh = ({ delta, onViewUpdates }) => (
  <div className="auto-refresh">
    <span className="auto-refresh--message">{`${delta} new item${delta > 1 ? 's' : ''}`}</span>
    <button className="auto-refresh--button" onClick={onViewUpdates}>View</button>
  </div>
);

export default AutoRefresh;
