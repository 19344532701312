import React, { Component } from 'react';
import { TAGLINE } from 'components/utils/constants';
import { Link } from 'react-router-dom';
import { SEARCH_ROUTE } from '../../../../../routes';

import './Tagline.scss';

class Tagline extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
    };
  }

  onClose() {
    this.setState({ open: false });
  }

  render() {
    const { open } = this.state;
    return (
      <div className={`tagline ${open ? '' : 'closed'}`}>
        <div className="tagline__wrapper">
          {open && <button className="tagline__close" type="button" onClick={() => this.onClose()} aria-label="close tagline" />}
          <h2>{TAGLINE}</h2>

          <Link className="tagline__actions" to={SEARCH_ROUTE}>
            <span className="tagline__action">
              <i className="tagline__action--search"/>
              <label>Search</label>
              <p>news, photo and video</p>
            </span>

            <span className="tagline__action--angle" />

            <span className="tagline__action">
              <i className="tagline__action--add"/>
              <label>Add</label>
              <p>to your collection</p>
            </span>

            <span className="tagline__action--angle" />

            <span className="tagline__action">
              <i className="tagline__action--download"/>
              <label>Download</label>
              <p>content for publishing</p>
            </span>
          </Link>
        </div>
      </div>
    );
  }
}

export default Tagline;
