import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import ErrorView from 'components/elements/common/ErrorView';
import { verifyUnlockKey } from 'store/actions/user';
import Page from '../Page';

export class VerifyUnlock extends Component {
  componentDidMount() {
    const { match: { params: { bconnectId, unlockKey } } } = this.props;
    this.props.verifyUnlockKey(bconnectId, unlockKey);
  }

  render() {
    const { loading, error, accountUnlocked } = this.props;

    return (
      <Page className="unlock" title="unlock-verify" pageType="unlock-verify">
          {loading && <p>Please wait while we unlock your account</p>}
            {accountUnlocked && !error
              ? <p>Your account is now unlocked. You may now <Link to="/login">sign-in</Link> or <Link to="/reset-password">reset your password</Link></p>
              : <ErrorView error={error} />
            }
          <p>Questions?</p>
          <p>Please let us know if there is anything we can help you with by emailing <a href="mailto:newsservice@bloomberg.net?Subject=Support%20Request" target="_top">newsservice@bloomberg.net</a>.</p>
      </Page>
    );
  }
}

const mapStateToProps = ({ user: { loading, error, accountUnlocked } }) => ({
  loading,
  error,
  accountUnlocked,
});

const mapDispatchToProps = {
  verifyUnlockKey,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VerifyUnlock));
