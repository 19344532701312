import Activation from 'components/elements/pages/activation/Activation';
import Homepage from 'components/elements/pages/homepage/Homepage';
import Collections from 'components/elements/pages/collections/Collections';
import News from 'components/elements/pages/news/News';
import Photo from 'components/elements/pages/photo/Photo';
import Registration from 'components/elements/pages/registration/Registration';
import ResetPassword from 'components/elements/pages/resetpassword/Resetpassword';
import Search from 'components/elements/pages/search/Search';
import Signin from 'components/elements/pages/signin/Signin';
import ForgotPassword from 'components/elements/pages/forgotPassword/ForgotPassword';
import CollectionDetails from 'components/elements/pages/collection/CollectionDetails';
import UnlockForm from 'components/elements/pages/unlock/UnlockForm';
import VerifyUnlock from 'components/elements/pages/unlock/VerifyUnlock';
import Usage from 'components/elements/pages/usage/Usage';
import UserPreferences from 'components/elements/pages/userPreferences/UserPreferences';
import ContactUs from 'components/elements/pages/contactUs/ContactUs';
import Video from 'components/elements/pages/video/Video';
import VideoSeriesCatalog from 'components/elements/pages/video/seriesCatalog/VideoSeriesCatalog';
import VideoSeriesContent from 'components/elements/pages/video/seriesContent/VideoSeriesContent';
import TopicPage from 'components/elements/pages/topics/TopicPage';

export const SEARCH_ROUTE = '/search';

const routes =  [
  {
    path: '/',
    exact: true,
    component: Homepage,
  },
  {
    path: '/activate/:csId/:bconnectId/:validationKey',
    component: Activation,
  },
  {
    path: '/collections/:id',
    component: CollectionDetails,
  },
  {
    path: '/collections',
    component: Collections,
  },
  {
    path: '/images/:id',
    component: Photo,
  },
  {
    path: '/login',
    component: Signin,
  },
  {
    path: '/news/:id',
    component: News,
  },
  {
    path: '/registration/:publicationToken',
    component: Registration,
  },
  {
    path: '/registration',
    component: Registration,
  },
  {
    path: '/reset-password/:userId/:key',
    component: ResetPassword,
  },
  {
    path: '/reset-password',
    component: ForgotPassword,
  },
  {
    path: '/search/:id?',
    component: Search,
  },
  {
    path: '/feeds/:feedId?',
    component: Search,
  },
  {
    path: '/unlock/:bconnectId/:unlockKey',
    component: VerifyUnlock,
  },
  {
    path: '/unlock',
    component: UnlockForm,
  },
  {
    path: '/usage',
    component: Usage,
  },
  {
    path: '/preferences',
    component: UserPreferences,
  },
  {
    path: '/contact-us',
    component: ContactUs,
  },
  {
    path: '/videos/series/catalog/:type',
    component: VideoSeriesCatalog,
  },
  {
    path: '/videos/series/content/:id',
    component: VideoSeriesContent,
  },
  {
    path: '/videos/:id',
    component: Video,
  },
  {
    path: '/topics/:topic',
    component: TopicPage,
  },
];

export default routes;
