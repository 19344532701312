export const SET_VIDEO_ERROR = 'redux/videos/SET_VIDEO_ERROR';
export const SET_VIDEO_LOADING = 'redux/videos/SET_VIDEO_LOADING';
export const SET_CLIP = 'redux/videos/SET_CLIP';
export const SET_VIDEO_DOWNLOADING = 'redux/videos/SET_VIDEO_DOWNLOADING';
export const SET_VIDEO_PERMISSIONS = 'redux/videos/SET_VIDEO_PERMISSIONS';
export const SET_USER_HAS_REMAINING_VIDEO_QUOTA = 'redux/videos/SET_USER_HAS_REMAINING_VIDEO_QUOTA';

export const initialState = {
  error: false,
  loading: false,
  clip: {},
  downloading: false,
  permissions: {},
};

const videos = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIDEO_ERROR:
      return {
        ...state,
        error: action.data.error,
      };
    case SET_VIDEO_LOADING:
      return {
        ...state,
        loading: action.data.loading,
      };
    case SET_CLIP:
      return {
        ...state,
        error: false,
        loading: false,
        clip: action.data.clip,
      };
    case SET_VIDEO_DOWNLOADING:
      return {
        ...state,
        downloading: action.data.downloading,
      };
    case SET_VIDEO_PERMISSIONS:
      return {
        ...state,
        permissions: action.data.permissions,
      };
    default:
      return state;
  }
};

export default videos;
