import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchCollections } from 'store/actions/collections';
import { getCollection, resetCollection } from 'store/actions/collection';
import Items from 'components/elements/common/Items/Items';
import { noop } from 'components/utils/helpers';
import RemoveCollectionModal from 'components/elements/common/modal/collections/RemoveCollectionModal';
import ManageCollectionModal from 'components/elements/common/modal/collections/ManageCollectionModal';
import MultifileDownload from 'components/elements/common/modal/multifiledownload/MultifileDownload';
import ErrorView from 'elements/common/ErrorView';
import Page from '../Page';
import CollectionsNav from './CollectionsNav';
import CollectionGridItem from './CollectionGridItem';

import './Collections.scss';

const LAYOUT = 'grid';

class Collections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      removeCollectionOpen: false,
      manageCollectionOpen: false,
      downloadAllOpen: false,
      collection: {},
    };
  }

  componentDidMount() {
    this.props.fetchCollections();
  }

  componentDidUpdate(prevProps) {
    const { signedIn } = this.props;
    if (signedIn !== prevProps.signedIn) {
      this.props.fetchCollections();
    }
  }

  openRemoveCollectionModal(collection) {
    this.setState({ collection, removeCollectionOpen: true });
  }

  openManageCollectionModal(collection) {
    this.setState({ collection, manageCollectionOpen: true });
  }

  async openDownloadAllModal(collectionId) {
    this.props.resetCollection();
    this.props.getCollection(collectionId);
    this.setState({ collection: this.props.collection, downloadAllOpen: true });
  }

  closeModal() {
    this.setState({
      collection: {},
      removeCollectionOpen: false,
      manageCollectionOpen: false,
      downloadAllOpen: false,
    });
  }

  render() {
    const { collection, removeCollectionOpen, manageCollectionOpen, downloadAllOpen } = this.state;
    const { error, collections, loading, userPermissions = {}, collection: { assets = [] }, collectionLoading } = this.props;
    const downloadPermissions = {
      image: userPermissions.photos,
      ...userPermissions,
    };

    return (
      <Page className="collections" title="collections" pageType="collections" error={error}>
        <h2>My Collections</h2>
        <CollectionsNav />

        {error && <ErrorView className="collections-error" error={error} />}

        <Items
          className={`collections-list ${LAYOUT}`}
          loading={loading}
          results={collections}
          hasMore={false}
          totals={collections.length}
          loadMore={noop}
          defaultLayout={LAYOUT}
          GridItem={
            <CollectionGridItem
              openDeleteModal={aCollection => this.openRemoveCollectionModal(aCollection)}
              openManageCollection={aCollection => this.openManageCollectionModal(aCollection)}
              openDownloadAllModal={collectionId => this.openDownloadAllModal(collectionId)}
            />}
        />

        <RemoveCollectionModal open={removeCollectionOpen} collection={collection} handleClose={() => this.closeModal()}/>
        <ManageCollectionModal open={manageCollectionOpen} collection={collection} handleClose={() => this.closeModal()}/>
        <MultifileDownload className="button--download-collection" open={downloadAllOpen} close={() => this.closeModal()} files={assets} permissions={downloadPermissions} loading={collectionLoading} />
      </Page>
    );
  }
}

const mapStateToProps = ({ collections, collection, user }) => ({
  collection: collection.collection,
  collectionLoading: collection.loading,
  userPermissions: user.user.permissions,
  signedIn: !!user.user.id,
  loading: collections.loading,
  error: collections.error,
  collections: collections.displayCollections,
});

const mapDispatchToProps = {
  fetchCollections,
  getCollection,
  resetCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(Collections);
