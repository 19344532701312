import React from 'react';
import RemoveFromCollectionButton from 'components/elements/common/permission/RemoveFromCollectionButton';
import Download from 'components/elements/common/Story/Download';
import ShareButton from 'elements/common/permission/ShareButton';
import { PERMISSIONS } from '../../../utils/constants';

const CollectionDetailsActions = ({ className, permission, id, name, type, thumb, watermark, showFooterOptions }) => (
  <React.Fragment>
    <ShareButton className="button button--share" mediaType={type} id={id} name={name}/>
    {(permission === PERMISSIONS.edit || permission === PERMISSIONS.own)
    && <RemoveFromCollectionButton mediaType={type} id={id} className="button button--remove"/>}
    <Download className={`button ${className}-download`} id={id} type={type} thumb={thumb} watermark={watermark}
              parentIsOpen={showFooterOptions}/>
  </React.Fragment>
);

export default CollectionDetailsActions;
