import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getOrFetchPhoto, setPhoto } from 'store/actions/photos';
import ResponsiveImage from 'components/elements/common/ResponsiveImage/ResponsiveImage';
import Loading from 'components/elements/common/loading/Loading';
import ErrorView from 'elements/common/ErrorView';
import { getPhotoTrackingDetails } from 'utils/analytics';
import PhotoInfo from './PhotoInfo';
import PhotoCommands from './PhotoCommands';
import RelatedPhotos from './relatedPhotos/RelatedPhotos';
import Page from '../Page';

import './Photo.scss';

class Photo extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getPhoto(id);
  }

  componentDidUpdate(prevProps) {
    /* istanbul ignore next */
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.props.getPhoto(this.props.match.params.id);
    }
  }

  componentWillUnmount() {
    this.props.setPhoto({});
  }

  render() {
    const { error, loading, photo } = this.props;
    if ((loading || !photo.id) && !error) {
      return (<Loading />);
    }

    const { title, watermark } = photo;
    const crops = {
      desktop: '-1x-1',
      tablet: '-1x-1',
      mobile: '-1x-1',
    };

    return (
      <Page className="photo" {...getPhotoTrackingDetails(photo)} description={photo.caption} error={error}>
        {error && <ErrorView className="photo-error" error={error} />}
        <ResponsiveImage className="photo-image" imageUrl={watermark} title={title} crops={crops} />
        <PhotoInfo {...photo} />
        <PhotoCommands {...photo} /><br/>
        <RelatedPhotos />
      </Page>
    );
  }
}

const mapStateToProps = ({ photos }) => ({
  loading: photos.loading,
  error: photos.error,
  photo: photos.photo,
});

const mapDispatchToProps = {
  getPhoto: getOrFetchPhoto,
  setPhoto,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Photo));
