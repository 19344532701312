import React, { Component } from 'react';
import { connect } from 'react-redux';
import Autocomplete from 'elements/common/Autocomplete';
import Pills from 'elements/common/pills/Pills';
import { autocomplete } from 'store/actions/autocomplete';
import { updateFilter, removeFilter } from 'store/actions/search';
import { SEARCH_CONTEXTS } from 'utils/constants';

import './PillFilter.scss';

class PillFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      queryValue: '',
    };
  }

  handleSearchChange(queryValue) {
    this.setState({ queryValue });
  }

  onSelect({ value, displayValue }) {
    const { filterKey } = this.props;
    this.props.updateFilter(filterKey, value, { value, displayValue });
    this.setState({ queryValue: '' });
  }

  removePill(value) {
    const { filterKey } = this.props;
    this.props.removeFilter(filterKey, value);
  }

  autocompleteSuggestions(query) {
    this.props.autocomplete(query, SEARCH_CONTEXTS[this.props.filterKey].autocomplete);
  }

  render() {
    const { items = [], name, className, placeholder } = this.props;
    const { queryValue } = this.state;

    return (
      <div className={`pill-filters ${className}`}>
        <Autocomplete
          id={`${className}-autocomplete`}
          ariaLabel={className}
          value={queryValue}
          getSuggestions={query => this.autocompleteSuggestions(query)}
          onChange={(evt, newValue) => this.handleSearchChange(newValue)}
          onSelect={query => this.onSelect(query)}
          placeholder={placeholder}
        />
        <legend>{name}</legend>
        <Pills items={items} onRemove={value => this.removePill(value)}/>
      </div>
    );
  }
}

const mapDispatchToProps = {
  autocomplete,
  updateFilter,
  removeFilter,
};

export default connect(null, mapDispatchToProps)(PillFilter);
