import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import EmailInput from 'components/elements/common/forms/EmailInput';
import PasswordInput from 'components/elements/common/forms/PasswordInput';
import ErrorView from 'elements/common/ErrorView';
import {
  PASSWORD_REGEX,
  PASSWORD_RULES_LANGUAGE,
  SIGNIN_LOCKED_LANGUAGE,
} from 'components/utils/constants';
import { validateForm } from 'components/utils/helpers';
import { signinUser, resetUserError } from 'store/actions/user';

import './Signin.scss';

export class SigninContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        email: '',
        password: '',
      },
      errors: {
        password: false,
      },
    };
  }

  componentDidMount() {
    this.props.resetUserError();
  }

  onChange({ target: { name, value } }) {
    this.setState(prevState => ({ form: { ...prevState.form, [name]: value.trim() } }));
  }

  onPasswordBlur(evt) {
    const password = evt.target.value.trim();
    const error = password.length && !PASSWORD_REGEX.test(password) ? PASSWORD_RULES_LANGUAGE : false;

    this.setState(prevState => ({ errors: { ...prevState.errors, password: error } }));
  }

  onPasswordFocus() {
    this.setState(prevState => ({ errors: { ...prevState.errors, password: false } }));
  }

  validateForm() {
    return validateForm(Object.keys(this.state.form)) && !this.state.errors.password;
  }

  onSubmit(evt) {
    evt.preventDefault();

    if (this.validateForm()) {
      const { form: { email, password } } = this.state;
      this.props.signin(email, password);
    }
  }

  renderSignInError() {
    const { error } = this.props;
    if (error === SIGNIN_LOCKED_LANGUAGE) {
      return <Redirect to="/unlock" />;
    }
    return <ErrorView className="signin-error" error={error} />;
  }

  render() {
    const { errors } = this.state;
    const { error, loading, handleClose, invalidToken } = this.props;
    const header = (invalidToken ? 'Your Session has Expired. Please ' : '').concat('Sign in');
    return (
      <div className="signin" >
        <h1>{header}</h1>
        <form method="post" className="signin-form" onSubmit={evt => this.onSubmit(evt) }>
          <EmailInput label="Email" name="email" placeholder="name@example.com" onChange={evt => this.onChange(evt)} required={true} />
          <PasswordInput label="Password" name="password" onChange={evt => this.onChange(evt)} onFocus={evt => this.onPasswordFocus(evt)} onBlur={evt => this.onPasswordBlur(evt)} required={true} errorMsg={errors.password}/>
          {error && this.renderSignInError()}
          <div>
            <Link to="/reset-password" onClick={handleClose} className="signin-forgot-password">Forgot Password?</Link>
            <button type="submit" onClick={evt => this.onSubmit(evt)} className="button-submit signin-form-submit" disabled={loading}>Sign In</button>
          </div>
        </form>

        <div className="signin-welcome">
          <div>
            <h3>Welcome to the new home for Bloomberg News Service!</h3>
            <p>Timely, trusted, actionable coverage of global markets, industries and economic news.</p>
            <p>Engage your audience now.</p>
          </div>
          <div className="signin-register">
            <h4>Want access?</h4>
            <p>Register now for access to the world&apos;s most timely, trusted and actionable new coverage of global markets, industries and economic news.</p>
            <Link to="/registration" onClick={handleClose} className="button-register signin-register-button">Register</Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user, globalActions }) => ({
  loading: user.loading,
  error: user.error,
  invalidToken: globalActions.signin.invalidToken,
});

const mapDispatchToProps = {
  resetUserError,
  signin: signinUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(SigninContent);
