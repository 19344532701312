import axios from 'axios';
import { getAxiosError } from 'components/utils/helpers';
import {
  SET_TOPICPAGE_LOADING,
  SET_TOPICPAGE_ERROR,
  SET_TOPICPAGE,
} from '../reducers/topicpage';

const TOPICPAGE_TIMEOUT = process.env.HOMEPAGE_TIMEOUT || 10000;

export const setTopicPageLoading = (loading = true) => ({
  type: SET_TOPICPAGE_LOADING,
  data: { loading },
});

export const setTopicpage = topics => ({
  type: SET_TOPICPAGE,
  data: { topics },
});

export const setTopicpageError = error => ({
  type: SET_TOPICPAGE_ERROR,
  data: { error },
});

export const fetchTopicpages = topic => async (dispatch) => {
  dispatch(setTopicpage([]));
  dispatch(setTopicPageLoading());
  try {
    const { data } = await axios.get(`/api/catalogs/${topic}`, { timeout: TOPICPAGE_TIMEOUT });
    dispatch(setTopicpage(data));
  } catch (err) {
    const { message } = getAxiosError(err);
    dispatch(setTopicpageError(message));
  }
};
