import React from 'react';
import { connect } from 'react-redux';
import { toggleFilters } from 'store/actions/search';

const FiltersToggle = ({ toggle }) => (
  <div className="search-nav-filters">
    <button type="button" onClick={toggle}>Filters</button>
  </div>
);

const mapDispatchToProps = dispatch => ({
  toggle: () => dispatch(toggleFilters()),
});

export default connect(null, mapDispatchToProps)(FiltersToggle);
