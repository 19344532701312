export const SET_COLLECTION_LOADING = 'redux/collection/SET_COLLECTION_LOADING';
export const SET_COLLECTION_ERROR = 'redux/collection/SET_COLLECTION_ERROR';
export const SET_COLLECTION = 'redux/collection/SET_COLLECTION';
export const REMOVE_ASSETS_FROM_COLLECTION_DETAILS = 'redux/collections/REMOVE_ASSETS_FROM_COLLECTION_DETAILS';

export const initialState = {
  loading: false,
  collection: {},
  error: false,
};

const collection = (state = initialState, action) => {
  switch (action.type) {
    case SET_COLLECTION_LOADING:
      return {
        ...state,
        error: false,
        loading: action.data.loading,
      };
    case SET_COLLECTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data.error,
      };
    case SET_COLLECTION:
      return {
        ...state,
        error: false,
        loading: false,
        collection: action.data.collection,
      };
    case REMOVE_ASSETS_FROM_COLLECTION_DETAILS:
      return {
        ...state,
        collection: {
          ...state.collection,
          assets: state.collection.assets.filter(({ id, type }) => !action.data.assets.find(({ assetId, assetType }) => id === assetId && type === assetType)),
        },
      };
    default:
      return state;
  }
};

export default collection;
