import mt from 'moment-timezone';
import { PAGE_VIEW_EVENT, PAGE_CUSTOM_EVENT, BASIC_DATE } from './constants';

const updateDataLayer = async (params) => {
  /* istanbul ignore next */
  const dataLayer = typeof window === 'object' && window.dataLayer || [];
  dataLayer.push(params);
};

export const trackEvent = (event, params = {}) => {
  updateDataLayer({
    event,
    ...params,
  });
};

export const trackPageView = (params = {}) => {
  trackEvent(PAGE_VIEW_EVENT, params);
};

export const trackCustomEvent = ({ category, action, label = null }, params = {}) => {
  // events work off of global variables
  window.eventCategory = category;
  window.eventAction = action;
  window.eventLabel = label;

  // track event with custom params
  trackEvent(PAGE_CUSTOM_EVENT, params);

  // reset the global event triggers
  delete window.eventCategory;
  delete window.eventAction;
  delete window.eventLabel;
};

export const getGaPublishedAt = timestamp => mt(timestamp).tz('America/New_York').format();
export const getOrientation = (width, height) => {
  if (width === height) {
    return 'square';
  }
  return width > height ? 'landscape' : 'portrait';
};

export const getPhotoTrackingDetails = (photo) => {
  const {
    id,
    title,
    city,
    state,
    country,
    imageReferenceId,
    width,
    height,
    searchTerms = {},
    publishedDate,
    source = '',
    type,
    submitAppId,
  } = photo;

  let formattedSearchTerms = [];
  Object.keys(searchTerms).forEach((key) => {
    // todo: this shows nicodes and people ids in GA analytics - may be we should not be doing that
    formattedSearchTerms = formattedSearchTerms.concat(searchTerms[key] || []);
  });
  const location = [city, state, country].filter(item => !!item);
  return {
    id,
    title,
    pageType: 'photo',
    gaLocation: location.join(' ').toLowerCase(),
    parentId: imageReferenceId,
    orientation: getOrientation(width, height),
    searchTerms: formattedSearchTerms.join().toLowerCase(),
    publishedAt: publishedDate,
    sources: source.toLowerCase(),
    pageSubType: type,
    submitAppId,
  };
};

export const getObjectTrackingValues = (obj = {}) => Object.keys(obj).map(key => (obj[key] ? key : null)).filter(key => key);
export const getPipedObjectTrackingValues = obj => Object.keys(obj).map(key => `${key}|${obj[key].displayValue}`);
export const getUserMappedValues = (filters, userValues) => [...new Set(
  filters.map(filter => userValues.find(({ id }) => id === filter) || {}).map(({ name }) => name).filter(name => name)),
];
export const formatTrackingDate = date => (date ? mt(date).format(BASIC_DATE) : '*');

export const getSearchTrackingDetails = ({ query = '', filters = {}, user = {}, layout = null, totals = {} }) => {
  const mediaTypes = getObjectTrackingValues(filters.media);
  const orientations = filters.photo && getObjectTrackingValues({
    portrait: filters.photo.portrait,
    landscape: filters.photo.landscape,
  }) || [];
  const usage = filters.photo && getObjectTrackingValues({
    creative: filters.photo.creative,
    editorial: filters.photo.editorial,
  }) || [];
  const topics = filters.topics && getPipedObjectTrackingValues(filters.topics) || [];
  const people = filters.people && getPipedObjectTrackingValues(filters.people) || [];
  const tickers = filters.companies && getPipedObjectTrackingValues(filters.companies) || [];

  const sources = getUserMappedValues(filters.feeds && getObjectTrackingValues(filters.feeds) || [], user.feeds || []);
  const languages = getUserMappedValues(filters.languages && getObjectTrackingValues(filters.languages) || [], user.languages || []);

  const dateRange = filters.dateRange || {};
  const dateTimeRange = `${formatTrackingDate(dateRange.after)}-${formatTrackingDate(dateRange.before)}`;

  const userVideoSubscriptions = user.videoBrands && user.videoBrands.map(({ refName }) => refName) || [];
  const checkedVideoFilters = filters.video && Object.keys(filters.video).filter(k => !!filters.video[k]) || [];
  // only show media counts if we have totals for them and their respective filters are checked
  // only show news and video counts if user has a subscription for them and they're checked
  const newsCount = totals.news && filters.media.text && filters.feeds && Object.values(filters.feeds).some(k => !!k) ? totals.news : 'n/a';
  const photosCount = totals.photos && filters.media.photo ? totals.photos : 'n/a';
  const videosCount = totals.videos && filters.media.video && userVideoSubscriptions.some(k => checkedVideoFilters.includes(k)) ? totals.videos : 'n/a';

  const {
    id: userId,
    firm: firmId,
    firmName,
    publication: publicationId,
    publicationName,
    bconnect: { id: bConnectId = null } = {},
  } = user;

  return {
    userId,
    firmId,
    firmName,
    publicationId,
    publicationName,
    bConnectId,
    language: languages.join(),
    sources: sources.join(),
    location: null,
    mediaAssetType: mediaTypes.join(),
    people: people.join(),
    nicode: topics.join(),
    tickers: tickers.join(),
    keywords: null,
    query,
    orientation: orientations.join(),
    usage: usage.join(),
    dateTimeRange,
    title: 'search',
    pageType: 'search',
    layout,
    newsCount,
    photosCount,
    videosCount,
  };
};

export const trackSearchView = (params) => {
  trackPageView(getSearchTrackingDetails(params));
};

export const getCollectionTrackingDetails = (collection = {}) => ({
  title: collection.name,
  // owner: TODO
  pageType: 'collection',
});

export const getNewsTrackingDetails = (story = {}) => ({
  id: story.revision,
  parentId: story.id,
  title: story.title,
  pageType: 'news',
  author: story.byline,
  language: story.language && story.language.toLowerCase(),
  nicode: story.nicodes && story.nicodes.map(({ id }) => id).join().toLowerCase(),
  tickers: story.terminalTickers && story.terminalTickers.map(({ id }) => id).concat(story.webTickers.map(({ id }) => id)).join().toLowerCase(),
  publishedAt: story.publishedAt,
});

export const getVideoTrackingDetails = clip => ({
  ...clip,
  publishedAt: clip.publishedDate,
});
