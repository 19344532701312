import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './Modal.scss';

class Modal extends Component {
  componentDidMount() {
    this.unlistenHistory = this.props.history.listen((_, action) => {
      // action POP usually meant history back or back button click
      if (action === 'POP') {
        this.props.handleClose();
      }
    });
  }

  componentWillUnmount() {
    this.unlistenHistory();
  }

  render() {
    const { handleClose, open, className = '', theme = 'light', children } = this.props;
    const showHideClassName = open ? 'modal display-block' : 'modal hide';
    const cssClassName = (`${className} ${theme}`).trim();

    return (
      <div className={showHideClassName} onClick={handleClose}>
        <section className={`modal-main ${cssClassName}`.trim()} onClick={e => e.stopPropagation()}>
          <button className="modal-main-close-button" onClick={handleClose} aria-label={`close modal ${cssClassName}`} />
          {children}
        </section>
      </div>
    );
  }
}

export default withRouter(Modal);
