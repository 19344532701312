export const SET_PHOTOS_LOADING = 'redux/photos/SET_PHOTOS_LOADING';
export const SET_PHOTOS_ERROR = 'redux/photos/SET_PHOTOS_ERROR';
export const SET_PHOTO = 'redux/photos/SET_PHOTO';
export const ADD_PHOTO = 'redux/photos/ADD_PHOTO';
export const SET_PHOTO_DOWNLOADING = 'redux/photos/SET_PHOTO_DOWNLOADING';

export const initialState = {
  loading: false,
  downloading: false,
  error: false,
  photos: {},
  photo: {},
};

const photos = (state = initialState, action) => {
  switch (action.type) {
    case SET_PHOTOS_LOADING:
      return {
        ...state,
        photo: {},
        error: false,
        loading: action.data.loading,
      };
    case SET_PHOTOS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data.error,
      };
    case SET_PHOTO:
      return {
        ...state,
        photo: action.data.photo,
      };
    case ADD_PHOTO:
      return {
        ...state,
        error: false,
        loading: false,
        photos: {
          ...state.photos,
          [action.data.photo.id]: action.data.photo,
        },
      };
    case SET_PHOTO_DOWNLOADING:
      return {
        ...state,
        downloading: action.data.downloading,
      };
    default:
      return state;
  }
};

export default photos;
