import axios from 'axios';
import Cookies from 'universal-cookie';
import get from 'lodash.get';
import {
  SIGNIN_UNSUCCESSFUL_LANGUAGE,
  SIGNIN_LOCKED_LANGUAGE,
  SEARCH_HISTORY_STORAGE_KEY,
  SESSION_FILTERS,
  MIN_BOOKMARKS_AUTOCOMPLETE_LEN,
} from 'components/utils/constants';
import { getAxiosError, loginCookieSettings, getLocalStorageItem, setLocalStorageItem, intersectFilters, diffFilters, userFilterReducer } from 'components/utils/helpers';
/* eslint-disable import/no-cycle */
import { setUserFilters, setFilters } from './search';
import { CLEAR_STORE } from '../reducers';
import { fetchHomepage } from './homepage';
import {
  SET_USER_LOADING,
  SET_USER_ERROR,
  SET_USER,
  RESET_USER,
  SET_ACCOUNT_UNLOCKED,
  SET_ATTEMPTED_AUTO_SIGNIN,
  UPDATE_USER_PERMISSIONS,
  RESET_USER_ERROR,
  SET_USER_PREFERENCES,
  SET_PUBLICATION_FEEDS,
  FILTER_DISPLAY_PUBLICATION_FEEDS,
  RESET_DISPLAY_PUBLICATION_FEEDS,
} from '../reducers/user';
import { setLayout, closeRestrictions } from './globalActions';

const SIGNIN_TIMEOUT = process.env.SIGNIN_TIMEOUT || 10000;

export const clearStore = () => ({
  type: CLEAR_STORE,
});

export const setUserLoading = () => ({
  type: SET_USER_LOADING,
});

export const setAccountUnlocked = (error = '') => ({
  type: SET_ACCOUNT_UNLOCKED,
  data: {
    error,
    loading: false,
    accountUnlocked: true,
  },
});

export const setUnlockKey = (error = '') => ({
  type: SET_ACCOUNT_UNLOCKED,
  data: {
    error,
    loading: false,
    unlockKeyGenerated: true,
  },
});

export const setUser = user => ({
  type: SET_USER,
  data: { user },
});

export const resetUser = () => ({
  type: RESET_USER,
});

export const setPublicationFeeds = publicationFeeds => ({
  type: SET_PUBLICATION_FEEDS,
  data: { publicationFeeds },
});

// Does not mean user is actually singed-in (just that auto sign-in request finished)
export const setAttemptedAutoSignIn = attemptedAutoSignin => ({
  type: SET_ATTEMPTED_AUTO_SIGNIN,
  data: { attemptedAutoSignin },
});

export const setUserError = error => ({
  type: SET_USER_ERROR,
  data: { error },
});

export const updateUserPermissions = permissions => ({
  type: UPDATE_USER_PERMISSIONS,
  data: { permissions },
});

export const resetUserError = () => ({
  type: RESET_USER_ERROR,
});

export const setPreferences = preferences => ({
  type: SET_USER_PREFERENCES,
  data: { preferences },
});

export const filterDisplayPublicationFeeds = filter => ({
  type: FILTER_DISPLAY_PUBLICATION_FEEDS,
  data: { filter },
});

export const resetDisplayPublicationFeeds = () => ({
  type: RESET_DISPLAY_PUBLICATION_FEEDS,
});

export const filterPublicationFeeds = filter => (dispatch) => {
  if (filter.length < MIN_BOOKMARKS_AUTOCOMPLETE_LEN) {
    dispatch(resetDisplayPublicationFeeds());
  } else {
    dispatch(filterDisplayPublicationFeeds(filter));
  }
};

export const addFiltersToStorage = (filters) => {
  const persistentFilters = Object.keys(filters).reduce((result, key) => {
    // session filters are not saved in the cookie
    if (!SESSION_FILTERS.includes(key)) {
      /* eslint no-param-reassign: 0 */ // ok in reducer
      result[key] = filters[key];
    }
    return result;
  }, {});

  const searchHistory = getLocalStorageItem(SEARCH_HISTORY_STORAGE_KEY);
  if (!searchHistory.filters) {
    searchHistory.filters = {};
  }
  // feeds, video brands, and languages should not be accumulated but rather replaced with user feeds, brands and languages
  const { feeds: storageFeeds = {}, languages: storageLanguages = {}, brands: storageBrands = {} } = searchHistory.filters;
  const { feeds: userFeeds = {}, languages: userLanguages = {}, brands: userBrands = {} } = persistentFilters;

  searchHistory.filters.feeds = intersectFilters(storageFeeds, userFeeds);
  searchHistory.filters.languages = intersectFilters(storageLanguages, userLanguages);
  searchHistory.filters.brands = intersectFilters(storageBrands, userBrands);

  const combinedFilters = { ...searchHistory.filters, ...persistentFilters, dateRange: {} };
  setLocalStorageItem(SEARCH_HISTORY_STORAGE_KEY, { ...searchHistory, filters: combinedFilters });
  // good pattern to enable testing (V.S. the hacky option that we follow elsewhere of adding a Logger.info/error and asserting args to the Logger)
  return combinedFilters;
};

export const loadStorageFilters = (searchHistory, data) => async (dispatch) => {
  const storageFilters = get(searchHistory, 'filters', {});
  const { feeds: storageFeeds = {}, languages: storageLanguages = {}, brands: storageBrands = {}, video = {} } = storageFilters;
  const { feeds: userFeedsNormalized = [], languages: userLanguagesNormalized = [], videoBrands: userBrandsNormalized = [] } = data || {};
  const userFeeds = userFilterReducer(userFeedsNormalized);
  const userLanguages =  userFilterReducer(userLanguagesNormalized);
  // todo: change video brands to match news feeds so we don't have to customize for videos. The equality to undefined covers new added brands that are not yet in the local storage
  const userBrands = userBrandsNormalized.filter(brand => (video[brand.refName] === true || video[brand.refName] === undefined)).reduce((result, { refName, name }) => {
    result[refName] = { value: refName, displayValue: name };
    return result;
  }, {});

  const { feeds: netStorageFeeds, languages: netStorageLanguages, brands: netStorageBrands } = {
    feeds: intersectFilters(storageFeeds, userFeeds),
    languages: intersectFilters(storageLanguages, userLanguages),
    brands: intersectFilters(storageBrands, userBrands),
  };

  const { feeds: newFeeds, languages: newLanguages, brands: newBrands } = {
    feeds: diffFilters(userFeeds, storageFeeds),
    languages: diffFilters(userLanguages, storageLanguages),
    brands: diffFilters(userBrands, storageBrands),
  };

  const combinedUserFilters = {
    feeds: { ...netStorageFeeds, ...newFeeds },
    languages: { ...netStorageLanguages, ...newLanguages },
    brands: { ...netStorageBrands, ...newBrands },
  };

  dispatch(setFilters(storageFilters));
  dispatch(setUserFilters(combinedUserFilters));
  return { storageFilters, combinedUserFilters };
};

export const signinUser = (email, password) => async (dispatch, getState) => {
  dispatch(setUserLoading());
  const cookies = new Cookies();
  const searchHistory = getLocalStorageItem(SEARCH_HISTORY_STORAGE_KEY);
  try {
    const { data } = await axios.post('/api/signin', { email, password }, { timeout: SIGNIN_TIMEOUT });

    cookies.set('login', data.token, loginCookieSettings);
    delete data.token;

    dispatch(loadStorageFilters(searchHistory, data));
    dispatch(setUser(data));
  } catch (err) {
    const { status, message } = getAxiosError(err);
    let signinError;

    if (status === 401) {
      signinError = SIGNIN_UNSUCCESSFUL_LANGUAGE;
    } else if (status === 423) {
      signinError = SIGNIN_LOCKED_LANGUAGE;

      const { signin: { showSignin } } = getState().globalActions;
      if (showSignin) {
        dispatch(closeRestrictions());
      }
    } else {
      const errorMsg = typeof message === 'object' ? message.error : message;
      signinError = `Error: ${errorMsg}`;
    }

    dispatch(setUserError(signinError));
  }
  dispatch(setAttemptedAutoSignIn(true));
  dispatch(setLayout(searchHistory.layout));
};

export const autoSigninUser = () => async (dispatch) => {
  const cookies = new Cookies();
  const loginCookie = cookies.get('login');
  const searchHistory = getLocalStorageItem(SEARCH_HISTORY_STORAGE_KEY);
  let userData = null;
  try {
    if (loginCookie) {
      const { data } = await axios.post('/api/auth', {}, {
        timeout: SIGNIN_TIMEOUT,
      });
      dispatch(setUser(data));
      userData = data;
    }
  } catch (err) {
    const { status, message } = getAxiosError(err);
    // 404 is returned when a user no longer has a valid cookie, can ignore
    if (status !== 401 && status !== 404) {
      console.error(err);
      dispatch(setUserError(`Error: ${message}`));
    }
  }
  dispatch(loadStorageFilters(searchHistory, userData));
  dispatch(setAttemptedAutoSignIn(true));
  dispatch(setLayout(searchHistory.layout));
};

export const isActiveSession = () => {
  const cookies = new Cookies();
  const loginCookie = cookies.get('login');

  if (loginCookie) {
    return true;
  }

  return false;
};

export const logoutUser = () => async (dispatch) => {
  const cookies = new Cookies();
  cookies.remove('login');
  dispatch(setUser({}));
  dispatch(clearStore());
  dispatch(fetchHomepage());
  await dispatch(setAttemptedAutoSignIn(true));
};

export const updateUserNewsPermissions = (id, storyPermissions) => (dispatch, getState) => {
  const { user: { permissions: currentPermisions = {} } } = getState().user;
  const permissions = {
    ...currentPermisions,
    news: {
      ...currentPermisions.news,
      [id]: storyPermissions,
    },
  };
  dispatch(updateUserPermissions(permissions));
};

export const hasPermission = permission => (_, getState) => {
  if (!permission) {
    return false;
  }

  const { user: { permissions } } = getState().user;
  return get(permissions, permission, false);
};

export const generateUnlockKey = email => async (dispatch) => {
  dispatch(setUserLoading());
  try {
    await axios.post('/api/users/unlock/key', { email }, { timeout: SIGNIN_TIMEOUT });
    dispatch(setUnlockKey());
  } catch (err) {
    const { message } = getAxiosError(err);
    dispatch(setUserError(message));
  }
};

export const verifyUnlockKey = (id, key) => async (dispatch) => {
  dispatch(setUserLoading());
  try {
    await axios.post('/api/users/unlock', { id, key }, { timeout: SIGNIN_TIMEOUT });
    dispatch(setAccountUnlocked());
  } catch (err) {
    const { message } = getAxiosError(err);
    dispatch(setUserError(message));
  }
};

export const updatePreferences = userPrefs => async (dispatch, getState) => {
  const { user: { preferences } } = getState().user;
  const updatedPrefs = { ...preferences, ...userPrefs };
  dispatch(setUserLoading());
  try {
    await axios.put('/api/users/preferences', { preferences: updatedPrefs }, { timeout: SIGNIN_TIMEOUT });
    dispatch(setPreferences(updatedPrefs));
  } catch (err) {
    const { message } = getAxiosError(err);
    dispatch(setUserError(message));
  }
};

export const fetchPublicationFeeds = () => async (dispatch, getState) => {
  const { loading, user: { publicationFeeds, publication: publicationId } } = getState().user;
  if (loading || publicationFeeds.length || !publicationId) {
    return;
  }

  try {
    dispatch(setUserLoading());
    const { data } = await axios.get(`/api/publications/${publicationId}/feeds`, { timeout: SIGNIN_TIMEOUT });
    // some feeds are duplicates which trigger react duplicate key (which cause zoombie UI dups to show on the component)
    const publicationIds = [];
    const uniquePublications = data.filter(({ id }) => {
      if (!publicationIds.includes(id)) {
        publicationIds.push(id);
        return true;
      }
      return false;
    });

    dispatch(setPublicationFeeds(uniquePublications));
  } catch (err) {
    const { message } = getAxiosError(err);
    dispatch(setUserError(message));
  }
};
