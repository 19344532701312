import React from 'react';
import ResponsiveImage from 'components/elements/common/ResponsiveImage/ResponsiveImage';
import VideoPlayer from 'components/elements/common/videoplayer/VideoPlayer';

const Lede = ({ lede, images, videos }) => {
  if (!lede) {
    return null;
  }

  const ledeVideo = videos.find(({ avmmId, bmmrId }) => avmmId === lede.id || bmmrId === lede.id);
  if (ledeVideo) {
    const videoPlayerOptions = {
      muted: false,
      poster: ledeVideo.thumb,
      sources: [{ src: ledeVideo.watermark, type: 'video/mp4' }],
    };

    return (
      <div className="news--lede"><VideoPlayer {...videoPlayerOptions} clip={ledeVideo} /></div>
    );
  }

  const ledeImage = images.find(({ id }) => id === lede.id);
  if (!ledeImage) {
    return null;
  }

  const { watermark, title } = ledeImage;
  const crops = {
    desktop: '814x541',
    tablet: '768x510',
    mobile: '320x213',
  };
  const caption = `${lede.caption ? lede.caption :''}${lede.credit ? `${lede.credit}`:''}`;

  return (
    <ResponsiveImage className="news--lede" caption={caption} imageUrl={watermark} crops={crops} title={title} />
  );
};

export default Lede;
