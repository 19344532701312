import React, { Component } from 'react';
import videojs from 'video.js/dist/video';
import { trackCustomEvent } from 'components/utils/analytics';
import { getVideoPlayedPercentage } from 'components/utils/helpers';
import { ASSET_TYPES } from 'components/utils/constants';

import 'video.js/dist/video-js.css';

class VideoPlayer extends Component {
  constructor(props) {
    super(props);

    this.defaultOptions = {
      controls: true,
      fluid: true,
      muted: true,
      autoplay: false,
      nativeTextTracks: false,
    };
  }

  componentDidMount() {
    const options = { ...this.defaultOptions, ...this.props };
    this.player = videojs(this.videoNode, options);
  }

  componentWillUnmount() {
    if (this.player) {
      const { clip, noTrack } = this.props;
      if (clip && !noTrack) {
        const { id, title } = clip;
        trackCustomEvent({ category: ASSET_TYPES.video.type, action: 'watched', label: title }, {
          id,
          percentage: getVideoPlayedPercentage(this.player),
        });
      }

      this.player.dispose();
    }
  }

  render() {
    const options = { ...this.props };
    return (
      <div data-vjs-player>
        <video ref={ (node) => { this.videoNode = node; }} className="video-js vjs-big-play-centered">
          {
            options && options.captions && options.captions.src
            && <track { ...options.captions } />
          }
        </video>
      </div>
    );
  }
}

export default VideoPlayer;
