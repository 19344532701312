export const SET_BOOKMARKS_FILTER = 'redux/bookmarks/SET_BOOKMARKS_FILTER';

export const initialState = {
  filter: '',
};

const bookmarks = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOOKMARKS_FILTER:
      return {
        ...state,
        filter: action.data.filter,
      };
    default:
      return state;
  }
};

export default bookmarks;
