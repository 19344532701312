import React from 'react';
import './SearchGoButton.scss';

const ShareGoButton = () => (
    <button type="button" className="search-go-button">
      Go
    </button>
);

export default ShareGoButton;
