import React from 'react';
import mt from 'moment-timezone';
import { DATETIME_DISPLAY } from 'components/utils/constants';
import { formatLocation, formatCopyright } from 'components/utils/helpers';
import searchTermsSection from 'components/elements/common/searchterm/SearchTermSection';

const PhotoInfo = ({ id, title, caption, searchTerms, publishedDate, shotDate, creator, copyright, city, state, country }) => {
  const formattedLocation = formatLocation(city, state, country);
  return (
    <div className="photo-info">
      <h1 dangerouslySetInnerHTML={{ __html: title }} />

      <figcaption dangerouslySetInnerHTML={{ __html: caption }} />

      <div className="photo-info-categorization">
        {Object.keys(searchTerms).map(key => searchTermsSection(key, searchTerms, 'photo'))}
      </div>

      <div className="photo-info-information">
        <h4>Information</h4>
        <span>Date shot: {mt(shotDate).format(DATETIME_DISPLAY)}</span>
        <span>Date published: {mt(publishedDate).format(DATETIME_DISPLAY)}</span>
        <span>Id: {id}</span>
        {formattedLocation && <span>Location: {formattedLocation}</span>}
        {creator && <span>Creator: {creator}</span>}
        <span>Copyright: {formatCopyright(copyright)}</span>
      </div>
    </div>
  );
};

export default PhotoInfo;
