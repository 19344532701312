import React, { Component } from 'react';

import './Accordion.scss';

class Accordion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.initializeOpen === undefined ? true : props.initializeOpen,
    };
  }

  toggleOpen() {
    const { open } = this.state;
    this.setState({ open: !open });
  }

  render() {
    const { open } = this.state;
    const { label, children, className, animate } = this.props;
    return (
      <div className={`${className} accordion ${open ? 'expand' : 'collapse'} ${animate ? 'animate' : ''}`.trim()}>
        <summary onClick={() => this.toggleOpen()}>{label}</summary>
        <div className="slide-container">
          <section>{children}</section>
        </div>
      </div>
    );
  }
}

export default Accordion;
