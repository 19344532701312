import React from 'react';
import { ASSET_TYPES } from 'components/utils/constants';
import ShareButton from 'components/elements/common/permission/ShareButton';
import VideoDownloadOptions from 'components/elements/common/Story/VideoDownloadOptions';
import AddToCollectionButton from 'components/elements/common/permission/AddToCollectionButton';

import './VideoContentOptions.scss';

const renderButtonLabel = text => (
  <div>
    <div className="label-image" />
    <div className="label-text">{ text }</div>
  </div>
);

const VideoContentOptions = props => (
  <div className="video-content-options">
    <div className="video-content-options--share-button">
      <ShareButton label={renderButtonLabel('Share')} mediaType={ASSET_TYPES.video.type} id={props.id} name={props.title} />
    </div>
    <div className="video-content-options--collection-button">
      <AddToCollectionButton label={renderButtonLabel('Add')} mediaType={ASSET_TYPES.video.type} id={props.id} />
    </div>
    <div className="video-content-options--download-button">
      <VideoDownloadOptions label={renderButtonLabel('Download')} id={props.id} />
    </div>
  </div>
);

export default VideoContentOptions;
