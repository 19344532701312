import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { initialState } from 'store/reducers/search';
import { setQuery, search, setFilterGroup, resetFilterGroups, updateFilter } from 'store/actions/search';
import { SEARCH_ROUTE } from '../../../../../routes';
import { SEARCH_CONTEXTS, SESSION_FILTERS } from '../../../utils/constants';

const toContextName = (keywordType) => {
  // valid contexts
  if (Object.keys(SEARCH_CONTEXTS).includes(keywordType)) {
    return keywordType;
  }
  // keywords have no context
  return '';
};

class SearchTerm extends Component {
  executeSearch(value, displayValue, mediaType) {
    this.props.resetFilterGroups(SESSION_FILTERS);
    const context = toContextName((this.props.type));
    if (context) {
      this.props.setQuery('');
      this.props.setFilterGroup(context, { [value]: { value, displayValue } });
    } else {
      this.props.setQuery(value);
    }

    if (mediaType) {
      const mediaFilters = Object.keys(initialState.filters.media);
      mediaFilters.map(filter => this.props.updateFilter('media', filter, (mediaType === filter)));
    }
    this.props.search();
  }

  render() {
    const { value, displayValue, mediaType, className } = this.props;
    return (
      <Link className={className} onClick={() => this.executeSearch(value, displayValue, mediaType)} to={SEARCH_ROUTE}>{displayValue || value}</Link>
    );
  }
}

const mapDispatchToProps = {
  setQuery,
  search,
  setFilterGroup,
  resetFilterGroups,
  updateFilter,
};

export default withRouter(connect(null, mapDispatchToProps)(SearchTerm));
