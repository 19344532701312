import React from 'react';
import mt from 'moment-timezone';
import { Link } from 'react-router-dom';
import { DATETIME_DISPLAY } from 'components/utils/constants';
import { formatResultURL, ellipsisSentence } from 'components/utils/helpers';
import GroupActionButton from './GroupActionButton';

import './GridItem.scss';

const SUMMARY_MAX_WORDS = 125; // stay conservative for portrait size images

const GridItem = ({ wire = 'BN', id, revision, title, className, contents, actions, type, publishedDate, summary, caption, group, wordCount, duration }) => (
  <div className={`griditem griditem--${type} ${className}`.trim()}>
    {/* checks GridItem is used in search or collection page */}
    {type !== 'collection' && <div className="griditem-info">
        <span className="griditem-info__wire">{wire}</span>
        <time>
          {mt(publishedDate).tz(mt.tz.guess()).format(DATETIME_DISPLAY)}
          {wordCount && <span> - {wordCount} words</span>}
          {duration && <span> - {duration}</span>}
        </time>
        {group
        && <GroupActionButton className="griditem-info__icon griditem-info__icon--group" group={group}/>
        || <span className={`griditem-info__icon griditem-info__icon--${type}`} />
        }
      </div>
    }
    {contents}
    <div className={`griditem-footer ${className}--footer`}>
      <Link className="griditem-link" to={formatResultURL(type, revision || id)} aria-label={title || id}>
        <span className="griditem-title" dangerouslySetInnerHTML={{ __html: title }} />
        {(summary || caption) && <span className="griditem-summary" dangerouslySetInnerHTML={{ __html: ellipsisSentence(summary || caption, SUMMARY_MAX_WORDS) }} />}
      </Link>
      <div className="griditem-options">
        {actions}
      </div>
    </div>
  </div>
);

export default GridItem;
