import React from 'react';
import NavigationBar from 'elements/common/navigationbar/NavigationBar';
import FiltersToggle from './FiltersToggle';
import CollectionOptions from './CollectionOptions';
import NumResults from './NumResults';

import './SearchNav.scss';

const SearchNav = props => (
  <NavigationBar className="search-nav"
    leftSide={(
      <React.Fragment>
        {!props.hideFilters && <FiltersToggle />}
        <CollectionOptions />
      </React.Fragment>
    )}
    rightSide={(
      <NumResults />
    )}
  />
);

export default SearchNav;
