export const TITLE_BRAND = 'Bloomberg Mercury';
export const SEARCH_HISTORY_STORAGE_KEY = 'searchHistory';
export const PASSWORD_REGEX = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g;
export const PASSWORD_RULES_LANGUAGE = `
Must Include: Passwords must contain at least 8 characters and following types of characters:
Lower case letters (a, b, c) - Upper case letters (A, B, C) - Numbers (1, 2, 3)
`;
export const REGISTRATION_SUCCESS_LANGUAGE = `
  An email has been sent to you to confirm your registration. Please follow the steps in the email.
`;
export const SIGNIN_UNSUCCESSFUL_LANGUAGE = `
  An incorrect username or password has been entered. Please try again.
`;
export const SIGNIN_LOCKED_LANGUAGE = `
  Your account is locked. Please contact support.
`;

export const TAGLINE = 'Home of market-moving stories, images, and videos that power newsrooms around the globe.';

export const PUBLISHED_DATETIME_DISPLAY = 'MMM D, YYYY, h:mm A';
export const DATETIME_DISPLAY = 'M/D/YYYY h:mm a';
export const BASIC_DATE = 'YYYY-MM-DD';
export const EARLIEST_CONTENT_DATE = new Date(2000, 1, 1); // Jan. 1, 2000

export const ASSET_TYPES = {
  // todo we could have done better using consistent naming - FIX
  image: { type: 'image', name: 'Photo', context: 'photos', media: 'photo' },
  news: { type: 'news', name: 'Story', context: 'news', media: 'text' },
  video: { type: 'video', name: 'Video', context: 'videos', media: 'video' },
  collection: { type: 'collection', name: 'Collection', context: 'collections' },
  search: { type: 'search', name: 'Search', context: 'searches' },
};

export const PERMISSIONS = {
  own: 'own',
  edit: 'edit',
  view: 'view',
  share: 'share',
  admin: 'admin',
};

// well known parsing idiom
export /* istanbul ignore next */ const SEARCH_RESULT_LIMIT = JSON.parse(process.env.SEARCH_RESULT_LIMIT || 15);
export /* istanbul ignore next */ const PHOTO_FIXED_RESULT_LIMIT = JSON.parse(process.env.PHOTO_FIXED_RESULT_LIMIT || 111);

// GA
export const PAGE_VIEW_EVENT = 'Track Request - PV';
export const PAGE_CUSTOM_EVENT = 'Track Request - Event';

export const DOWNLOAD_DEBOUNCE_DELAY = 500; // msecs
export const AUTOCOMPLETE_DEBOUNCE_DELAY = 250; // msecs
export const MIN_AUTOCOMPLETE_LEN = 3;
export const MIN_BOOKMARKS_AUTOCOMPLETE_LEN = 2;

export const SEARCH_CONTEXTS = {
  topics: { name: 'topics', autocomplete: 'NI' },
  companies: { name: 'companies', autocomplete: 'company' },
  people: { name: 'people', autocomplete: 'person' },
  series: { name: 'series', autocomplete: 'series' },
  brands: { name: 'brands', autocomplete: 'N/A', persistent: true },
};

export const SESSION_FILTERS = Object.keys(SEARCH_CONTEXTS).filter(key => !SEARCH_CONTEXTS[key].persistent); // more session filters of other types can be added here

export const AUTO_REFRESH_INTERVAL = {
  high: 2,
  medium: 5,
  low: 10,
};

export const LEDE_MODULE_TITLE = 'LEDE';

export const STORY_TYPE = { article: 'article', quicktake: 'quicktake' };

export const VIDEO_RENDITIONS = {
  compressed: { label: 'compressed', displayName: 'mp4', paid: true },
  highres: { label: 'highres', displayName: 'mxf', paid: true },
  pal: { label: 'pal', displayName: 'pal', paid: true },
  lowres: { label: 'watermark', displayName: 'mp4/watermark', paid: false },
};

export const VIDEO_ATTACHMENTS = { label: 'attachment', paid: false };

export const VIDEO_TEXT = {
  subtitles: { label: 'subtitles', displayName: 'subtitles', paid: false },
  transcript: { label: 'transcript', displayName: 'transcript', paid: false },
  thumb: { label: 'thumb', displayName: 'thumbnail', paid: false },
};

export const QUICK_FIND_OPTIONS = {
  firstColumn: [
    { title: 'News', category: 'news', filterKey: 'text' },
    { title: 'Photo', category: 'photo', filterKey: 'photo' },
    { title: 'Video', category: 'video', filterKey: 'video' },
    { title: 'Video Catalog', category: 'video', openNextCol: true },
  ],
  secondColumn: [
    { title: 'Longform', category: 'longform', id: 'longform', path: 'catalog/longform' },
    { title: 'Web Series', category: 'webseries', id: 'webseries', path: 'catalog/webseries' },
  ],
};

export const QUICK_FIND_BASE_MENU = [
  { title: 'News', category: 'news', filterKey: 'text' },
  { title: 'Photo', category: 'photo', filterKey: 'photo' },
  { title: 'Video', category: 'video', filterKey: 'video' },
];

export const VIDEO_LANGUAGES = [
  { name: 'english', displayName: 'English' },
  { name: 'spanish', displayName: 'Spanish' },
  { name: 'japanese', displayName: 'Japanese' },
  { name: 'chinese', displayName: 'Chinese' },
];

export const DATA_ENCODINGS = { plain: 'text/plain;charset=utf-8' };
export const NEWS_COPY_THRESHOLD = 30;
export const NEWS_FILE_EXTENSION = 'xml';
export const NEWS_COPY_EXTENSION = 'copy';
export const NEWS_TIMEOUT = process.env.NEWS_TIMEOUT || 10000;
