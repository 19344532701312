import axios from 'axios';

import {
  CONTACT_US_FORM_UPDATE,
  CONTACT_US_FORM_SUBMITTING,
  CONTACT_US_FORM_NOT_SUBMITTING,
  CONTACT_US_FORM_SUCCESS,
  CONTACT_US_FORM_ERROR,
  CONTACT_US_FORM_CLEAR_ERROR,
  CONTACT_US_FORM_RESET,
  CONTACT_US_FORM_FLAG_RESET,
} from '../reducers/contactUs';

export const updateForm = (name, value) => ({
  type: CONTACT_US_FORM_UPDATE,
  data: { name, value },
});

export const setFormSubmitting = () => ({
  type: CONTACT_US_FORM_SUBMITTING,
});

export const setFormNotSubmitting = () => ({
  type: CONTACT_US_FORM_NOT_SUBMITTING,
});

export const setFormSuccess = () => ({
  type: CONTACT_US_FORM_SUCCESS,
});

export const setFormError = error => ({
  type: CONTACT_US_FORM_ERROR,
  data: { error },
});

export const clearFormError = () => ({
  type: CONTACT_US_FORM_CLEAR_ERROR,
  data: { error: null },
});

export const resetForm = () => ({
  type: CONTACT_US_FORM_RESET,
});

export const resetFormFlags = () => ({
  type: CONTACT_US_FORM_FLAG_RESET,
});

export const submitContactUsForm = formData => async (dispatch) => {
  try {
    dispatch(resetFormFlags());
    dispatch(setFormSubmitting());
    await axios.post('/api/contactus/submit', formData);
    dispatch(setFormSuccess());
    dispatch(resetForm());
  } catch (err) {
    dispatch(setFormError(err));
    throw err;
  } finally {
    dispatch(setFormNotSubmitting());
  }
};
