import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  setVideoDownloading,
  getVideoPermissions,
  postVideoDownload,
  setPermissions,
  checkUserAndDownload,
} from 'store/actions/videos';
import { showSignIn as globalActionsShowSignIn } from 'store/actions/globalActions';
import { isActiveSession } from 'store/actions/user';
import { ASSET_TYPES, DOWNLOAD_DEBOUNCE_DELAY } from 'components/utils/constants';
import Dropdown from 'components/elements/common/dropdown/Dropdown';
import AddToCollectionButton from 'components/elements/common/permission/AddToCollectionButton';
import NavigationBar from 'elements/common/navigationbar/NavigationBar';
import ShareButton from 'elements/common/permission/ShareButton';
import { saveToFile } from 'components/utils/helpers';
import { DATA_ENCODINGS, VIDEO_TEXT, VIDEO_RENDITIONS, VIDEO_ATTACHMENTS } from '../../../utils/constants';

// todo code duplication with VideoDownloadOptions - partial re-write required
class VideoCommands extends Component {
  constructor(props) {
    super(props);
    this.debounceDownloadWatermark = debounce(() => this.downloadRendition(VIDEO_RENDITIONS.lowres), DOWNLOAD_DEBOUNCE_DELAY, { leading: true, trailing: false });
    this.debounceDownloadCompressed = debounce(() => this.downloadRendition(VIDEO_RENDITIONS.compressed), DOWNLOAD_DEBOUNCE_DELAY, { leading: true, trailing: false });
    this.debounceDownloadHighRes = debounce(() => this.downloadRendition(VIDEO_RENDITIONS.highres), DOWNLOAD_DEBOUNCE_DELAY, { leading: true, trailing: false });
    this.debounceDownloadPal = debounce(() => this.downloadRendition(VIDEO_RENDITIONS.pal), DOWNLOAD_DEBOUNCE_DELAY, { leading: true, trailing: false });
    this.debounceDownloadSubtitles = debounce(() => this.downloadSubtitles(), DOWNLOAD_DEBOUNCE_DELAY, { leading: true, trailing: false });
    this.debounceDownloadTranscript = debounce(() => this.downloadTranscript(), DOWNLOAD_DEBOUNCE_DELAY, { leading: true, trailing: false });
    this.debounceDownloadThumb = debounce(() => this.downloadThumb(), DOWNLOAD_DEBOUNCE_DELAY, { leading: true, trailing: false });
  }

  componentDidMount() {
    const { id } = this.props;
    this.props.setPermissions({});
    this.props.getVideoPermissions(id);
  }

  downloadRendition(renditionSpecs) {
    const { id, permissions: { renditions } } = this.props;
    window.open(renditions[renditionSpecs.label], `download_${id}_${renditionSpecs.label}`, 'noopener,noreferrer');
    this.props.postVideoDownload(id, renditions[renditionSpecs.label], renditionSpecs);
  }

  downloadSubtitles() {
    const { id, clip: { subtitles } } = this.props;
    window.open(subtitles.url2, `download_${id}_sub`, 'noopener,noreferrer');
    this.props.postVideoDownload(id, subtitles.url2, VIDEO_TEXT.subtitles);
  }

  downloadTranscript() {
    const { id, clip: { transcript } } = this.props;
    saveToFile(transcript, DATA_ENCODINGS.plain, `${id}-transcript.txt`);
    this.props.postVideoDownload(id, VIDEO_TEXT.transcript.label, VIDEO_TEXT.transcript);
  }

  downloadThumb() {
    const { id, clip: { thumb } } = this.props;
    const thumbExtension = thumb.split('.').pop().toLowerCase();
    saveToFile(thumb, null, `${id}-thumb.${thumbExtension}`, true);
    this.props.postVideoDownload(id, thumb, VIDEO_ATTACHMENTS);
  }

  checkUserAndDownload({ downloadFunc, specs }) {
    if (!this.props.email || !isActiveSession()) {
      this.props.showSignIn();
      return;
    }

    this.props.checkUserAndDownload({
      specs,
      id: this.props.id,
      downloadFunc: () => downloadFunc(specs),
    });
  }

  renderDownloadButton({ downloadUrl, downloadFunc, specs }) {
    const { displayName } = specs;
    return (
      <li>
        {downloadUrl && <button className="video-download-option" onClick={() => this.checkUserAndDownload({ downloadFunc, specs })}>{displayName}</button>
        || <span className="video-download-option-inactive">{displayName}</span>}
      </li>
    );
  }

  renderContentDownloadButton(renditionSpecs, downloadFunc) {
    const { permissions: { renditions } } = this.props;
    const downloadUrl = renditions && renditions[renditionSpecs.label];
    return this.renderDownloadButton({ downloadUrl, downloadFunc, specs: renditionSpecs });
  }

  renderThumbDownloadButton(downloadFunc) {
    const { thumb } = this.props.clip;
    return this.renderDownloadButton({ downloadUrl: thumb, downloadFunc, specs: VIDEO_TEXT.thumb });
  }

  renderSubtitlesDownloadButton(downloadFunc) {
    const subtitles = this.props.clip.subtitles || { url2: null };
    return this.renderDownloadButton({ downloadUrl: subtitles.url2, downloadFunc, specs: VIDEO_TEXT.subtitles });
  }

  renderTranscriptDownloadButton(downloadFunc) {
    const { displayName } = VIDEO_TEXT.transcript;
    return (
      <li>
        {this.props.clip.transcript && <button className="video-download-option" onClick={() => this.checkUserAndDownload({ downloadFunc, specs: VIDEO_TEXT.transcript })}>{displayName}</button>
        || <span className="video-download-option-inactive">{displayName}</span>}
      </li>
    );
  }

  render() {
    const { id, title } = this.props;
    return (
      <NavigationBar
        className="video-detail-commands"
        leftSide={(
          <ShareButton mediaType={ASSET_TYPES.video.type} context={ASSET_TYPES.video.context} id={id} name={title}/>
        )}
        center={(
          <React.Fragment>
            <Dropdown title={'Low Resolution'} className={'low-res-download'} onOpenClassName={'open'} closeOnClick>
              <ul>
                {this.renderContentDownloadButton(VIDEO_RENDITIONS.lowres, this.debounceDownloadWatermark)}
              </ul>
            </Dropdown>
            <Dropdown title={'High Resolution'} className={'high-res-download'} onOpenClassName={'open'} closeOnClick>
              <ul>
                {this.renderContentDownloadButton(VIDEO_RENDITIONS.compressed, this.debounceDownloadCompressed)}
                {this.renderContentDownloadButton(VIDEO_RENDITIONS.highres, this.debounceDownloadHighRes)}
                {this.renderContentDownloadButton(VIDEO_RENDITIONS.pal, this.debounceDownloadPal)}
                {this.renderSubtitlesDownloadButton(this.debounceDownloadSubtitles)}
                {this.renderTranscriptDownloadButton(this.debounceDownloadTranscript)}
                {this.renderThumbDownloadButton(this.debounceDownloadThumb)}
              </ul>
            </Dropdown>
          </React.Fragment>
        )}
        rightSide={(
          <AddToCollectionButton mediaType={ASSET_TYPES.video.type} id={id} className="button--add-asset"/>
        )}
        hideLayoutOptions
      />
    );
  }
}

const mapStateToProps = ({ user, videos: { permissions, clip } }) => ({
  permissions,
  clip,
  email: user.user.email,
});

const mapDispatchToProps = {
  checkUserAndDownload,
  setPermissions,
  setVideoDownloading,
  postVideoDownload,
  getVideoPermissions,
  showSignIn: globalActionsShowSignIn,
  isActiveSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoCommands);
