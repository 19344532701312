import React from 'react';
import Modal from './Modal';

export const ComingSoonModal = ({ open, close }) => (
  <Modal className="coming-soon-modal" theme="dark" open={open} handleClose={() => close()}>
    <h3>Coming Soon</h3>
    <p>We are working to get this ready for you.</p>
  </Modal>
);

export default ComingSoonModal;
