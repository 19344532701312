import React, { Component } from 'react';
import { trackCustomEvent } from 'utils/analytics';
import Modal from './Modal';
import AccessDenied from '../permission/AccessDenied';

export class AccessDeniedModal extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      trackCustomEvent({
        category: 'upsell',
        action: 'popup',
        label: 'accessdenied',
      });
    }
  }

  render() {
    const { open, handleClose } = this.props;
    return (
      <Modal theme="dark" open={open} handleClose={handleClose}>
        <AccessDenied/>
      </Modal>
    );
  }
}

export default AccessDeniedModal;
