import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'components/elements/common/loading/Loading';
import { fetchSavedSearches } from 'store/actions/savedsearch';
import { fetchPublicationFeeds } from 'store/actions/user';
import ErrorView from 'elements/common/ErrorView';
import SavedSearch from '../savedsearch/SavedSearch';
import PublicationFeeds from './PublicationFeeds';
import Modal from '../Modal';
import BookmarksFilter from './BookmarksFilter';

import './BookmarksModal.scss';

/* istanbul ignore next */
const { FEATURE_FEEDS_ENABLED } = process.env;

class BookmarksModal extends Component {
  componentDidMount() {
    if (this.props.open) {
      this.props.fetchSavedSearches();
      /* istanbul ignore next */
      if (FEATURE_FEEDS_ENABLED) {
        this.props.fetchPublicationFeeds();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.props.fetchSavedSearches();
      /* istanbul ignore next */
      if (FEATURE_FEEDS_ENABLED) {
        this.props.fetchPublicationFeeds();
      }
    }
  }

  closeModal() {
    this.props.handleClose();
  }

  render() {
    const { open, loading, error, handleClose } = this.props;
    return (
      <React.Fragment>
        <Modal className="savedsearch-modal" theme="dark" open={open} handleClose={() => this.closeModal()}>
          <h4>Bookmarks</h4>
          <BookmarksFilter className="savedsearch-modal--filter"/>
          {error && <ErrorView className="savedsearch-modal-error" error={error}/>}
          {loading && <Loading color="white"/>
          || <React.Fragment>
            <SavedSearch handleClose={handleClose}/>
            {/* istanbul ignore next */ FEATURE_FEEDS_ENABLED && <PublicationFeeds handleClose={handleClose}/>}
          </React.Fragment>
          }
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ savedsearch, user }) => ({
  loading: user.loading || savedsearch.loading,
  error: user.error || savedsearch.error,
  userLevel: user.user.level,
  feeds: user.user.feeds,
});

const mapDispatchToProps = {
  fetchSavedSearches,
  fetchPublicationFeeds,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookmarksModal);
