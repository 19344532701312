import React from 'react';
import Contributor from './Contributor';

import './Contributors.scss';

const Contributors = ({ title, items }) => (
    <section className="contributors">
      <h3>{title}</h3>
      <div className="contributors-container">
        {items.map((contributor, i) => (
          <Contributor key={`contributor_${i}`} contributor={contributor} />
        ))}
      </div>
    </section>
);

export default Contributors;
