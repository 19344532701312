import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from 'components/App';
import configureStore from './store';
import { autoSigninUser } from './store/actions/user';

import './styles/index.scss';

if (process.env.NODE_ENV === 'development') {
  const axe = require('react-axe'); // eslint-disable-line global-require
  axe(React, ReactDOM, 1000);
}

const initialState = window.__STATE__ || {}; // eslint-disable-line no-underscore-dangle
delete window.__STATE__; // eslint-disable-line no-underscore-dangle
document.getElementById('initial-state').remove();

const store = configureStore(initialState);
store.dispatch(autoSigninUser());

ReactDOM.hydrate(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('app'),
);
