import React, { Component } from 'react';
import { connect } from 'react-redux';
import EmailInput from 'components/elements/common/forms/EmailInput';
import ErrorView from 'components/elements/common/ErrorView';
import { generateUnlockKey, resetUserError } from 'store/actions/user';
import Page from '../Page';
import './Unlock.scss';

export class UnlockForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        email: this.props.email || '',
      },
    };
  }

  componentDidMount() {
    this.props.resetUserError();
  }

  onChange({ target: { name, value } }) {
    this.setState(prevState => ({ form: { ...prevState.form, [name]: value.trim() } }));
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.generateUnlockKey(this.state.form.email);
  }

  render() {
    const { error, unlockKeyGenerated } = this.props;
    const { email } = this.state.form;

    return (
      <Page className="unlock" title="unlock-form" pageType="unlock-form">
        <h1>Unlock Account</h1>
        {
          (unlockKeyGenerated && !error)
            ? <p>An email has been sent to the email address you provided. Please follow the instructions in the email to unlock your account.</p>
            : <form method="post" className="unlock-form" onSubmit={evt => this.onSubmit(evt) }>
                <EmailInput label="Email" name="email" placeholder="name@example.com" onChange={evt => this.onChange(evt)} required={true} value={email} />
                <div>
                  <button type="submit" className="button-submit unlock-form-submit">Unlock</button>
                </div>
              </form>
        }
        {error && <ErrorView error={error} />}
      </Page>
    );
  }
}

const mapStateToProps = ({ user: { loading, error, unlockKeyGenerated } }) => ({
  loading,
  error,
  unlockKeyGenerated,
});

const mapDispatchToProps = {
  generateUnlockKey,
  resetUserError,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnlockForm);
