export const SET_ITEMS_LAYOUT = 'redux/globalActions/SET_ITEMS_LAYOUT';
export const SHOW_SIGNIN = 'redux/globalActions/SHOW_SIGNIN';
export const SHOW_ACCESS_DENIED = 'redux/globalActions/SHOW_ACCESS_DENIED';
export const CLOSE_ACCESS_DENIED = 'redux/globalActions/CLOSE_ACCESS_DENIED';
export const CLOSE_RESTRICTIONS = 'redux/globalActions/CLOSE_RESTRICTIONS';
export const SHOW_COLLECTIONS_MODAL = 'redux/globalActions/SHOW_COLLECTIONS_MODAL';
export const CLOSE_COLLECTIONS_MODAL = 'redux/globalActions/CLOSE_COLLECTIONS_MODAL';
export const SHOW_COMINGSOON_MODAL = 'redux/globalActions/SHOW_COMINGSOON_MODAL';
export const CLOSE_COMINGSOON_MODAL = 'redux/globalActions/CLOSE_COMINGSOON_MODAL';
export const SHOW_BOOKMARKS_MODAL = 'redux/globalActions/SHOW_BOOKMARKS_MODAL';
export const CLOSE_BOOKMARKS_MODAL = 'redux/globalActions/CLOSE_BOOKMARKS_MODAL';
export const SHOW_QUOTAEXCEEDED_MODAL = 'redux/globalActions/SHOW_QUOTAEXCEEDED_MODAL';
export const CLOSE_QUOTAEXCEEDED_MODAL = 'redux/globalActions/CLOSE_QUOTAEXCEEDED_MODAL';
export const FAILED_SHARING_RECIPIENTS = 'redux/globalActions/FAILED_SHARING_RECIPIENTS';
export const SET_SHARING_DESCRIPTOR = 'redux/globalActions/SET_SHARING_DESCRIPTOR';
export const RESET_SHARING = 'redux/globalActions/RESET_SHARING';
export const SET_SHARING_LOADING = 'redux/globalActions/SET_SHARING_LOADING';
export const SET_SHARING_ERROR = 'redux/globalActions/SET_SHARING_ERROR';
export const SHOW_SHARE_ITEM_MODAL = 'redux/globalActions/SHOW_SHARE_ITEM_MODAL';
export const CLOSE_SHARE_ITEM_MODAL = 'redux/globalActions/CLOSE_SHARE_ITEM_MODAL';
export const ADD_NOTIFICATION = 'redux/globalActions/ADD_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'redux/globalActions/CLEAR_NOTIFICATIONS';
export const SHOW_ASSET_NOT_AVAILABLE_MODAL = 'redux/globalActions/SHOW_ASSET_NOT_AVAILABLE_MODAL';
export const CLOSE_ASSET_NOT_AVAILABLE_MODAL = 'redux/globalActions/CLOSE_ASSET_NOT_AVAILABLE_MODAL';
export const SHOW_ANNOUNCEMENT_MODAL = 'redux/announcement/SHOW_ANNOUNCEMENT_MODAL';
export const CLOSE_ANNOUNCEMENT_MODAL = 'redux/announcement/CLOSE_ANNOUNCEMENT_MODAL';

export const ITEMS_LAYOUT_OPTIONS = { grid: 'grid', list: 'list' };

export const initialState = {
  showAccessDenied: false,
  showCollectionsModal: false,
  showBookmarksModal: false,
  showQuotaExceededModal: false,
  showComingSoon: false,
  showAssetNotAvailableModal: false,
  layout: ITEMS_LAYOUT_OPTIONS.grid,
  signin: {
    showSignin: false,
    invalidToken: false,
  },
  sharing: {
    showShareItemModal: false,
    failedSharingRecipients: [],
    descriptor: {
      item: { id: '', name: '' },
      mediaType: '',
      loading: false,
      error: false,
      userLevel: '',
    },
  },
  showNotifications: false,
  notifications: [],
  showAnnouncementModal: false,
};

const globalActions = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SIGNIN:
      return {
        ...state,
        signin: { ...state.signin, showSignin: true, invalidToken: action.data.invalidToken },
      };
    case SHOW_ACCESS_DENIED:
      return {
        ...state,
        showAccessDenied: true,
      };
    case CLOSE_ACCESS_DENIED:
      return {
        ...state,
        showAccessDenied: false,
      };
    case CLOSE_RESTRICTIONS:
      return {
        ...state,
        signin: { ...state.signin, showSignin: false },
        showAccessDenied: false,
      };
    case SHOW_COLLECTIONS_MODAL:
      return {
        ...state,
        showCollectionsModal: true,
      };
    case CLOSE_COLLECTIONS_MODAL:
      return {
        ...state,
        showCollectionsModal: false,
      };
    case SHOW_COMINGSOON_MODAL:
      return {
        ...state,
        showComingSoon: true,
      };
    case CLOSE_COMINGSOON_MODAL:
      return {
        ...state,
        showComingSoon: false,
      };
    case SHOW_BOOKMARKS_MODAL:
      return {
        ...state,
        showBookmarksModal: true,
      };
    case CLOSE_BOOKMARKS_MODAL:
      return {
        ...state,
        showBookmarksModal: false,
      };
    case SHOW_QUOTAEXCEEDED_MODAL:
      return {
        ...state,
        showQuotaExceededModal: true,
      };
    case CLOSE_QUOTAEXCEEDED_MODAL:
      return {
        ...state,
        showQuotaExceededModal: false,
      };
    case SHOW_SHARE_ITEM_MODAL:
      return {
        ...state,
        sharing: { ...state.sharing, showShareItemModal: true },
      };
    case CLOSE_SHARE_ITEM_MODAL:
      return {
        ...state,
        sharing: { ...state.sharing, showShareItemModal: false },
      };
    case SET_ITEMS_LAYOUT:
      return {
        ...state,
        layout: action.data.layout,
      };
    case FAILED_SHARING_RECIPIENTS:
      return {
        ...state,
        sharing: { ...state.sharing, failedSharingRecipients: action.data.failedSharingRecipients },
      };
    case SET_SHARING_DESCRIPTOR:
      return {
        ...state,
        sharing: { ...state.sharing, descriptor: { ...state.sharing.descriptor, ...action.data.descriptor } },
      };
    case SET_SHARING_LOADING:
      return {
        ...state,
        sharing: { ...state.sharing, descriptor: { ...state.sharing.descriptor, loading: action.data.loading } },
      };
    case SET_SHARING_ERROR:
      return {
        ...state,
        sharing: { ...state.sharing, descriptor: { ...state.sharing.descriptor, error: action.data.error } },
      };
    case RESET_SHARING:
      return {
        ...state,
        sharing: initialState.sharing,
      };
    case ADD_NOTIFICATION:
      return {
        ...state,
        showNotifications: true,
        notifications: [...state.notifications, action.data.notification],
      };
    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        showNotifications: false,
        notifications: initialState.notifications,
      };
    case SHOW_ASSET_NOT_AVAILABLE_MODAL:
      return {
        ...state,
        showAssetNotAvailableModal: true,
      };
    case CLOSE_ASSET_NOT_AVAILABLE_MODAL:
      return {
        ...state,
        showAssetNotAvailableModal: false,
      };
    case SHOW_ANNOUNCEMENT_MODAL:
      return {
        ...state,
        showAnnouncementModal: true,
      };
    case CLOSE_ANNOUNCEMENT_MODAL:
      return {
        ...state,
        showAnnouncementModal: false,
      };
    default:
      return state;
  }
};

export default globalActions;
