import React, { Component } from 'react';
import { connect } from 'react-redux';
import Page from '../Page';
import SigninContent from './SigninContent';

export class Signin extends Component {
  componentDidUpdate() {
    const { email, history } = this.props;
    if (email && history) {
      history.push('/');
    }
  }

  render() {
    return (
      <Page title="signin" pageType="signin">
        <SigninContent />
      </Page>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  email: user.user.email,
});

export default connect(mapStateToProps)(Signin);
