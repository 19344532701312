import React from 'react';
import { connect } from 'react-redux';
import { toggleFilters } from 'store/actions/search';
import MediaFilters from './MediaFilters';
import FilterActions from './FilterActions';
import DateFilters from './DateFilters';
import SearchTermsFilters from './keywords/SearchTermsFilters';
import PhotoFilters from './PhotoFilters';
import NewsFilters from './NewsFilters';
import VideoFilters from './VideoFilters';

import './Filters.scss';

const Filters = props => (
  <div className={`filters filter-${props.open ? 'show' : 'hide'}`}>
    <button className="back-button" onClick={() => props.toggleFilters()} aria-label="dismiss filters" />
    <div className="filters--scrollable">
      <h2>Filters</h2>
      <MediaFilters />
      <DateFilters />
      <hr />
      <SearchTermsFilters />
      <hr />
      <NewsFilters />
      <PhotoFilters />
      <VideoFilters />
    </div>

    <FilterActions />
  </div>
);

const mapStateToProps = ({ search: searchStore }) => ({
  open: searchStore.filtersAreOpen,
});

const mapDispatchToProps = {
  toggleFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
