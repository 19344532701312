import React from 'react';

import './ErrorPage.scss';

const ErrorPage = ({ error }) => (
  <div className="error-page">
    <h2 className="error-page__title">Oops... Something unexpected happened</h2>
    {error.message && <h3 className="error-page__message">Error: {error.message}</h3>}
    {error.correlationId && <h4 className="error-page__id">ID: {error.correlationId}</h4>}
  </div>
);

export default ErrorPage;
