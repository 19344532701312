import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducer from './reducers';

const composeEnhancers = (typeof window === 'object' && process.env.NODE_ENV !== 'production') && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

const enhancer = composeEnhancers(
  applyMiddleware(
    thunkMiddleware,
  ),
);

const configureStore = initialState => createStore(
  reducer,
  initialState,
  enhancer,
);
export default configureStore;
