import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import Items from 'components/elements/common/Items/Items';
import StoryListItem from 'components/elements/common/Story/StoryListItem';
import StoryGridItem from 'components/elements/common/Story/StoryGridItem';
import ErrorView from 'elements/common/ErrorView';
import AutoRefresh from 'elements/pages/search/autorefresh/AutoRefresh';
import {
  paginate,
  search,
  executeSavedSearch,
  updateSearchResults,
  resetResults,
  resetAutoRefreshDelta,
  fetchFeedArticles,
  closeFilters,
} from 'store/actions/search';

import SearchNav from './searchnav/SearchNav';
import Filters from './filters/Filters';
import Page from '../Page';
import SearchContents from './SearchContents';
import SearchActions from './SearchActions';

import './Search.scss';

class Search extends Component {
  constructor(props) {
    super(props);
    this.refreshTimer = null;
    // can inject the ref into this component if needed (also helps testing)
    this.autoRefreshRef = React.createRef();
  }

  /* istanbul ignore next */ // cannot test while mounting the component
  componentDidMount() {
    const { results, searchId, lastFeedId, match: { params: { id, feedId } }, loading, finishedAutoSignin } = this.props;
    if (finishedAutoSignin) {
      if (id && id !== searchId) {
        this.props.executeSavedSearch(id);
      } else if (feedId && feedId !== lastFeedId) {
        this.props.fetchFeedArticles(feedId);
        this.props.closeFilters();
      } else if (!results.length && !loading) {
        this.props.search();
      }
      this.startAutoRefreshTimer();
    }
  }

  componentDidUpdate(prevProps) {
    const { params: { id, feedId } } = this.props.match;
    /* istanbul ignore next */
    if (id && id !== prevProps.match.params.id) {
      this.props.executeSavedSearch(id);
    }

    /* istanbul ignore next */
    if (feedId && feedId !== prevProps.match.params.feedId) {
      this.props.fetchFeedArticles(feedId);
      this.props.closeFilters();
    }

    /* istanbul ignore next */
    if (this.props.finishedAutoSignin !== prevProps.finishedAutoSignin) {
      if (id) {
        this.props.executeSavedSearch(id);
      } else if (feedId) {
        this.props.fetchFeedArticles(feedId);
        this.props.closeFilters();
      } else {
        this.props.search();
      }
      this.startAutoRefreshTimer();
    }
  }

  startAutoRefreshTimer() {
    // Calls to start timer can be initiated for users who are not signed in yet, hence the defaulting for preferences
    const { autoRefresh, autoRefreshInterval } = this.props.preferences || {};

    if (autoRefresh && !this.refreshTimer) {
      this.refreshTimer = setInterval(() => {
        this.props.updateSearchResults();
      }, autoRefreshInterval * 60 * 1000);
    }
  }

  /* istanbul ignore next */ // unmount-ing the component will not enable testing this block
  componentWillUnmount() {
    if (this.refreshTimer != null) {
      clearInterval(this.refreshTimer);
      this.refreshTimer = null;
    }
  }

  onViewUpdates(evt) {
    evt.preventDefault();
    this.props.resetAutoRefreshDelta();
    if (this.autoRefreshRef && this.autoRefreshRef.scrollTo) {
      this.autoRefreshRef.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }

  render() {
    const { error, layout, results, hasMore, loading, totals, autoRefreshDelta, finishedAutoSignin, filtersAreOpen, match: { params: { feedId } } } = this.props;
    const debouncedPaginate = debounce(this.props.paginate, 250);

    return (
      <Page className={`search ${filtersAreOpen ? 'open' : 'closed'}`} noTrack>
        <SearchNav hideFilters={feedId}/>
        {error && <ErrorView className="search-error" error={error} />}
        {!feedId && <Filters />}

        <div ref={(autoRefreshRef) => { this.autoRefreshRef = autoRefreshRef; }} className="search-results">
          <Items
            className={` ${layout}`}
            results={results}
            hasMore={finishedAutoSignin && hasMore}
            totals={totals}
            loading={loading}
            loadMore={debouncedPaginate}
            ListItem={(<StoryListItem contents={<SearchContents />} actions={<SearchActions />} className="search-result-list"/>)}
            GridItem={(<StoryGridItem contents={<SearchContents />} actions={<SearchActions />} className="search-result-grid" />)}
          />
        </div>
        <div className="auto-refresh-updates-notification">
          {autoRefreshDelta && <AutoRefresh
            delta={autoRefreshDelta}
            onViewUpdates={evt => this.onViewUpdates(evt)}
          /> || null}
        </div>
      </Page>
    );
  }
}

const mapStateToProps = ({ search: searchStore, globalActions, user }) => ({
  layout: globalActions.layout,
  error: searchStore.error,
  totals: searchStore.totals,
  autoRefreshDelta: searchStore.autoRefreshDelta,
  loading: searchStore.loading,
  results: searchStore.results,
  hasMore: searchStore.hasMore,
  searchId: searchStore.searchId,
  lastFeedId: searchStore.feedId,
  filtersAreOpen: searchStore.filtersAreOpen,
  finishedAutoSignin: user.attemptedAutoSignin,
  preferences: user.user.preferences,
});

const mapDispatchToProps = {
  paginate,
  search,
  resetResults,
  executeSavedSearch,
  updateSearchResults,
  resetAutoRefreshDelta,
  fetchFeedArticles,
  closeFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
