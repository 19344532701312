import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { modifySavedSearch, deleteSavedSearch, setSavedSearchError, setFilter } from 'store/actions/savedsearch';
import { setQuery, search, updateFilter, resetFilters } from 'store/actions/search';
import Accordion from 'elements/common/accordion/Accordion';
import ShareButton from 'elements/common/permission/ShareButton';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { SEARCH_ROUTE } from '../../../../../../routes';

import { ASSET_TYPES } from '../../../../utils/constants';

class SavedSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmDeleteOpen: false,
      itemToDelete: null,
    };
  }

  openDeleteConfirmation(itemToDelete) {
    this.setState({ confirmDeleteOpen: true, itemToDelete });
  }

  closeDeleteConfirmation() {
    this.setState({ confirmDeleteOpen: false, itemToDelete: false });
    this.props.setSavedSearchError(false);
  }

  async deleteSavedSearch() {
    if (await this.props.deleteSavedSearch(this.state.itemToDelete.id)) {
      this.closeDeleteConfirmation();
    }
  }

  executeSavedSearch(saveSearchParams) {
    this.props.handleClose();
    const { history } = this.props;
    const routePath = `${SEARCH_ROUTE}/${saveSearchParams.id}`;
    history.push(routePath);
  }

  render() {
    const { savedSearches, loading, error } = this.props;
    const { confirmDeleteOpen, itemToDelete } = this.state;
    return (
      <React.Fragment>
        <Accordion className={'saved--searches'} initializeOpen={true} label='Saved Searches' animate>
          <ul className="savedsearch-modal--list">
            {savedSearches.map(({ id, name, queryParams }) => (
              <li key={id}>
                <button type="button" className="savedsearch-modal__execute bookmarks-savedsearch-execute-button" onClick={() => this.executeSavedSearch({ id, name, queryParams })}>
                  <div className="savedsearch-modal__execute-body">
                    <span className="savedsearch-modal__execute-name">{name}</span>
                    <span className="savedsearch-modal__execute-carrot">&gt;</span>
                  </div>
                </button>
                <div className="savedsearch-modal--list__actions">
                  <ShareButton className="savedsearch-modal__share" label="" mediaType={ASSET_TYPES.search.type} userLevel={this.props.userLevel} id={id} name={name} />
                  <button type="button" className="savedsearch-modal__delete" onClick={() => this.openDeleteConfirmation({ id, name })} aria-label="delete saved search?" />
                </div>
              </li>
            ))}
          </ul>
        </Accordion>
        <ConfirmDeleteModal
          open={confirmDeleteOpen}
          name={itemToDelete && itemToDelete.name}
          loading={loading}
          error={error}
          handleClose={() => this.closeDeleteConfirmation()}
          confirm={() => this.deleteSavedSearch()}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ savedsearch, search: searchStore, user, globalActions }) => ({
  savedSearches: savedsearch.displaySavedSearches,
  loading: savedsearch.loading,
  error: savedsearch.error,
  hasResults: !!searchStore.results.length,
  filters: searchStore.filters,
  failedSharingRecipients: globalActions.failedSharingRecipients,
  userLevel: user.user.level,
});

const mapDispatchToProps = {
  modifySavedSearch,
  deleteSavedSearch,
  setQuery,
  search,
  updateFilter,
  setSavedSearchError,
  setFilter,
  resetFilters,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SavedSearch));
