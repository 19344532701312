import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { initialState } from 'store/reducers/search';
import { search, updateFilter, resetFilterGroups } from 'store/actions/search';
import { defaultVideoFilters } from 'store/actions/videos';
import { setDropdownOpen, setSelected, setColumnOpen, setColumnOptions, quickFindReset } from 'store/actions/quickFind';
import { ASSET_TYPES, SESSION_FILTERS } from '../../../utils/constants';
import QuickFind from './quickfind/QuickFind';

import './QuickFindOptions.scss';

const columnKeys = { one: '1', two: '2', three: '3' };

class QuickFindOptions extends Component {
  handleFilterSearch(filterKey) {
    this.props.resetFilterGroups(SESSION_FILTERS);
    const mediaFilters = Object.keys(initialState.filters.media);
    mediaFilters.map(filter => this.props.updateFilter('media', filter, (filterKey === filter)));
    this.customizeFilterSearch(filterKey);
    this.props.search();
  }

  customizeFilterSearch(filterKey) {
    if (filterKey === ASSET_TYPES.video.media) {
      this.props.defaultVideoFilters();
    }
  }

  handleClickToNextColumn(option, columnKey, currentColumnSelection) {
    const currentSelectionClicked = option.title === currentColumnSelection;
    this.props.setSelected(columnKey, currentSelectionClicked ? '' : option.title);

    const nextColumn = `${Number.parseInt(columnKey, 10) + 1}`;
    this.props.setColumnOpen(nextColumn, !currentSelectionClicked);
  }

  handleActionClick(option) {
    if (option.filterKey) {
      this.handleFilterSearch(option.filterKey);
    }
    this.props.quickFindReset();
  }

  handleClick(option, columnKey, currentColumnSelection) {
    if (option.openNextCol) {
      this.handleClickToNextColumn(option, columnKey, currentColumnSelection);
    } else {
      this.handleActionClick(option);
    }
  }

  renderColumn(columnKey) {
    const { columnData } = this.props;
    const column = columnData.columns[columnKey];

    if (!columnKey || !column) return null;

    return column.options.map((option) => {
      const { title, openNextCol, path } = option;

      const OptionTag = openNextCol ? 'div' : Link;

      const optionProps = {
        to: path ? `/videos/series/${path}` : '/search',
        className: (`options__col--option ${column.selected === title ? 'selected' : ''}`).trim(),
        children: (<span>{ title } { openNextCol ? <span className="carat">&gt;</span> : null } </span>),
        onClick: () => this.handleClick(option, columnKey, column.selected),
      };

      return <OptionTag key={title} {...optionProps} />;
    });
  }

  render() {
    if (process.env.FEATURE_CATALOG_ENABLED) {
      return <QuickFind />;
    }
    const { columnData } = this.props;
    const columns = Object.values(columnKeys).filter(key => columnData.columns[key] && columnData.columns[key].open).map(key => (
      <div className="options__col" key={`options__col-${key}`}>
        { this.renderColumn(key) }
      </div>
    ));

    return (
      <div className="options">
        { columns }
      </div>
    );
  }
}

const mapStateToProps = ({ quickFind }) => ({
  columnData: quickFind,
});

const mapDispatchToProps = {
  setDropdownOpen,
  setSelected,
  setColumnOpen,
  setColumnOptions,
  quickFindReset,
  updateFilter,
  defaultVideoFilters,
  search,
  resetFilterGroups,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuickFindOptions));
