import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Accordion from 'elements/common/accordion/Accordion';

class PublicationFeeds extends Component {
  getFeedArticles(feedParams) {
    this.props.handleClose();
    const { history } = this.props;
    const routePath = `/feeds/${feedParams.id}/news`;
    history.push(routePath);
  }

  render() {
    const { publicationFeeds } = this.props;
    return (
      <React.Fragment>
        <Accordion className={'publication--feeds'} initializeOpen={true} label='Feeds' animate>
          <ul className="savedsearch-modal--list">
            {publicationFeeds.map(({ id, name }) => (
              <li key={id}>
                <button type="button" className="savedsearch-modal__execute bookmarks-feeds-execute-button" onClick={() => this.getFeedArticles({ id, name })}>
                  <div className="savedsearch-modal__execute-body">
                    <span className="savedsearch-modal__execute-name">{name}</span>
                    <span className="savedsearch-modal__execute-carrot">&gt;</span>
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </Accordion>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ search: searchStore, user }) => ({
  loading: user.loading,
  hasResults: !!searchStore.results.length,
  userLevel: user.user.level,
  publicationFeeds: user.displayPublicationFeeds,
});

export default withRouter(connect(mapStateToProps)(PublicationFeeds));
