import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setFilterGroup, updateFilter } from 'store/actions/search';
import { denormalizeContextFilters } from 'components/utils/helpers';
import { VIDEO_LANGUAGES } from 'components/utils/constants';
import FilterGroup from './FilterGroup';
import FilterCheckboxes from './FilterCheckboxes';

class VideoFilters extends Component {
  // todo/haldokanji: even after this simplification the implementation is not adequate - video brands are similar to news feeds and should
  // be implemented using the same pattern and structures
  updateSearchFilters(checked, brandRefName) {
    const { subscriptions, filters } = this.props;

    const brandsForSearch = subscriptions.filter(sub => (checked && sub.refName === brandRefName) || (filters.video && filters.video[sub.refName] && sub.refName !== brandRefName));
    const { brands } = denormalizeContextFilters({ brands: brandsForSearch.map(({ refName, name }) => ({ value: refName, displayValue: name })) });
    this.props.setFilterGroup('brands', brands);
  }

  renderSubscriptionSection(subsBrands, className) {
    const brands = subsBrands.map(labelBrand => ({
      name: labelBrand.refName,
      displayName: labelBrand.name,
      legend: labelBrand.label,
    }));
    if (brands.length > 0) {
      return <FilterCheckboxes
        className={className}
        filterKey="video"
        options={brands}
        onClick={(checked, name) => this.updateSearchFilters(checked, name)} />;
    }
    return null;
  }

  options(filters) {
    const { video } = filters;
    const { shortForm, longForm, clean, dirty } = video;
    return {
      durationOptions: [
        { name: 'shortForm', displayName: 'Shorter than 5 minutes', value: shortForm, legend: 'Duration' },
        { name: 'longForm', displayName: 'Longer than 5 minutes', value: longForm, legend: 'Duration' },
      ],
      typeOptions: [
        { name: 'clean', displayName: 'Clean', value: clean, legend: 'Type' },
        { name: 'dirty', displayName: 'Dirty', value: dirty, legend: 'Type' },
      ],
      languageOptions: VIDEO_LANGUAGES.map(language => ({ ...language, legend: 'Language', value: video[language.name] })),
    };
  }

  render() {
    const { filters, subscriptions } = this.props;
    const { durationOptions, typeOptions, languageOptions } = this.options(filters);
    return (
      <FilterGroup className="video" name="Video" open={filters.media.video}>
        { this.renderSubscriptionSection(subscriptions, 'video-brands') }
        <FilterCheckboxes className="video-duration" filterKey="video" options={durationOptions} />
        <FilterCheckboxes className="video-type" filterKey="video" options={typeOptions} />
        <FilterCheckboxes className="video-subtitles-language" legend="Language" filterKey="video" options={languageOptions} checkboxGroup={VIDEO_LANGUAGES.map(language => language.name)} />
      </FilterGroup>
    );
  }
}

const mapStateToProps = ({ search, user }) => ({
  subscriptions: user.user.videoBrands || [],
  filters: search.filters,
});

const mapDispatchToProps = {
  updateFilter,
  setFilterGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoFilters);
