import React from 'react';
import Story from '../common/Story';

import './FeaturedModule.scss';

const FeaturedModule = ({ items, row, title }) => {
  if (items.length < 3) {
    console.warn('Feature Module - not enough images to display');
    return null;
  }

  const crops = {
    desktop: '1380x700',
    tablet: '690x350',
    mobile: '345x175',
  };

  const [story1, story2, story3] = items;

  return (
    <section className="featured-module">
      <Story className="featured-module-primary" crops={crops} story={story1} row={row} column={0} moduleTitle={title} />
      <Story className="featured-module-secondary" crops={crops} story={story2} row={row} column={1} moduleTitle={title} />
      <Story className="featured-module-tertiary" crops={crops} story={story3} row={row} column={2} moduleTitle={title} />
    </section>
  );
};

export default FeaturedModule;
