import React from 'react';
import { connect } from 'react-redux';
import FilterList from 'components/elements/common/filterlist/FilterList';
import { filterCollections, setFilter } from 'store/actions/collections';

const CollectionsFilter = props => (
  <FilterList className={`collections-filter ${props.className}`.trim()} filterList={props.filterCollections} display={props.display} placeholder="Find Collection" setFilter={props.setFilter} filter={props.filter}/>
);

const mapStateToProps = ({ collections }) => ({
  display: !!collections.collections.length,
  filter: collections.filter,
});

const mapDispatchToProps = {
  filterCollections,
  setFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsFilter);
