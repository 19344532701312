import React from 'react';
import { connect } from 'react-redux';
import FilterGroup from './FilterGroup';
import FilterCheckboxes from './FilterCheckboxes';

const options = (filters) => {
  const { photo: { landscape, portrait, creative, editorial } } = filters;
  return {
    orientationOptions: [
      { name: 'landscape', displayName: 'Landscape', value: landscape, legend: 'Orientation' },
      { name: 'portrait', displayName: 'Portrait', value: portrait, legend: 'Orientation' },
    ],
    usageOptions: [
      { name: 'creative', displayName: 'Creative', value: creative, legend: 'Usage' },
      { name: 'editorial', displayName: 'Editorial', value: editorial, legend: 'Usage' },
    ],
  };
};

const PhotoFilters = ({ open, filters }) => {
  const { orientationOptions, usageOptions } = options(filters);
  return (
    <FilterGroup className="photos" name="Photo" open={open}>
      <FilterCheckboxes filterKey="photo" options={orientationOptions}/>
      <FilterCheckboxes filterKey="photo" options={usageOptions}/>
    </FilterGroup>
  );
};

const mapStateToProps = ({ search: searchStore }) => ({
  open: searchStore.filters.media.photo,
  filters: searchStore.filters,
});

export default connect(mapStateToProps)(PhotoFilters);
