import React from 'react';
import mt from 'moment-timezone';
import { PUBLISHED_DATETIME_DISPLAY }  from 'components/utils/constants';
import { STORY_TYPE } from '../../../utils/constants';

const formatDate = date => mt(date).tz(mt.tz.guess()).format(PUBLISHED_DATETIME_DISPLAY);

const formatDateDisplay = (publishedAt, updatedAt, type) => {
  if (type === STORY_TYPE.quicktake) {
    return `Updated on ${formatDate(updatedAt)}`;
  }
  return formatDate(publishedAt).concat(publishedAt !== updatedAt ? ` - Updated on ${formatDate(updatedAt)}` : '');
};

const PublishedInfo = ({ publishedAt, updatedAt, type, byline, wordCount }) => (
  <div className="news--published-info">
    <div>By {byline}</div>
    <time>{formatDateDisplay(publishedAt, updatedAt, type)}</time>
    <div>Word Count: {wordCount}</div>
  </div>
);

export default PublishedInfo;
