import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateFilter } from 'store/actions/search';
import Checkbox from 'common/forms/checkbox/Checkbox';
import { allowFilterAction } from '../../../../utils/helpers';

class SourceFilters extends Component {
  constructor(props) {
    super(props);
    this.feeds = {};
  }

  onSourceChange({ target: { checked } }, name, options) {
    if (allowFilterAction(options, checked)) {
      this.feeds[name].forEach((id) => {
        this.props.updateFilter('feeds', id, checked);
      });
    }
  }

  render() {
    const { feeds = [], filters } = this.props;

    const options = {};
    if (filters.feeds) {
      feeds.forEach(({ id, name: displayName }) => {
        const name = displayName.toLowerCase().trim().replace(/[\s\W-]/g, '_');
        this.feeds[name] = this.feeds[name] || new Set();
        this.feeds[name].add(id);

        if (!options[name]) {
          options[name] = { name, displayName, value: filters.feeds[id] };
        }
      });
    }

    return (
      <div className="filters--checkboxes filters--sources">
        <legend>Sources</legend>
        {Object.values(options).map(({ name, value, displayName }) => (
          <Checkbox key={name} checked={value} onChange={evt => this.onSourceChange(evt, name, options)}>{displayName}</Checkbox>
        ))}
      </div>
    );
  }
}

const mapStateToProps = ({ user, search }) => ({
  feeds: user.user.feeds,
  filters: search.filters,
});

const mapDispatchToProps = {
  updateFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceFilters);
