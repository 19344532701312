import React from 'react';
import { connect } from 'react-redux';
import PillFilter from '../PillFilter';

const PeopleFilters = ({ people }) => (
  <PillFilter className="people-filters" name="People" placeholder="Search People" items={people} filterKey="people" />
);

const mapStateToProps = ({ search: searchStore }) => ({
  people: Object.values(searchStore.filters.people || {}),
});

export default connect(mapStateToProps)(PeopleFilters);
