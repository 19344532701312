import React from 'react';
import { Link } from 'react-router-dom';
import ResponsiveImage from 'components/elements/common/ResponsiveImage/ResponsiveImage';
import { formatResultURL } from 'components/utils/helpers';

const crops = {
  image: {
    desktop: '-1x250',
    tablet: '-1x240',
    mobile: '-1x180',
  },
  news: {
    desktop: '250x250',
    tablet: '240x240',
    mobile: '180x180',
  },
  video: {
    desktop: '-1x250',
    tablet: '-1x240',
    mobile: '-1x180',
  },
};

const SearchContents = ({ id, title, type, thumb }) => {
  // need to account for not having crops[story] for some old data
  const assetType = type === 'story' ? 'news' : type;

  return (
    <Link to={formatResultURL(assetType, id)} aria-label={title || id}>
      {thumb && <ResponsiveImage imageUrl={thumb} title={title || id} crops={crops[assetType]}/>}
    </Link>
  );
};

export default SearchContents;
