import React from 'react';
import QuickFindItem from './QuickFindItem';
import CatalogMenuItem from './CatalogMenuItem';

const QuickFindMenuItem = ({ item }) => {
  if (item.category) {
    return <QuickFindItem item={item} />;
  } if (item.items) {
    return <CatalogMenuItem item={item} />;
  }
  return null;
};

export default QuickFindMenuItem;
