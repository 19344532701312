import React, { Component } from 'react';
import Loading from 'components/elements/common/loading/Loading';
import ErrorView from 'elements/common/ErrorView';
import Modal from '../Modal';

import './Confirmation.scss';

export class Confirmation extends Component {
  onSubmit(evt) {
    evt.preventDefault();
    this.props.confirm();
  }

  render() {
    const { open, className, loading, error, message, title, handleClose } = this.props;
    return (
      <Modal className={`confirmation ${className}`.trim()} theme="dark" open={open} handleClose={handleClose}>
        <h4>{title}</h4>
        <p>{message}</p>
        <form method="post" className="confirmation--actions" onSubmit={evt => this.onSubmit(evt) } >
          <button className="confirmation--actions__cancel button-cancel" type="button" onClick={handleClose} disabled={loading}>Cancel</button>
          <button className="confirmation--actions__confirm button-contact" type="submit" onClick={evt => this.onSubmit(evt)} disabled={loading}>
            Confirm {loading && <Loading color="white" height={8} width={24} />}
          </button>
        </form>
        {error && <ErrorView className="confirmation--error" error={error} />}
      </Modal>
    );
  }
}

export default Confirmation;
