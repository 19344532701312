import React from 'react';
import _get from 'lodash.get';
import { connect } from 'react-redux';
import { updateFilter } from 'store/actions/search';
import Checkbox from 'common/forms/checkbox/Checkbox';
import { allowFilterAction } from '../../../../utils/helpers';

// todo this whole checkboxGroup and onClick logic is specific to video and should be dealt with there
const adjustFilters = ({ filterKey, name, checked, checkboxGroup, options, props }) => {
  const group = checkboxGroup || [name];
  if (allowFilterAction(options, checked)) {
    group.forEach((checkbox) => {
      const value = checkbox === name ? checked : false;
      props.updateFilter(filterKey, checkbox, value);
      if (props.onClick) {
        props.onClick(checked, name);
      }
    });
  }
};

const checkboxesAttrs = ({ filterKey, options, filters }) => {
  const selections = [];
  const boxes = options.reduce((result, { legend }) => { // init
    /* eslint-disable no-param-reassign */
    result[legend] = [];
    return result;
  }, {});

  options.forEach(({ name, displayName, legend }) => {
    const attrs = {
      name,
      displayName,
      selected: _get(filters, `${filterKey}.${name}`) || false,
    };
    boxes[legend].push(attrs);
    selections.push({ value: attrs.selected });
  });
  return { boxes, selections };
};

const FilterCheckboxes = ({ options, filterKey, filters, checkboxGroup, ...props }) => {
  const { boxes, selections } = checkboxesAttrs({ filterKey, options, filters });
  return (
    <div className="filters--checkboxes">
      {Object.keys(boxes).map(legend => (
        <span key={legend}>
          <legend>{legend}</legend>
          {boxes[legend].map(({ name, selected, displayName }) => (
            <Checkbox
              key={name}
              checked={selected}
              onChange={({ target: { checked } }) => adjustFilters({ filterKey, name, checked, checkboxGroup, options: selections, props })}
            >
              {displayName}
            </Checkbox>
          ))}
        </span>
      ))}
    </div>
  );
};

const mapStateToProps = ({ search: searchStore }) => ({
  filters: searchStore.filters,
});

const mapDispatchToProps = {
  updateFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterCheckboxes);
