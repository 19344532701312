import React from 'react';
import Modal from '../Modal';
import VideoPlayer from '../../videoplayer/VideoPlayer';

import './VideoPlayerModal.scss';

const VideoPlayerModal = ({ open, close, url = '' }) => {
  const videoPlayerOptions = {
    autoplay: true,
    muted: false,
    sources: [{ src: url, type: 'video/mp4' }],
  };

  return !open ? null : (
    <Modal className="video-player-modal" theme="dark" open={open} handleClose={() => close()}>
      <VideoPlayer {...videoPlayerOptions} noTrack />
    </Modal>
  );
};

export default VideoPlayerModal;
