import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import get from 'lodash.get';
import { getOrFetchNews, setStory } from 'store/actions/news';
import Loading from 'components/elements/common/loading/Loading';
import { getNewsTrackingDetails } from 'utils/analytics';
import Page from '../Page';
import Attachments from './attachments/Attachments';
import PublishedInfo from './PublishedInfo';
import Body from './Body';
import Lede from './Lede';
import Nav from './Nav';
import Trialist from './Trialist';
import NewsCommands from './NewsCommands';

import './News.scss';

export class News extends Component {
  componentDidMount() {
    const { match: { params: { id } } } = this.props;
    this.props.getOrFetchNews(id);
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { id } }, signedIn } = this.props;
    if (id !== prevProps.match.params.id || (signedIn !== prevProps.signedIn && prevProps.signedIn === false)) {
      this.props.getOrFetchNews(id);
    }
  }

  componentWillUnmount() {
    this.props.setStory({});
  }

  render() {
    const { story, loading, error, permissions, history } = this.props;
    if (loading && !story.id && !error) {
      return <Loading />;
    }

    const { id, title, revision, videos = [], images = [] } = story;
    const attachments = [...images, ...videos.map(video => ({ ...video, type: 'video' }))];
    const trialist = revision && get(permissions, `news.${revision}.news.watermark`, false);

    return (
      <Page className="news" {...getNewsTrackingDetails(story)} error={error}>
        <Nav/>
        <div className="news--content">
          <Lede {...story} />
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
          <PublishedInfo {...story} />
          <Trialist trialist={trialist} />
          <Body {...story} history={history} />
        </div>
        <NewsCommands attachments={attachments} id={id} revision={revision} title={title} />
        <Attachments revision={revision} attachments={attachments} />
      </Page>
    );
  }
}

const mapStateToProps = ({ news, user }) => ({
  loading: news.loading,
  error: news.error,
  story: news.story,
  signedIn: !!user.user.id,
  permissions: user.user.permissions,
});

const mapDispatchToProps = {
  setStory,
  getOrFetchNews,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(News));
