import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import GlobalActions from 'elements/common/GlobalActions';
import Page from '../pages/Page';
import ErrorBoundary from './ErrorBoundary';
import routes from '../../../../routes';

export const NotFound = () => <Page error={404}/>;

class MainBody extends Component {
  render() {
    return (
      <ErrorBoundary>
        <main className="main-body">
          <Switch>
            {routes.map(route => <Route key={route.path} {...route} />)}
            <Route component={NotFound} />
          </Switch>
          <GlobalActions />
        </main>
      </ErrorBoundary>
    );
  }
}

export default MainBody;
