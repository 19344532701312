import React, { Component } from 'react';
import { applyClassname } from 'utils/helpers';

class Pill extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animate: true, // allow animation to be performed only on creation
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ animate: false });
    }, 1000);
  }

  render() {
    const { className, item: { value, displayValue }, onRemove } = this.props;
    const { animate } = this.state;

    return (
      <div className={`pill ${animate ? 'pill__animate' : ''} ${applyClassname(className, '--pill')}`.trim()}>
        <label className={`pill__label ${applyClassname(className, '--pill__label')}`.trim()}>{displayValue}</label>
        <button type="button" className={`pill__remove ${applyClassname(className, '--pill__remove')}`.trim()} onClick={() => onRemove(value)} aria-label={`clear ${displayValue} filter`} />
      </div>
    );
  }
}

export default Pill;
