import React from 'react';
import TextInput from '../TextInput';
import Autocomplete from '../../Autocomplete';

import './SearchInput.scss';

const SearchInput = ({ className = 'search', disabled = false, autocomplete = false, autocompleteId, handleSubmit, children, ...props }) => (
  <form method="post" className={`search-input ${className}`} onSubmit={evt => handleSubmit(evt)}>
    {/* this component is used in contexts where autocomplete is not required */}
    { autocomplete && <Autocomplete id={autocompleteId} ariaLabel="search-input" onSelect={evt => handleSubmit(evt)} {...props} />
    || <TextInput {...props} className={`search-input--query ${className}--query`} type="search"/>}
    <button type="submit" className={`search-input--submit ${className}--submit`} onClick={evt => handleSubmit(evt)} disabled={disabled} aria-label="search submit" />
    {children}
  </form>
);

export default SearchInput;
