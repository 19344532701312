import React, { Component } from 'react';
import ResponsiveImage from 'components/elements/common/ResponsiveImage/ResponsiveImage';
import VideoPlayerModal from 'components/elements/common/modal/videoplayermodal/VideoPlayerModal';
import AttachmentDownload from './AttachmentDownload';

const crops = {
  desktop: '148x101',
  tablet: '148x101',
  mobile: '148x101',
};

class VideoAttachment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  closeVideoPlayer() {
    this.setState({ open: false });
  }

  openVideoPlayer() {
    this.setState({ open: true });
  }

  render() {
    const { revision, attachment } = this.props;
    const { open } = this.state;

    return (
      <div className="news--attachments__attachment video">
        <div className="news--attachments__attachment-video-player" onClick={() => this.openVideoPlayer()}>
          <ResponsiveImage imageUrl={attachment.thumb} title={attachment.title} crops={crops}/>
        </div>
        <AttachmentDownload revision={revision} attachment={attachment} />
        <VideoPlayerModal open={open} close={() => this.closeVideoPlayer()} url={attachment.watermark} />
      </div>
    );
  }
}

export default VideoAttachment;
