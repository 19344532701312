import React from 'react';
import Truncate from 'react-truncate';
import ResponsiveImage from 'components/elements/common/ResponsiveImage/ResponsiveImage';
import StoryContentOptions from 'components/elements/pages/topics/modules/common/StoryContentOptions';
import { InternalExternalLink } from '../../../homepage/modules/common/Story';

import './StoryPackage.scss';

const StoryPackage = ({ title, items }) => {
  const crops = {
    desktop: '-1x-1',
    tablet: '768x274',
    mobile: '320x169',
  };
  const stories = items.filter(({ thumbnail }) => !!thumbnail);
  if (!stories || !stories.length) {
    return null;
  }

  return (
    <section className="story-package">
      <div className="story-package-wrapper">
        <h3>{title}</h3>
        <div className="story-package-container">
          {stories.map((item, index) => (
            <div key={item.id || /* istanbul ignore next */ `topic-${index}`} className="topic-list--item">
              <InternalExternalLink story={item}>
                <ResponsiveImage imageUrl={item.thumbnail.url} crops={crops} title={item.title} />
              </InternalExternalLink>
              <div className="topic-list--item-title">
                <InternalExternalLink story={item}>{item.title}</InternalExternalLink>
              </div>
              <div className="topic-list--item-summary">
                <Truncate lines={3} ellipsis={'...'}>{item.summary}</Truncate>
              </div>
              <StoryContentOptions {...item} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StoryPackage;
