import React, { Component } from 'react';
import SearchInput from 'components/elements/common/forms/searchinput/SearchInput';

import './FilterList.scss';

class FilterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
    };
  }

  handleChange(evt) {
    const filter = evt.target.value;
    this.props.setFilter(filter);
    this.props.filterList(filter);
    this.setState({ filter });
  }

  handleSubmit(evt) {
    evt.preventDefault();
    this.props.filterList(this.state.filter);
  }

  componentDidUpdate() {
    const { filter, filterList } = this.props;
    if (filter === '') {
      filterList(filter);
    }
  }

  render() {
    const { display, placeholder, className = '', filter } = this.props;
    if (!display) {
      return null;
    }

    return (
      <SearchInput
        name={placeholder}
        className={`filter-list ${className}`.trim()}
        value={filter}
        handleSubmit={evt => this.handleSubmit(evt)}
        onChange={evt => this.handleChange(evt)}
        placeholder={placeholder} />
    );
  }
}

export default FilterList;
