import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NewCollectionModal from 'elements/common/modal/collections/NewCollectionModal';
import PermissionButton from 'elements/common/permission/PermissionButton';
import CollectionsFilter from 'elements/common/collectionsfilter/CollectionsFilter';
import NavigationBar from 'elements/common/navigationbar/NavigationBar';

class CollectionsNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newCollectionOpen: false,
    };
  }

  openNewCollectionModal() {
    this.setState({ newCollectionOpen: true });
  }

  closeNewCollectionModal() {
    this.setState({ newCollectionOpen: false });
  }

  render() {
    const { newCollectionOpen } = this.state;
    return (
      <React.Fragment>
        <NavigationBar className="collections--nav" hideLayoutOptions
          leftSide={(
            <React.Fragment>
              <Link className="button--search" to="/search">Search</Link>
              <PermissionButton permission="collections.create" className="collections--nav--new-collection" onClick={() => this.openNewCollectionModal()}>New Collection</PermissionButton>
              <CollectionsFilter className="collections--nav--filter"/>
            </React.Fragment>
          )}
        />
        <NewCollectionModal open={newCollectionOpen} handleClose={() => this.closeNewCollectionModal()} afterSave={() => this.closeNewCollectionModal()}/>
      </React.Fragment>
    );
  }
}

export default CollectionsNav;
