import React from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './FeaturedSeries.scss';

const FeaturedSeries = (props) => {
  const { series, isContentPage } = props;

  const TitleElement = isContentPage ? 'div' : Link;

  const items = series.map((item, index) => (
    <div key={item.id || `featured-series-${index}`} className="video-featured-series--item">
      <img src={item.img} alt={item.title} />
      <div className="legend video-featured-series--item--info">
        <div className="video-featured-series--item--info-title">
          <TitleElement to={`/videos/series/content/${item.id}`}>{ item.title }</TitleElement>
        </div>
        <div className="video-featured-series--item--info-summary">
          { item.summary }
        </div>
      </div>
    </div>
  ));

  return (
    <div className="video-featured-series">
      <Carousel {...props}>
        { items }
      </Carousel>
    </div>
  );
};

export default FeaturedSeries;
