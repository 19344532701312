import React from 'react';
import './Radio.scss';

const Radio = ({ className, children, checked, ...attr }) => (
  // requires value, checked, onChange attributes
  <label className={`radio radio${!checked ? '-unchecked' : ''}`}>
    <input
      type="radio"
      checked={checked}
      className={`custom-radio-input ${className}`}
      {...attr}
    /> {children}
  </label>
);

export default Radio;
