import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showCollectionsModal } from 'store/actions/globalActions';
import PermissionButton from 'components/elements/common/permission/PermissionButton';

const CollectionOptions = ({ showModal, assets }) => (
  <div className="search-nav-collections">
    <Link to="/collections" className="button--my-collections">Collections</Link>
    <PermissionButton permission="collections.create" className="button--add-to-collection" onClick={showModal} disabled={!assets.length}>
      Add to Collection {!!assets.length && <span>({assets.length})</span>}
    </PermissionButton>
  </div>
);

const mapStateToProps = ({ collections }) => ({
  assets: collections.workQueue,
});

const mapDispatchToProps = {
  showModal: showCollectionsModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionOptions);
