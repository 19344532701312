import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchHomepage } from 'store/actions/homepage';
import Loading from 'components/elements/common/loading/Loading';
import { TITLE_BRAND } from 'components/utils/constants';
import ErrorView from 'elements/common/ErrorView';
import modules from './modules';
import Tagline from './Tagline';
import Page from '../Page';

import './Homepage.scss';

class Homepage extends Component {
  componentDidMount() {
    this.props.fetch();
  }

  renderModule(module, i) {
    const mercuryType =  (module.mercuryType || 'default').toLowerCase();
    const Element = modules[mercuryType] || modules.default;
    const key = `${mercuryType}_${i}`;
    return (
      <Element key={key} row={i} { ...module } />
    );
  }

  render() {
    const { layout, loading, error } = this.props;
    return (
      <Page className="homepage" title={TITLE_BRAND} pageType="homepage" error={error}>
        <Tagline />
        {loading && <Loading /> || layout.map((module, i) => this.renderModule(module, i))}
        {error && <ErrorView className="homepage-error" error={error} />}
      </Page>
    );
  }
}

const mapStateToProps = ({ homepage }) => ({
  loading: homepage.loading,
  error: homepage.error,
  layout: homepage.layout,
});

const mapDispatchToProps = dispatch => ({
  fetch: () => dispatch(fetchHomepage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
