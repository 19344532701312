import moment from 'moment';
import mtz from 'moment-timezone';
import saveAs from 'file-saver';
import { ASSET_TYPES } from './constants';

export const noop = () => {};

const defaultImg = '//placehold.it/-1x-1';
export const getCropImages = (imageUrl, crops) => ({
  desktop: (imageUrl || defaultImg).replace('-1x-1', crops.desktop),
  tablet: (imageUrl || defaultImg).replace('-1x-1', crops.tablet),
  mobile: (imageUrl || defaultImg).replace('-1x-1', crops.mobile),
});

export const getStoryTitle = (story = {}) => story.title || (story.headline && story.headline.title) || null;

export const validateForm = (inputNames, className = '') => {
  let valid = true;

  inputNames.forEach((name) => {
    const querySelector = `${className ? `.${className}` : ''} *[name=${name}]`.trim();
    const element = document.querySelector(querySelector);
    element.focus();
    element.blur();
    valid = valid && element.checkValidity();
  });

  return valid;
};

export const getAxiosError = (err) => {
  const error = err.response || err;
  return {
    status: error.status || 500,
    message: error.data || error.statusText || err.message,
  };
};

export const pluralize = str => (/s$/i.test(str) ? str : `${str}s`);
export const ucFirst = str => str.charAt(0).toUpperCase() + str.slice(1);
export const ellipsisSentence = (sentence, max) => {
  if (!sentence) {
    return '';
  }

  if (sentence.length < max) {
    return sentence;
  }

  return sentence.substring(0, max).replace(/(.*)\s+\S*$/, '$1...');
};

export const formatResultURL = (type, id) => `/${pluralize(type)}/${id}`;

export const formatCopyright = (copyright) => {
  const formattedCopyright = copyright || 'Bloomberg';
  if (formattedCopyright.includes('&copy;') || formattedCopyright.includes('©')) {
    return formattedCopyright.replace('&copy;', '©');
  }
  return `© ${formattedCopyright}`;
};

// from http://www.jacklmoore.com/notes/rounding-in-javascript/
export const roundFloat = (value, decimals) => Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);

export const formatNumber = (number = 0) => new Intl.NumberFormat().format(parseInt(number, 10));

export const isForbidden = (error = '') => [403, '403', 401, '401', 'Forbidden', 'Unauthorized'].includes(error);
export const isNotFound = (error = '') => [404, '404', 'not found'].includes(error) || /not found/ig.test(error);

export const dayRoundedDate = (days = 0, date = mtz.utc()) => date.add(days, 'd')
  .hour(0)
  .minute(0)
  .second(0)
  .millisecond(0)
  .toISOString();

// convert from nested structure to array structure:
// {topics: {ECO: {value: 'ECO', displayValue: 'economy'}, ENV: {value: 'ENV', displayValue: 'environment}} =>
// {topics: [{value: 'ECO', displayValue: 'economy'}, {value: 'ENV', displayValue: 'environment'}]
export const normalizeContextFilters = (contextFilters) => {
  const { topics = {}, companies = {}, people = {}, brands = {}, series = {} } = contextFilters;
  return {
    topics: Object.values(topics),
    companies: Object.values(companies),
    people: Object.values(people),
    brands: Object.values(brands),
    series: Object.values(series),
  };
};

const contextFilterReducer = context => context.reduce((result, { value, displayValue }) => {
  /* eslint  no-param-reassign: 0 */ // proper param re-assignment in this case
  result[value] = {
    value,
    displayValue,
  };
  return result;
}, {});

// exact opposite to normalizeContextFilters above (see example)
export const denormalizeContextFilters = (contextFilters) => {
  const { topics = [], companies = [], people = [], brands = [], series = [] } = contextFilters;
  return {
    topics: contextFilterReducer(topics),
    companies: contextFilterReducer(companies),
    people: contextFilterReducer(people),
    brands: contextFilterReducer(brands),
    series: contextFilterReducer(series),
  };
};

export const formatFilters = (filters, userFeeds = []) => {
  const { topics = {}, companies = {}, people = {}, series = {}, brands = [], ...normalizedFilters } = filters;
  normalizedFilters.contexts = normalizeContextFilters({ topics, companies, people, brands, series });
  const filterFeeds = filters.feeds || [];
  const filterLanguages = filters.languages || [];

  return {
    ...normalizedFilters,
    languages: Object.keys(filterLanguages).map(key => (filterLanguages[key] ? key : null)).filter(value => !!value),
    feeds: Object.keys(filterFeeds)
      .map(key => (filterFeeds[key] ? key : null))
      .filter(value => !!value)
      .map(feedId => ({ id: feedId, languages: (userFeeds.find(({ id }) => feedId === id) || { languages: [] }).languages })),
  };
};

export const userFilterReducer = userFilter => userFilter.reduce((result, { id }) => {
  /* eslint  no-param-reassign: 0 */ // proper param re-assignment in this case
  result[id] = true;
  return result;
}, {});

// Intersect 2 filter objects based on keys and returns an object that has the intersection
// Example: filters1 = { id1: v1, id2: v2 }, filters2 = { id2: v2, id3: v3 }
// intersectFilters(filters1, filters2) => { id2: v2 }
export const intersectFilters = (filters1, filters2) => {
  let filters = { ...filters1 };

  const filters1Ids = Object.keys(filters1);
  const filters2Ids = Object.keys(filters2);

  if (filters1Ids.some(id => !filters2Ids.includes(id))) {
    filters = filters1Ids.filter(id => filters2Ids.includes(id)).reduce((result, id) => {
      result[id] = filters1[id];
      return result;
    }, {});
  }
  return filters;
};

// Diffs 2 filter objects based on keys and returns an object that has the difference
// Example: filters1 = { id1: v1, id2: v2 }, filters2 = { id2: v2, id3: v3 }
// diffFilters(filters1, filters2) => { id1: v1 }
// diffFilters(filters2, filters1) => { id3: v3 }
export const diffFilters = (filters1, filters2) => {
  let filters = {};

  const filters1Ids = Object.keys(filters1);
  const filters2Ids = Object.keys(filters2);

  if (filters1Ids.some(id => !filters2Ids.includes(id))) {
    filters = filters1Ids.filter(id => !filters2Ids.includes(id)).reduce((result, id) => {
      result[id] = filters1[id];
      return result;
    }, {});
  }
  return filters;
};

// from https://stackoverflow.com/questions/5379120/get-the-highlighted-selected-text
export const getSelectedText = /* istanbul ignore next */ () => {
  let selectedText = '';
  if (window && typeof window.getSelection !== 'undefined') {
    selectedText = window.getSelection().toString();
  } else if (document && typeof document.selection !== 'undefined' && document.selection.type === 'Text') {
    const { text } = document.selection.createRange();
    selectedText = text;
  }
  return selectedText;
};

export const assembleUrl = (id, mediaType) => `${typeof location !== 'undefined' ? location.origin  /* istanbul ignore next */ : ''}/${pluralize(mediaType)}/${id}`; // eslint-disable-line

export const filterRegex = filter => new RegExp(filter.trim(), 'i');

const cookieSettings = {
  path: '/',
  secure: process.env.NODE_ENV !== 'development',
};

export const loginCookieSettings = {
  ...cookieSettings,
  maxAge: process.env.SESSION_EXPIRATION || 60 * 60 * 48, // 2 days
};

export const getLocalStorageItem = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key)) || {};
  } catch (e) {
    console.error(`Failed to get item from local storage with key: ${key}`, e);
    return {};
  }
};

export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error(`Failed to set item into local storage with key: ${key}`, e);
  }
};

export const updateLocalStorageItem = (key, value, propertyName) => {
  try {
    const itemToUpdate = JSON.parse(localStorage.getItem(key));
    if (propertyName) {
      localStorage.setItem(key, JSON.stringify({ ...itemToUpdate, [propertyName]: value }));
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (e) {
    console.error(`Failed to update item in local storage with key: ${key}`, e);
  }
};

/* istanbul ignore next */
export const host = () => /* istanbul ignore next */ window && window.location && window.location.host || ''; // eslint-disable-line

export const isInternalLink = ({ id, url }) => {
  if (id) {
    return true;
  }

  const hostname = host();
  return url && url.includes(hostname) || url && hostname === 'mercury.btogo.com' || false;
};

export const formatLink = ({ id, url, type = 'news' }) => {
  const knownTypes = Object.keys(ASSET_TYPES);
  const safeType = knownTypes.includes(type) ? type : 'news';
  if (id) {
    return `/${pluralize(safeType)}/${id}`;
  }

  let hostname = host();
  if (hostname === 'mercury.btogo.com') {
    hostname = 'mercury.bloomberg.com'; // special case for btogo users
  }
  return url && url.split(hostname).pop() || hostname;
};

export const applyClassname = (className, bem) => (className ? `${className}${bem}` : '');

export const isInvalidDate = date => !(date instanceof Date) || date.toString() === 'Invalid Date';

export const formatLocation = (city, state, country) => [city, state, country].filter(item => !!item).join(', ');

export const formatDuration = (durationMillis) => {
  if (!durationMillis) {
    return '00:00';
  }
  const duration = moment.duration(Number(durationMillis));
  return moment(duration.as('milliseconds')).format('mm:ss');
};

export const getVideoErrorMsg = (status, message) => (status === 404 ? status : message);

export const getVideoPlayedPercentage = (player) => {
  const currentTime = parseInt(player.currentTime && player.currentTime(), 10) || 0;
  const duration =  parseInt(player.duration && player.duration(), 10) || 1;
  return `${Math.trunc((currentTime / duration) * 100)}%`;
};

export const saveToFile = (data, encoding, fileName, uriDirectDownload = false) => {
  const content = uriDirectDownload ? data : new Blob([data], { type: encoding });
  saveAs(content, fileName);
};

// allow filter action (select/deselect) if it does not result in a filterSet that is fully deselected
export const allowFilterAction = (filterSet, select) => select || Object.values(filterSet).filter(({ value }) => value).length > 1;

export const validatePasswordMatch = (password, confirmPassword) => ((password.length && confirmPassword.length && password !== confirmPassword) ? 'Passwords do not match' : false);
