import React, { Component } from 'react';
import { connect } from 'react-redux';
import mtz from 'moment-timezone';
import { withRouter } from 'react-router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import TextInput from 'common/forms/TextInput';
import Checkbox from 'common/forms/checkbox/Checkbox';
import Loading from 'common/loading/Loading';
import ErrorView from 'elements/common/ErrorView';
import { PERMISSIONS } from 'components/utils/constants';
import { updateCollection, clearCollectionError } from 'store/actions/collection';
import { dayRoundedDate, assembleUrl } from 'components/utils/helpers';
import Modal from '../Modal';

import './ManageCollectionModal.scss';

let initState;
const DEFAULT_DAYS_TO_EXPIRY = 15;

const daysToExpiry = (expireAt) => {
  if (!expireAt) {
    return DEFAULT_DAYS_TO_EXPIRY;
  }
  const expiryDate = new Date(expireAt);
  const roundedExpiryDate = dayRoundedDate(0, mtz.utc(expiryDate));
  const roundedExpiryMoment = mtz.utc(new Date(roundedExpiryDate));

  const daysLeft = roundedExpiryMoment.diff(mtz.utc(dayRoundedDate()), 'days');
  return daysLeft <= 0 ? 0 : daysLeft;
};

class ManageCollectionModal extends Component {
  constructor(props) {
    super(props);
    const { name, permission, public: isPublic, expireAt } = props.collection;
    initState = { newName: name, isPublic, expires: !!expireAt, days: daysToExpiry(expireAt), saveAsCopy: permission === PERMISSIONS.view };
    this.state = { ...initState };
  }

  onNameChange({ target }) {
    this.setState({ newName: target.value });
  }

  onSaveAsChange({ target }) {
    this.setState({ saveAsCopy: target.checked });
  }

  toggleIsPublic(isPublic) {
    this.setState({ isPublic });
  }

  onToggleExpires({ target }) {
    this.setState({ expires: target.checked });
  }

  updateDays(value) {
    // insure days not assigned to negative values
    if (value <= 0 && this.state.days <= 0) {
      return;
    }
    this.setState(prevState => ({ days: prevState.days + value }));
  }

  hasDelta() {
    let stateChanged = false;
    Object.keys(this.state).forEach((key) => {
      if (this.state[key] !== initState[key]) {
        stateChanged = true;
      }
    });
    return stateChanged;
  }

  async save(id) {
    const { newName, isPublic, expires, days, saveAsCopy } = this.state;
    const { update, history, handleClose } = this.props;

    if (!(saveAsCopy || this.hasDelta())) {
      handleClose();
      return false;
    }
    const collectionId = await update(id, { name: newName, isPublic, expires, days, saveAsCopy });

    if (collectionId) {
      if (saveAsCopy) {
        history.push(`/collections/${collectionId}`);
      } else {
        handleClose();
      }
    }
    return true;
  }

  cancel() {
    this.setState({ ...initState });
    this.props.clearError();
    this.props.handleClose();
  }

  render() {
    const { open, handleClose, loading, collection, userLevel, error } = this.props;
    const { expires, days, isPublic, newName, saveAsCopy } = this.state;
    const { id, permission } = collection;
    const url = assembleUrl(id, 'collection');

    return (
      <Modal className="manage-collections-modal" theme="dark" open={open} handleClose={handleClose}>
        <h4>Manage Collection</h4>
        {loading && <Loading />}
        {error && <ErrorView className="collections-new-error" error={error} />}
        <div className="manage-collections-modal--basics">
          <TextInput value={newName} label="Collection:" onChange={evt => this.onNameChange(evt)}/>
          <Checkbox checked={saveAsCopy} name="saveAsCopy" disabled={permission === PERMISSIONS.view} onChange={evt => this.onSaveAsChange(evt)}>Save as Copy</Checkbox>
        </div>

        {userLevel === PERMISSIONS.admin && permission !== PERMISSIONS.view
        && <div className="manage-collections-modal--admin">
          <div className="manage-collections-modal--admin__access">
            <label>Access:</label>
            <Checkbox className="manage-collections-modal--admin__public" checked={isPublic} onChange={() => this.toggleIsPublic(true)}>Public</Checkbox>
            <Checkbox className="manage-collections-modal--admin__private" checked={!isPublic} onChange={() => this.toggleIsPublic(false)}>Private</Checkbox>
          </div>

          <div className="manage-collections-modal--admin__expirations">
            <div className="manage-collections-modal--admin__expires">
              <Toggle className="manage-collections-modal--admin__toggle accent-toggle" checked={expires} icons={false} onChange={evt => this.onToggleExpires(evt)}/>
              <span>Expiration Date</span>
            </div>

            {expires
            && <div className="manage-collections-modal--admin__expires-at">
              <button className="manage-collections-modal--admin__decrement" type="button" onClick={() => this.updateDays(-1)}>-</button>
              <span>{days} Days</span>
              <button className="manage-collections-modal--admin__increment" type="button" onClick={() => this.updateDays(1)}>+</button>
            </div>
            }
          </div>
        </div>
        }

        <div className="manage-collections-modal--copy-link">
          <label>Access Url:</label>
          <span>{url}</span>
          <CopyToClipboard text={url}><button>Copy URL</button></CopyToClipboard>
        </div>

        <div className="manage-collections-modal--actions">
          <button className="button-cancel" type="button" onClick={() => this.cancel()}>Cancel</button>
          <button className="button-contact" type="button" onClick={() => this.save(id)}>Save</button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ collection, user }) => ({
  userLevel: user.user.level,
  loading: collection.loading,
  error: collection.error,
});

const mapDispatchToProps = {
  update: updateCollection,
  clearError: clearCollectionError,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageCollectionModal));
