import axios from 'axios';
import { getAxiosError } from 'components/utils/helpers';
import {
  SET_VIDEO_SERIES_CATALOG_ERROR,
  SET_VIDEO_SERIES_CATALOG_LOADING,
  SET_VIDEO_SERIES_CATALOG_SERIES,
} from '../reducers/videoSeriesCatalog';

export const VIDEO_SERIES_CATALOG_TIMEOUT = process.env.VIDEO_SERIES_CATALOG_TIMEOUT || 10000;

export const setVideoSeriesCatalogError = error => ({
  type: SET_VIDEO_SERIES_CATALOG_ERROR,
  data: { error },
});

export const setVideoSeriesCatalogLoading = (loading = true) => ({
  type: SET_VIDEO_SERIES_CATALOG_LOADING,
  data: { loading },
});

export const setVideoSeriesCatalogSeries = (series = []) => ({
  type: SET_VIDEO_SERIES_CATALOG_SERIES,
  data: { series },
});

export const getVideoSeriesCatalog = type => async (dispatch) => {
  if (!type) return;

  try {
    dispatch(setVideoSeriesCatalogSeries([]));
    dispatch(setVideoSeriesCatalogLoading());
    const { data } = await axios.get(`/api/videos/series/catalog/${type}`, { timeout: VIDEO_SERIES_CATALOG_TIMEOUT });
    dispatch(setVideoSeriesCatalogSeries(data));
  } catch (err) {
    const { status, message } = getAxiosError(err);
    const errorMsg = status === 404 ? status : message;
    dispatch(setVideoSeriesCatalogError(errorMsg));
    console.error(`error getting video series data for type: ${type}; message: ${JSON.stringify(message)}`);
  } finally {
    dispatch(setVideoSeriesCatalogLoading(false));
  }
};
