export const SET_ANNOUNCEMENT = 'redux/announcement/SET_ANNOUNCEMENT';
export const FETCH_ANNOUNCEMENT_ERROR =  'redux/announcement/FETCH_ANNOUNCEMENT_ERROR';

export const initialState = {
  announcement: {},
  error: null,
};

const announcement = (state = initialState, action) => {
  switch (action.type) {
    case SET_ANNOUNCEMENT:
      return {
        ...state,
        announcement: action.data.announcement,
      };
    case FETCH_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        error: action.data.error,
      };
    default:
      return state;
  }
};

export default announcement;
