import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Dropdown from 'components/elements/common/dropdown/Dropdown';
import { logoutUser } from 'store/actions/user';

import './UserActions.scss';

/* istanbul ignore next */
const FEATURE_QUOTA_ENABLED = JSON.parse(process.env.FEATURE_QUOTA_ENABLED || false) === true;
/* istanbul ignore next */
const FEATURE_PREFERENCES_ENABLED = JSON.parse(process.env.FEATURE_PREFERENCES_ENABLED || false) === true;

class UserActions extends React.Component {
  logout() {
    this.props.logout();
  }

  renderUserDropdown() {
    const { firstName } = this.props;
    const title = (
      <span className="dropdown-user--icon" aria-label={firstName}>
        <span>{firstName}</span>
      </span>
    );

    return (
      <Dropdown title={title}>
        <ul>
          {FEATURE_PREFERENCES_ENABLED && <li><Link to='/preferences'>Preferences</Link></li>}
          {FEATURE_QUOTA_ENABLED && <li><Link to='/usage'>Usage</Link></li>}
          <li><Link to='/' onClick={() => this.logout()}>Logout</Link></li>
        </ul>
      </Dropdown>
    );
  }

  renderInitialUserActions() {
    return (
      <React.Fragment>
        <Link className="user-actions__sign-in" to="/login">Sign In</Link>
        <Link className="user-actions__register" to="/registration">Register</Link>
      </React.Fragment>
    );
  }

  render() {
    const { firstName } = this.props;

    return (
      <div className="user-actions">
        {firstName ? this.renderUserDropdown() : this.renderInitialUserActions()}
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  firstName: user.user.firstName,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserActions);
