import React from 'react';

const ErrorView = ({ className, error }) => {
  const message = typeof error === 'object' ? error.code || error.error : error || 'Unexpected error';
  const correlationId = typeof error === 'object' ? error.correlationId : false;

  return (
    <div className={`error ${className}`.trim()}>
      <span className="error__message">{message}</span>
      {correlationId && <h4 className="error__id">ID: {correlationId}</h4>}
    </div>
  );
};

export default ErrorView;
