import React from 'react';
import ShareButton from 'elements/common/permission/ShareButton';
import { PERMISSIONS } from 'components/utils/constants';

const CollectionActions = ({ id, name, permission, openDeleteModal, openManageCollection, openDownloadAllModal }) => (
  <React.Fragment>
    <ShareButton className="button button--share" mediaType="collection" id={id} name={name}/>
    <button className="button button--download" onClick={() => openDownloadAllModal(id)}>Download</button>
    <button className="button button--saveas"
            onClick={() => openManageCollection({ id, name, permission })}>Save/Edit
    </button>
    {permission === PERMISSIONS.own
    && <button className="button--delete" onClick={() => openDeleteModal({ id, name })}>Delete</button>}
  </React.Fragment>
);

export default CollectionActions;
