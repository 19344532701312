export const CONTACT_US_FORM_UPDATE = 'redux/contactus/CONTACT_US_FORM_UPDATE';
export const CONTACT_US_FORM_SUBMITTING = 'redux/contactus/CONTACT_US_FORM_SUBMITTING';
export const CONTACT_US_FORM_NOT_SUBMITTING = 'redux/contactus/CONTACT_US_FORM_NOT_SUBMITTING';
export const CONTACT_US_FORM_SUCCESS = 'redux/contactus/CONTACT_US_FORM_SUCCESS';
export const CONTACT_US_FORM_ERROR = 'redux/contactus/CONTACT_US_FORM_ERROR';
export const CONTACT_US_FORM_CLEAR_ERROR = 'redux/contactus/CONTACT_US_FORM_CLEAR_ERROR';
export const CONTACT_US_FORM_RESET = 'redux/contactus/CONTACT_US_FORM_RESET';
export const CONTACT_US_FORM_FLAG_RESET = 'redux/contactus/CONTACT_US_FORM_FLAG_RESET';

export const initialForm = {
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  country: '',
  topic: '',
  subject: '',
  comment: '',
};

export const initialState = {
  form: initialForm,
  formSubmitting: false,
  formSubmissionSuccessful: false,
  formSubmissionError: null,
};

const contactUs = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_US_FORM_UPDATE:
      return {
        ...state,
        form: {
          ...state.form,
          [action.data.name]: action.data.value,
        },
      };
    case CONTACT_US_FORM_SUBMITTING:
      return {
        ...state,
        formSubmitting: true,
      };
    case CONTACT_US_FORM_NOT_SUBMITTING:
      return {
        ...state,
        formSubmitting: false,
      };
    case CONTACT_US_FORM_SUCCESS:
      return {
        ...state,
        formSubmissionSuccessful: true,
      };
    case CONTACT_US_FORM_ERROR:
      return {
        ...state,
        formSubmissionError: action.data.error,
      };
    case CONTACT_US_FORM_CLEAR_ERROR:
      return {
        ...state,
        formSubmissionError: null,
      };
    case CONTACT_US_FORM_RESET:
      return {
        ...state,
        form: initialForm,
      };
    case CONTACT_US_FORM_FLAG_RESET:
      return {
        ...state,
        formSubmitted: false,
        formSubmissionSuccessful: false,
        formSubmissionError: null,
      };
    default:
      return state;
  }
};

export default contactUs;
