import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from 'components/elements/common/loading/Loading';
import Item from './Item';
import EmptyItems from './EmptyItems';

import './Items.scss';

const Items = ({ className, totals, results, hasMore, loading, loadMore, ListItem, GridItem, defaultLayout }) => {
  if (!loading && !results.length && Object.keys(totals).length) {
    return <EmptyItems />;
  }

  return (
    <InfiniteScroll
      loadMore={loadMore}
      hasMore={hasMore}
      loader={<div key={0} className="items-loader"><Loading /></div>}
      threshold={500}
      className={`items ${className}`}
      useWindow={false}>

      {results.map(result => <Item
          key={`${result.revision || result.id}_${result.batchId}_${result.type}`}
          item={result}
          ListItem={ListItem}
          GridItem={GridItem}
          defaultLayout={defaultLayout}
        />,
      )}
      {loading && !results.length && <Loading />}
    </InfiniteScroll>
  );
};

export default Items;
