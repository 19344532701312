import React from 'react';
import GridItem from 'components/elements/common/griditem/GridItem';
import Contents from 'components/elements/pages/collections/CollectionContents';
import Actions from './CollectionActions';

import './Collection.scss';

const CollectionGridItem = props => (
  <GridItem className="collections-collection" type="collection" id={props.id} title={props.name} contents={<Contents {...props} />} actions={<Actions {...props} />} />
);

export default CollectionGridItem;
