import axios from 'axios';
import { getAxiosError } from 'components/utils/helpers';
import { QUICK_FIND_BASE_MENU } from 'components/utils/constants';
import {
  QUICK_FIND_SET_DROPDOWN_OPEN,
  QUICK_FIND_SET_SELECTED,
  QUICK_FIND_SET_COLUMN_OPEN,
  QUICK_FIND_RESET,
  QUICK_FIND_UPDATE_MENU,
  QUICK_FIND_SET_SUBMENU_OPEN,
} from '../reducers/quickFind';

export const setDropdownOpen = (open = true) => ({
  type: QUICK_FIND_SET_DROPDOWN_OPEN,
  data: { open },
});

export const setSelected = (column, selected) => ({
  type: QUICK_FIND_SET_SELECTED,
  data: { column, selected },
});

export const setColumnOpen = (column, open = true) => ({
  type: QUICK_FIND_SET_COLUMN_OPEN,
  data: { column, open },
});

export const setSubMenuOpen = open => ({
  type: QUICK_FIND_SET_SUBMENU_OPEN,
  data: { open },
});

export const updateMenu = menu => ({
  type: QUICK_FIND_UPDATE_MENU,
  data: { menu },
});

export const quickFindReset = () => ({
  type: QUICK_FIND_RESET,
});

export const getMenu = () => async (dispatch, getState) => {
  const { menu } = getState().quickFind;
  if (menu.length > QUICK_FIND_BASE_MENU.length) {
    return;
  }

  try {
    const { data } = await axios.get('/api/catalogs/menu');
    dispatch(updateMenu(data));
  } catch (err) {
    const { message } = getAxiosError(err);
    console.warn(`Failed to fetch menu: ${message}`, err);
  }
};
