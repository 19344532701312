import React from 'react';
import ImageAttachment from './ImageAttachment';
import VideoAttachment from './VideoAttachment';

const Attachments = ({ attachments, revision }) => {
  if (!attachments.length) {
    return null;
  }

  return (
    <div className="news--attachments">
      <h4>Attached Media</h4>
      <div className="news--attachments__attachments">
        {attachments.map(attachment => (attachment.type === 'image'
          ? <ImageAttachment key={`${attachment.type}_${attachment.id}`} revision={revision} attachment={attachment} />
          : <VideoAttachment key={`${attachment.type}_${attachment.id}`} revision={revision} attachment={attachment} />),
        )}
      </div>
    </div>
  );
};

export default Attachments;
