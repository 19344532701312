import axios from 'axios';
import { getAxiosError } from 'components/utils/helpers';
import {
  SET_HOMEPAGE_LOADING,
  SET_HOMEPAGE_ERROR,
  SET_HOMEPAGE,
} from '../reducers/homepage';

const HOMEPAGE_TIMEOUT = process.env.HOMEPAGE_TIMEOUT || 10000;

export const setHomepageLoading = () => ({
  type: SET_HOMEPAGE_LOADING,
});

export const setHomepage = layout => ({
  type: SET_HOMEPAGE,
  data: {
    layout,
  },
});

export const setHomepageError = error => ({
  type: SET_HOMEPAGE_ERROR,
  data: { error },
});

export const fetchHomepage = () => async (dispatch, getState) => {
  const { homepage: { layout } } = getState();
  if (layout.length) {
    return;
  }

  dispatch(setHomepageLoading());
  try {
    const { data } = await axios.get('/api/homepage', { timeout: HOMEPAGE_TIMEOUT });
    dispatch(setHomepage(data));
  } catch (err) {
    const { message } = getAxiosError(err);
    dispatch(setHomepageError(message));
  }
};
