import React from 'react';
import LayoutOptions from './LayoutOptions';
import './NavigationBar.scss';

const NavigationBar = props => (
  <header className={`navigation-bar ${props.className || ''}`.trim()}>
    <div className="navigation-bar--left">
      {props.leftSide}
    </div>
    <div className="navigation-bar--center">
      {props.center}
    </div>
    <div className="navigation-bar--right">
      {!props.hideLayoutOptions && <LayoutOptions />}
      {props.rightSide}
    </div>
  </header>
);

export default NavigationBar;
