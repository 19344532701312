import { filterRegex } from 'utils/helpers';

export const SET_USER_LOADING = 'redux/user/SET_USER_LOADING';
export const SET_USER_ERROR = 'redux/user/SET_USER_ERROR';
export const SET_USER = 'redux/user/SET_USER';
export const RESET_USER = 'redux/user/RESET_USER';
export const SET_ATTEMPTED_AUTO_SIGNIN = 'redux/user/SET_ATTEMPTED_AUTO_SIGNIN';
export const SET_ACCOUNT_UNLOCKED = 'redux/user/SET_ACCOUNT_UNLOCKED';
export const UPDATE_USER_PERMISSIONS = 'redux/user/UPDATE_USER_PERMISSIONS';
export const RESET_USER_ERROR = 'redux/user/RESET_USER_ERROR';
export const SET_USER_PREFERENCES = 'redux/user/SET_USER_PREFERENCES';
export const SET_PUBLICATION_FEEDS = 'redux/user/SET_PUBLICATION_FEEDS';
export const FILTER_DISPLAY_PUBLICATION_FEEDS = 'redux/user/FILTER_DISPLAY_PUBLICATION_FEEDS';
export const RESET_DISPLAY_PUBLICATION_FEEDS = 'redux/user/RESET_DISPLAY_PUBLICATION_FEEDS';

export const initialState = {
  loading: false,
  user: {},
  error: false,
  attemptedAutoSignin: false,
  unlockKeyGenerated: false,
  accountUnlocked: false,
  displayPublicationFeeds: [], // better kept outside the user scope since they are not part of core user data
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case SET_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data.error,
      };
    case SET_USER:
      return {
        ...state,
        error: false,
        loading: false,
        user: { ...action.data.user, publicationFeeds: [] },
      };
    case RESET_USER:
      return { ...initialState };
    case SET_ATTEMPTED_AUTO_SIGNIN:
      return {
        ...state,
        attemptedAutoSignin: action.data.attemptedAutoSignin,
      };
    case UPDATE_USER_PERMISSIONS:
      return {
        ...state,
        user: {
          ...state.user,
          permissions: action.data.permissions,
        },
      };
    case SET_ACCOUNT_UNLOCKED:
      return {
        ...state,
        error: action.data.error || '',
        loading: action.data.loading || false,
        unlockKeyGenerated: action.data.unlockKeyGenerated || false,
        accountUnlocked: action.data.accountUnlocked || false,
      };
    case RESET_USER_ERROR:
      return {
        ...state,
        error: false,
      };
    case SET_USER_PREFERENCES:
      return {
        ...state, // todo I think we need to set loading to false here
        user: {
          ...state.user,
          preferences: action.data.preferences,
        },
      };
    case SET_PUBLICATION_FEEDS:
      return {
        ...state,
        loading: false,
        displayPublicationFeeds: [...action.data.publicationFeeds],
        user: {
          ...state.user,
          publicationFeeds: [...action.data.publicationFeeds],
        },
      };
    case FILTER_DISPLAY_PUBLICATION_FEEDS:
      return {
        ...state,
        displayPublicationFeeds: state.user.publicationFeeds.filter(({ name }) => filterRegex(action.data.filter).test(name)),

      };
    case RESET_DISPLAY_PUBLICATION_FEEDS:
      return {
        ...state,
        displayPublicationFeeds: [...state.user.publicationFeeds],
      };
    default:
      return state;
  }
};

export default user;
