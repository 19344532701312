import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { trackCustomEvent } from 'utils/analytics';
import { closeQuotaExceededModal } from 'store/actions/globalActions';
import Modal from '../Modal';

import './QuotaExceededModal.scss';

class QuotaExceededModal extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      trackCustomEvent({
        category: 'upsell',
        action: 'popup',
        label: 'quotaexceeded',
      });
    }
  }

  render() {
    const { open, close } = this.props;
    return (
      <Modal className="quota-exceeded-modal" theme="dark" open={open} handleClose={() => close()}>
        <p>You have reached your download limit.
        <br/>Please contact your sales representative for more access.</p>
        <p><Link to={'/contact-us'} className="button-contact" onClick={() => this.props.closeQuotaExceededModal()}>Send Request</Link></p>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  closeQuotaExceededModal,
};

export default connect(null, mapDispatchToProps)(QuotaExceededModal);
