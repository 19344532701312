import React, { Component } from 'react';
import { connect } from 'react-redux';
import { validateForm } from 'components/utils/helpers';
import { updateForm, submitContactUsForm, setFormError, clearFormError, resetFormFlags } from 'store/actions/contactUs';
import { trackCustomEvent } from 'utils/analytics';
import Page from '../Page';
import ErrorView from '../../common/ErrorView';
import EmailInput from '../../common/forms/EmailInput';
import TextInput from '../../common/forms/TextInput';
import TextareaInput from '../../common/forms/textareainput/TextareaInput';
import SelectDropdown from '../../common/forms/selectdropdown/SelectDropdown';
import countries from '../../../data/countries.json'; // taken from https://bbgithub.dev.bloomberg.com/Consumer-Web/bloomberg-registration/blob/master/common/data/countries.json

import './ContactUs.scss';

const countryOptions = countries.map(({ key }) => key);
const topicOptions = [
  'Request a Trial',
  'Report a Bug',
  'General Question',
  'Request Training',
  'Functionality Question',
  'Account/Billing Question',
  'Add News Access',
  'Add Photo Access',
  'Add Video Access',
];

export class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.props.resetFormFlags();
  }

  componentDidMount() {
    const { firstName, lastName, email, firmName, country } = this.props.user;

    if (firstName) {
      this.props.updateForm('firstName', firstName);
    }
    if (lastName) {
      this.props.updateForm('lastName', lastName);
    }
    if (email) {
      this.props.updateForm('email', email);
    }
    if (firmName) {
      this.props.updateForm('company', firmName);
    }
    if (country) {
      this.props.updateForm('country', country);
    }
  }

  onChange({ target: { name, value } }) {
    if (this.props.formSubmissionError) {
      this.props.clearFormError();
    }

    this.props.updateForm(name, value);
  }

  validateForm() {
    return validateForm(Object.keys(this.props.form));
  }

  async onSubmit(e) {
    e.preventDefault();

    if (this.validateForm()) {
      try {
        trackCustomEvent({
          category: 'upsell',
          action: 'contact',
          label: 'contactus',
        });

        await this.props.submitContactUsForm(this.props.form);
      } catch (err) {
        this.props.setFormError(err);
      }
    }
  }

  render() {
    const { error, form, formSubmitting, formSubmissionSuccessful, formSubmissionError } = this.props;
    return (
      <Page className="contact-us" title="contact us" pageType="contact-us">
        {!error
          && <React.Fragment>
            <h1>Contact Us</h1>
            <form method="post" id="contact-us-form" className="contact-us-form" onSubmit={evt => this.onSubmit(evt)}>
              <div className="contact-us-form-columns">
                <TextInput label="First Name" name="firstName" value={form.firstName} onChange={evt => this.onChange(evt)} required />
                <TextInput label="Last Name" name="lastName" value={form.lastName} onChange={evt => this.onChange(evt)} required />
              </div>
              <EmailInput label="Email" name="email" placeholder="name@example.com" value={form.email} onChange={evt => this.onChange(evt)} required />
              <TextInput label="Company" name="company" value={form.company} onChange={evt => this.onChange(evt)} />
              <SelectDropdown label="Country" name="country" value={form.country} options={countryOptions} onChange={evt => this.onChange(evt)} emptyOption />
              <SelectDropdown label="Topic" name="topic" value={form.topic} options={topicOptions} onChange={evt => this.onChange(evt)} emptyOption required />
              <TextInput label="Subject" name="subject" value={form.subject} onChange={evt => this.onChange(evt)} required />
              <TextareaInput label="Question / Comment" name="comment" value={form.comment} className="contact-us-form-textarea" rows={5} onChange={evt => this.onChange(evt)} />
              <div className="contact-us-form-submit">
                <button type="submit" onClick={evt => this.onSubmit(evt)} className="button-submit" disabled={formSubmitting}>
                  Submit
                </button>
              </div>
              { !formSubmitting && formSubmissionSuccessful && <div>Mail Sent Successfully</div> }
              { !formSubmitting && formSubmissionError && <div>Error Sending Mail. Please contact newsservice@bloomberg.com</div> }
            </form>
          </React.Fragment>
        }
        {error && <ErrorView error={error} />}
      </Page>
    );
  }
}

const mapStateToProps = ({ contactUs: { form, formSubmitting, formSubmissionSuccessful, formSubmissionError }, user: { user } }) => ({
  user,
  form,
  formSubmitting,
  formSubmissionSuccessful,
  formSubmissionError,
});

const mapDispatchToProps = {
  updateForm,
  resetFormFlags,
  setFormError,
  clearFormError,
  submitContactUsForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
