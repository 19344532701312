import React from 'react';
import { connect } from 'react-redux';
import { addNewCollection, clearCollectionsError } from 'store/actions/collections';

import NewItemModal from '../newitemmodal/NewItemModal';

export const NewCollectionModal = props => (
  <NewItemModal className="collection-new" label="New Collection" createItem={props.createCollection} {...props} />
);

const mapStateToProps = ({ collections }) => ({
  loading: collections.loading,
  error: collections.error,
});

const mapDispatchToProps = {
  createCollection: addNewCollection,
  clearError: clearCollectionsError,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCollectionModal);
