import React from 'react';
import { ellipsisSentence } from 'components/utils/helpers';
import './SecondaryFeaturedSeries.scss';
import { Link } from 'react-router-dom';
import { formatLink } from '../../../../utils/helpers';

const SecondaryFeaturedSeries = (props) => {
  const { series } = props;
  const items = series.map(item => (
    <Link key={item.id} to={formatLink({ url: item.url })} className="secondary-featured-series--item">
      <img src={item.img} alt={item.title} />
      <div className="secondary-featured-series--item-summary no-ellipsis">{item.summary}</div>
      <div className="secondary-featured-series--item-summary ellipsis">{ellipsisSentence(item.summary, 100)}</div>
    </Link>
  ));

  return (
    <div className="secondary-featured-series">
      {items}
    </div>
  );
};

export default SecondaryFeaturedSeries;
