import axios from 'axios';
import { getAxiosError, dayRoundedDate } from 'components/utils/helpers';
import {
  SET_COLLECTION_LOADING,
  SET_COLLECTION_ERROR,
  SET_COLLECTION,
  REMOVE_ASSETS_FROM_COLLECTION_DETAILS,
} from '../reducers/collection';
import {
  addCollection,
  setCollectionsLoading,
  updateCollection as updateCollectionSet,
  resetAssetQueue,
  handleCollectionError,
  updateAssetDetails,
  fetchCollections,
} from './collections';
import { PERMISSIONS } from '../../components/utils/constants';

const TIMEOUT = process.env.SIGNIN_TIMEOUT || 10000;

export const setCollectionLoading = (loading = true) => ({
  type: SET_COLLECTION_LOADING,
  data: { loading },
});

export const setCollectionError = error => ({
  type: SET_COLLECTION_ERROR,
  data: { error },
});

export const setCollection = collection => ({
  type: SET_COLLECTION,
  data: { collection },
});

export const resetCollection = () => ({
  type: SET_COLLECTION,
  data: { collection: {} },
});

export const removeAssetsFromCollectionDetails = assets => ({
  type: REMOVE_ASSETS_FROM_COLLECTION_DETAILS,
  data: { assets },
});

export const clearCollectionError = () => async (dispatch) => {
  dispatch(setCollectionError(false));
};

export const addAssetsToCollection = collectionId => async (dispatch, getState) => {
  dispatch(setCollectionsLoading());

  try {
    const { workQueue } = getState().collections;
    const assets = workQueue.map(({ assetId, assetType }) => ({ assetId, assetType }));
    const alteredCount = assets.length;
    await axios.put(`/api/collections/${collectionId}/assets`, { assets }, { timeout: TIMEOUT });

    dispatch(setCollectionsLoading(false));
    dispatch(updateAssetDetails(collectionId, alteredCount, workQueue[0].thumb));
    return true;
  } catch (err) {
    dispatch(handleCollectionError(err));
    return false;
  }
};

export const removeAssetsFromCollection = collectionId => async (dispatch, getState) => {
  dispatch(setCollectionLoading());

  try {
    const { workQueue: assets } = getState().collections;
    let alterCount = assets.length;

    alterCount = -alterCount;
    await axios.delete(`/api/collections/${collectionId}/assets`, {
      data: { assets },
      timeout: TIMEOUT,
    });

    dispatch(removeAssetsFromCollectionDetails([...getState().collections.workQueue]));
    dispatch(resetAssetQueue());

    dispatch(setCollectionLoading(false));
    dispatch(updateAssetDetails(collectionId, alterCount));
    return true;
  } catch (err) {
    const { message } = getAxiosError(err);
    dispatch(setCollectionError(message));
    return false;
  }
};

export const updateCollection = (id, attributes) => async (dispatch, getState) => {
  const { name, expires, days, isPublic, saveAsCopy } = attributes;
  let update  = {
    name,
  };

  const userLevel = getState().user.user.level;
  if (userLevel === PERMISSIONS.admin) {
    const adminUpdate = {
      expireAt: expires ? dayRoundedDate(days) : null,
      public: isPublic,
    };
    update = { ...update, ...adminUpdate };
  }
  // this can be null if id is for a shared collection that the user does not have
  const collection = getState().collections.collections.find(c => c.id === id);
  const { collection: { assets } } = getState().collection;
  dispatch(setCollectionLoading());

  try {
    const newCollection = { ...collection, ...update, assets, permission: PERMISSIONS.own };
    // user can save as copy a collection he does not own; common-services will make him an owner of the collection
    if (saveAsCopy) {
      const { data: { id: collectionId } } = await axios.post(`/api/collections/${id}/copy`, update, { timeout: TIMEOUT });
      newCollection.id = collectionId;

      const { collections: { collections } } = getState();
      // we can access a shared collection url before loading collections
      if (!collections.length) {
        dispatch(fetchCollections());
      } else {
        dispatch(addCollection(newCollection));
      }
    } else {
      // mars will fail this call if user is not the owner of the collection
      await axios.put(`/api/collections/${id}`, update, { timeout: TIMEOUT });
      dispatch(updateCollectionSet(newCollection));
    }
    dispatch(setCollection(newCollection));
    return newCollection.id;
  } catch (err) {
    const { message } = getAxiosError(err);
    dispatch(setCollectionError(message));
    return false;
  }
};

export const getCollection = id => async (dispatch, getState) => {
  if (getState().collection.loading) {
    return;
  }

  dispatch(setCollectionLoading());
  try {
    const { data } = await axios.get(`/api/collections/${id}`, { timeout: TIMEOUT });
    dispatch(setCollection(data));
  } catch (err) {
    const { message } = getAxiosError(err);
    dispatch(setCollectionError(message));
  }
};
