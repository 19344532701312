import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hasPermission, isActiveSession, setUser } from 'store/actions/user';
import {
  showSignIn as globalActionsShowSignIn,
  showAccessDenied as globalActionsShowAccessDenied,
} from 'store/actions/globalActions';

const noop = () => {};

export class PermissionButton extends Component {
  onClick() {
    const { email, permission, onClick = noop, showSignIn, showAccessDenied } = this.props;
    if (!email || !isActiveSession()) {
      this.props.setUser({});
      showSignIn();
      return;
    }
    if (!this.props.hasPermission(permission)) {
      showAccessDenied();
      return;
    }
    onClick();
  }

  render() {
    const { children, className, disabled, type = 'button', ariaLabel = '' } = this.props;
    return (
      <button type={type} className={className} onClick={() => this.onClick()} aria-label={ariaLabel} disabled={disabled}>{children}</button>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  email: user.user.email,
});

const mapDispatchToProps = {
  showSignIn: globalActionsShowSignIn,
  showAccessDenied: globalActionsShowAccessDenied,
  hasPermission,
  isActiveSession,
  setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionButton);
