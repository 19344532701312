import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isInvalidDate } from '../../../../utils/helpers';

import './Datepicker.scss';

const defaultParams = {
  showMonthDropdown: true,
  isClearable: true,
  scrollableYearDropdown: true,
  showYearDropdown: true,
};

const Datepicker = (props) => {
  const { name, selectsStart, selected, startDate } = props;

  const datepickerSettings = {
    ...props,
    selected: selectsStart && isInvalidDate(selected) ? null : selected,
    startDate: selectsStart && isInvalidDate(startDate) ? null : startDate,
  };

  return (
    <div className="datepicker">
      <DatePicker {...defaultParams} {...datepickerSettings} customInput={<input type="text" aria-label={name} />} />
    </div>
  );
};

export default Datepicker;
